import { useContext } from "react"
import { BulkTestingContext } from "../contexts/BulkTestingProvider"

export default function useBulkTesting() {

    const {
        activeTab,
        setActiveTab,
        searchBulkTest,
        setSearchBulkTest,
        currentBulkTestPage,
        setCurrentBulkTestPage,
        selectedBulkTestFilters,
        setSelectedBulkTestFilters,
        searchActualAnswer,
        setSearchActualAnswer,
        currentActualAnswerPage,
        setCurrentActualAnswerPage,
        selectedActualAnswerFilters,
        setSelectedActualAnswerFilters,
        appliedConfiguration,
        setAppliedConfiguration,
        actualAnswersData,
        setActualAnswersData,
        getActualAnswers,
        bulkTestingData,
        setBulkTestingData,
        getBulkTest,
        getCurrentActiveConfiguration,
        isActualAnswerLoading,
        isBuklTestingLoading,
        setIsActualAnswerLoading,
        setIsBulkTestingLoading
    } = useContext(BulkTestingContext)

    return {
        activeTab,
        setActiveTab,
        searchBulkTest,
        setSearchBulkTest,
        currentBulkTestPage,
        setCurrentBulkTestPage,
        selectedBulkTestFilters,
        setSelectedBulkTestFilters,
        searchActualAnswer,
        setSearchActualAnswer,
        currentActualAnswerPage,
        setCurrentActualAnswerPage,
        selectedActualAnswerFilters,
        setSelectedActualAnswerFilters,
        appliedConfiguration,
        setAppliedConfiguration,
        actualAnswersData,
        setActualAnswersData,
        getActualAnswers,
        bulkTestingData,
        setBulkTestingData,
        getBulkTest,
        getCurrentActiveConfiguration,
        isActualAnswerLoading,
        isBuklTestingLoading,
        setIsActualAnswerLoading,
        setIsBulkTestingLoading
    }
}