import closeIcon from "../../assets/cancelIcon.svg";

import { useState } from "react";
import useBulkTesting from "../../hooks/useBulkTesting";
import Select from "react-select";
import OutsideHandler from "../../components/OutsideHandler";

const FilterModal = ({ isOpen, onClose }) => {
  const { selectedActualAnswerFilters, setSelectedActualAnswerFilters } = useBulkTesting();
  const [filters, setFilters] = useState({
    granularity_type: null,
    metrics_type: null
  });

  const [granularityTypeOptions, setGranularityTypeOptions] = useState([
    { label: "PSM", value: "PSM" },
    { label: "Region", value: "Region" },
    { label: "Estate", value: "Estate" },
    { label: "Division", value: "Division" },
    { label: "Complex", value: "Complex" },
  ]);

  const [metricsTypeOptions, setMetricsTypeOptions] = useState([
    { label: "MVD Production", value: "MVD Production" },
    { label: "MVD Investor Downstream", value: "MVD Investor Downstream" },
    { label: "MVD Investor Upstream", value: "MVD Investor Upstream" },
    { label: "MVD Investor Yield", value: "MVD Investor Yield" },
    { label: "MVD Investor Seed", value: "MVD Investor Seed" },
    { label: "iCON SPB", value: "iCON SPB" },
    { label: "iCON Denda", value: "iCON Denda" },
    { label: "iCON IPD", value: "iCON IPD" },
  ]);

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  };

  const handleClear = () => {
    setSelectedActualAnswerFilters({
      ...selectedActualAnswerFilters,
      granularity_type: null,
      metrics_type: null
    })
    setFilters({
      granularity_type: null,
      metrics_type: null
    })
    onClose();
  }

  const handleApply = () => {
    setSelectedActualAnswerFilters({
      ...selectedActualAnswerFilters,
      granularity_type: filters.granularity_type ? filters.granularity_type.value : null,
      metrics_type: filters.metrics_type ? filters.metrics_type.value : null
    });
    onClose();
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onClose()}>
      <div className="bulk-filter__wrapper">
        <div className="bulk-filter__header">
          <h2 className="bulk-filter__title">Filter</h2>
          <button className="bulk-filter__close" onClick={() => onClose()}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="bulk-filter__separator" />
        <div className="bulk-filter__content">
          <div className="bulk-filter__form-row">
            <div className="bulk-filter__form-group">
              <label htmlFor="granularity_type" className="bulk-filter__input-label">Granularity Type</label>
              <Select
                id="granularity_type"
                name="granularity_type"
                options={granularityTypeOptions}
                value={filters.granularity_type}
                onChange={handleInputChange}
                placeholder="-- Select Granularity Type --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="bulk-filter__form-group">
              <label htmlFor="metrics_type" className="bulk-filter__input-label">Metrics Type</label>
              <Select
                id="metrics_type"
                name="metrics_type"
                options={metricsTypeOptions}
                value={filters.metrics_type}
                onChange={handleInputChange}
                placeholder={"-- Select Metrics Type --"}
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="bulk-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;