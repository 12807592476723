import ToolTip from "../../components/ToolTip";
import openModalIcon from "../../assets/shareIcon.svg";
import { useCallback, useEffect, useState } from "react";
import ActiveUsers from "./ActiveUsers";
import AvgSessionLength from "./AvgSessionLength";
import ChurnRate from "./ChurnRate";
import LoadingOverview from "../../components/LoadingOverview";
import RetentionRateModal from "./RetentionRateModal";
import axios from "axios";
import debounce from "../../utils/Debounce";
import { intervalToDuration, millisecondsToMinutes, millisecondsToSeconds, secondsToHours, secondsToMinutes } from "date-fns";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axiosCMS from "../../libs/axios/axiosCMS";

const UserOverview = ({ selectedFilters, selectedMonth, selectedYear }) => {
  const [data, setData] = useState({});

  const [isActiveUserOpen, setIsActiveUserOpen] = useState(false);
  const [isAvgSessionLengthOpen, setIsAvgSessionLengthOpen] = useState(false);
  const [isRetentionRateOpen, setIsRetentionRateOpen] = useState(false);
  const [isChurnRateOpen, setIsChurnRateOpen] = useState(false);
  const [isChurnRateDisplay, setIsChurnRateDisplay] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const getMessageSession = async (params) => {
    try {
      const URL = "user-engagement/overview-message-session";
      const response = await axiosCMS.post(URL, params)
      return response.data;
    } catch (error) {
      console.error('Error fetching message session data', error);
    }
  }

  const getTotalInteraction = async (params) => {
    try {
      const URL = "user-engagement/overview-total-interaction";
      const response = await axiosCMS.post(URL, params)
      return response.data;
    } catch (error) {
      console.error('Error fetching total interaction data', error);
    }
  }

  const getUserAnalytics = async (params) => {
    try {
      const URL = "user-engagement/overview-user-analytics";
      const response = await axiosCMS.post(URL, params)
      return response.data;
    } catch (error) {
      console.error('Error fetching user analytics data', error);
    }
  }

  const isIncludeChurnRate = (date_months, date_years) => {
    let prevMonth = 0;
    if (date_months.length === 1 && date_years.length === 1) return true;

    if (date_years.length > 1) return false;

    for (let i = 0; i < date_months.length; i++) {
      if (i === 0) {
        prevMonth = date_months[i];
      } else {
        if (date_months[i] - prevMonth !== 1) {
          return false;
        }
        prevMonth = date_months[i];
      }
    }
    return true
  }

  const getChurnUserAnalytic = async (params) => {
    try {
      const URL = "user-engagement/overview-churn-user-analytics";
      const response = await axiosCMS.post(URL, params)
      return response.data;
    } catch (error) {
      console.error('Error fetching user analytics data', error);
    }
  }

  const getAllData = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    const messageSession = getMessageSession(filters);
    const totalInteraction = getTotalInteraction(filters);
    const userAnalytics = getUserAnalytics(filters);

    const includeChurnRate = isIncludeChurnRate(filters.date_months, filters.date_years);
    setIsChurnRateDisplay(includeChurnRate);
    const listPromise = [messageSession, totalInteraction, userAnalytics];
    if (includeChurnRate) {
      const churnRate = getChurnUserAnalytic(filters);
      listPromise.push(churnRate);
    }

    Promise.all(listPromise)
      .then((values) => {
        const messageSessionData = values[0].data;
        const totalInteractionData = values[1].data;
        const userAnalyticsData = values[2].data;
        if (includeChurnRate) {
          const churnRateData = values[3].data;
          setData({ ...messageSessionData, total_interaction: totalInteractionData, ...userAnalyticsData, ...churnRateData });
        } else {
          setData({ ...messageSessionData, total_interaction: totalInteractionData, ...userAnalyticsData });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
      });
  }, 2000), [])

  useEffect(() => {
    getAllData(selectedFilters);
  }, [selectedFilters])

  const handleActiveUserModal = (action = null) => {
    if (action === null) {
      setIsActiveUserOpen(!isActiveUserOpen);
    } else {
      setIsActiveUserOpen(action);
    }
  }

  const handleAvgSessionLengthModal = (action = null) => {
    if (action === null) {
      setIsAvgSessionLengthOpen(!isAvgSessionLengthOpen);
    } else {
      setIsAvgSessionLengthOpen(action);
    }
  }

  const handleRetentionRateModal = (action = null) => {
    if (action === null) {
      setIsRetentionRateOpen(!isRetentionRateOpen);
    } else {
      setIsRetentionRateOpen(action);
    }
  }

  const handleChurnRateModal = (action = null) => {
    if (action === null) {
      setIsChurnRateOpen(!isChurnRateOpen);
    } else {
      setIsChurnRateOpen(action);
    }
  }

  return (
    <div className="ue-section">
      <h3 className="section-title">
        User Overview
      </h3>
      {
        isLoading
          ? <LoadingOverview length={6} prefixCSS={'ue'} />
          : (<div className="ue-boxes-grid">
            <div className="ue-box">
              <div className="box-title">
                Total Interactions
                <img
                  src={tooltipIcon}
                  alt="tooltip icon"
                  className="tooltip-icon anchor-total-interactions"
                />
                <ReactTooltip
                  id='tooltip'
                  place="top"
                  content="The total number of interactions between users and chatbot based on total messages sent by users and chatbot"
                  anchorSelect=".anchor-total-interactions"
                  style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                />
              </div>
              <p className="box-value">
                {data.total_interaction
                  ? Math.round(data.total_interaction.total * 100) / 100
                  : 0
                }</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.total_interaction && data.total_interaction.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.total_interaction
                        ? data.total_interaction.growth >= 0 ? `+${Math.round(data.total_interaction.growth * 100) / 100}` : Math.round(data.total_interaction.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box">
              <div className="box-title">
                Total Active Users
                <img
                  src={tooltipIcon}
                  alt="tooltip icon"
                  className="tooltip-icon anchor-total-active-users"
                />
                <ReactTooltip
                  id='tooltip'
                  place="top"
                  content="The total number of active users interacting with chatbot"
                  anchorSelect=".anchor-total-active-users"
                  style={{ maxWidth: "150px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                />
                <img src={openModalIcon}
                  alt="share icon"
                  className="share-icon cursor-pointer"
                  onClick={() => handleActiveUserModal(true)}
                />
                <ActiveUsers
                  isOpen={isActiveUserOpen}
                  onClose={() => handleActiveUserModal(false)}
                  selectedFilters={selectedFilters}
                />
              </div>
              <p className="box-value">
                {data.active_user
                  ? Math.round(data.active_user.total * 100) / 100
                  : 0
                }</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.active_user && data.active_user.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.active_user
                        ? data.active_user.growth >= 0 ? `+${Math.round(data.active_user.growth * 100) / 100}` : Math.round(data.active_user.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box">
              <div className="box-title">
                Avg. Message/Session
                <img
                  src={tooltipIcon}
                  alt="tooltip icon"
                  className="tooltip-icon anchor-avg-message-session"
                />
                <ReactTooltip
                  id='tooltip'
                  place="top"
                  content="The average number of messages sent to the chatbot in one session"
                  anchorSelect=".anchor-avg-message-session"
                  style={{ maxWidth: "150px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                />
              </div>
              <p className="box-value">
                {data.avg_doc_count
                  ? Math.round(data.avg_doc_count.avg)
                  : 0
                }</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.avg_doc_count && data.avg_doc_count.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.avg_doc_count
                        ? data.avg_doc_count.growth >= 0 ? `+${Math.round(data.avg_doc_count.growth * 100) / 100}` : Math.round(data.avg_doc_count.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box">
              <div className="box-title">
                Avg. Session Length
                <img
                  src={tooltipIcon}
                  alt="tooltip icon"
                  className="tooltip-icon anchor-avg-session-length"
                />
                <ReactTooltip
                  id='tooltip'
                  place="top"
                  content="The average duration of user interacting with the chatbot in one session"
                  anchorSelect=".anchor-avg-session-length"
                  style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                />
                <img src={openModalIcon}
                  alt="share icon"
                  className="share-icon cursor-pointer"
                  onClick={() => handleAvgSessionLengthModal(true)}
                />
                <AvgSessionLength
                  isOpen={isAvgSessionLengthOpen}
                  onClose={(() => handleAvgSessionLengthModal(false))}
                  selectedFilters={selectedFilters}
                />
              </div>
              <p className="box-value">
                <AvgSessionLengthComponent data={data.avg_session_length ? data.avg_session_length.avg : 0} />
              </p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.avg_session_length && data.avg_session_length.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.avg_session_length
                        ? data.avg_session_length.growth >= 0 ? `+${Math.round(data.avg_session_length.growth * 100) / 100}` : Math.round(data.avg_session_length.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box">
              <div className="box-title">
                Retention Rate
                <img
                  src={tooltipIcon}
                  alt="tooltip icon"
                  className="tooltip-icon anchor-retention-rate"
                />
                <ReactTooltip
                  id='tooltip'
                  place="top"
                  content="The rate of users who return to use the chatbot, based on having at least 2 interactions within 1 month of their initial chat/interaction"
                  anchorSelect=".anchor-retention-rate"
                  style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                />
                <img src={openModalIcon}
                  alt="share icon"
                  className="share-icon cursor-pointer"
                  onClick={() => handleRetentionRateModal(true)}
                />
                <RetentionRateModal
                  isOpen={isRetentionRateOpen}
                  onClose={() => handleRetentionRateModal(false)}
                  selectedFilters={selectedFilters}
                />
              </div>
              <p className="box-value">
                {data.retention_rate
                  ? Math.round(data.retention_rate.rate * 100) / 100
                  : 0
                }%</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.retention_rate && data.retention_rate.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.retention_rate
                        ? data.retention_rate.growth >= 0 ? `+${Math.round(data.retention_rate.growth * 100) / 100}` : Math.round(data.retention_rate.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            {
              <div className="ue-box">
                <div className="box-title">
                  Churn Rate
                  <img
                    src={tooltipIcon}
                    alt="tooltip icon"
                    className="tooltip-icon anchor-churn-rate"
                  />
                  <ReactTooltip
                    id='tooltip'
                    place="top"
                    content="The percentage of users who have not engaged with the chatbot for one month or longer."
                    anchorSelect=".anchor-churn-rate"
                    style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                  />
                  <img src={openModalIcon}
                    alt="share icon"
                    className="share-icon cursor-pointer"
                    onClick={() => handleChurnRateModal(true)}
                  />
                  <ChurnRate
                    isOpen={isChurnRateOpen}
                    onClose={() => handleChurnRateModal(false)}
                    selectedFilters={selectedFilters}
                  />
                </div>
                <p className="box-value">
                  {
                    isChurnRateDisplay ?
                      data.churn_rate
                        ? `${Math.round(data.churn_rate.rate * 100) / 100}%`
                        : `0%`
                      : 'N/A'}</p>
                <p className="box-date">
                  {
                    (selectedMonth.length === 1 && selectedYear.length === 1) &&
                    <>
                      <span
                        style={{ color: data.churn_rate && data.churn_rate.growth >= 0 ? 'green' : 'red' }}
                      >
                        {data.churn_rate
                          ? data.churn_rate.growth >= 0 ? `+${Math.round(data.churn_rate.growth * 100) / 100}` : Math.round(data.churn_rate.growth * 100) / 100
                          : 0
                        }{"% "}
                      </span>
                      from last month
                    </>
                  }
                </p>
              </div>
            }
          </div>)
      }
    </div>
  );
}

const AvgSessionLengthComponent = ({ data }) => {
  const intervalDuration = intervalToDuration({ start: 0, end: data });
  return (
    <>
      {
        intervalDuration.hours > 0
          ? (
            <>
              {`${intervalDuration.hours}.${intervalDuration.minutes}`}
              <span className="box-sub-value">
               {' '} hours
              </span>
            </>
          )
          : intervalDuration.minutes > 0
            ? (
              <>
                {`${intervalDuration.minutes}.${intervalDuration.seconds}`}
                <span className="box-sub-value">
                {' '} minutes
                </span>
              </>
            )
            : intervalDuration.seconds > 0
              ? (
                <>
                  {intervalDuration.seconds}
                  <span className="box-sub-value">
                  {' '} seconds
                  </span>
                </>
              )
              : ''
      }
    </>
  );
}

export default UserOverview;