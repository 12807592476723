import { useEffect, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import axiosBulk from "../../libs/axios/axiosBulk";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";

const DEFAULT_CONFIGURATION = ["intent_detector", "context_schema", "question_list", "cache_response"];
const ApplyConfigurationModal = ({ bulk, appliedConfiguration, getAppliedConfiguration, onClose }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    intent_detector: {
      isChange: false,
      old: "",
      new: ""
    },
    context_schema: {
      isChange: false,
      old: "",
      new: ""
    },
    question_list: {
      isChange: false,
      old: "",
      new: ""
    },
    cache_response: {
      isChange: false,
      old: "",
      new: ""
    }
  });

  const verifyConfigurationChange = (bulk, oldConfiguration) => {
    const newConfiguration = JSON.parse(bulk.configuration.replace(/'/g, '"'));
    DEFAULT_CONFIGURATION.map((item) => {
      if (newConfiguration.includes(item) && oldConfiguration.includes(item)) {
        setData(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isChange: false,
            old: "TRUE",
            new: "",
          }
        }))
      } else if (newConfiguration.includes(item) && !oldConfiguration.includes(item)) {
        setData(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isChange: true,
            old: "FALSE",
            new: "TRUE",
          }
        }))
      } else if (!newConfiguration.includes(item) && oldConfiguration.includes(item)) {
        setData(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isChange: true,
            old: "TRUE",
            new: "FALSE",
          }
        }))
      } else {
        setData(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isChange: false,
            old: "FALSE",
            new: "",
          }
        }))
      }
    })
  }

  const applyConfiguration = async () => {
    const URL = "testing/apply"
    try {
      const response = await axiosBulk.get(URL, {
        params: {
          testId: bulk.id
        }
      });
      if (response.status === 200) {
        dispatch(addToast({
          title: "Configuration applied successfully",
          type: "success"
        }))
        onClose();
        // getAppliedConfiguration();
      }
    } catch (error) {
      console.error("failed to apply configuration", error);
      dispatch(addToast({
        title: "Failed to apply configuration",
        type: "error"
      }))
    }
  }

  useEffect(() => {
    if (bulk !== null) {
      verifyConfigurationChange(bulk, appliedConfiguration);
    }
  }, [bulk])
  if (bulk === null) return null;

  return (
    <div className="apply-conf__overlay">
      <div className="apply-conf__modal">
        <div className="apply-conf__header">
          <h2 className="apply-conf__header-title">Apply Configuration</h2>
          <button className="apply-conf__close" onClick={onClose}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="apply-conf__separator"></div>
        <div className="apply-conf__content">
          <div>
            <h5 className="apply-conf__content-title">Are you sure want to apply the configuration</h5>
            <p className="apply-conf__content-subtitle">This configuration will affect the bot. Are you sure want to apply these settings?</p>
          </div>
          <div className="apply-conf__active-box">
            <h5 className="apply-conf__active-box-title">Active Configuration:</h5>
            <div className="apply-conf__boxes">
              <div className="apply-conf__box">
                <h5>Intent Detector</h5>
                <div className="apply-conf__separator"></div>
                <p>
                  {
                    data.intent_detector.isChange
                      ? (`${data.intent_detector.old} -> ${data.intent_detector.new}`)
                      : data.intent_detector.old
                  }
                </p>
              </div>
              <div className="apply-conf__box">
                <h5>Context Schema</h5>
                <div className="apply-conf__separator"></div>
                <p>
                  {
                    data.context_schema.isChange
                      ? (`${data.context_schema.old} -> ${data.context_schema.new}`)
                      : data.context_schema.old
                  }
                </p>
              </div>
              <div className="apply-conf__box">
                <h5>Question List</h5>
                <div className="apply-conf__separator"></div>
                <p>
                  {
                    data.question_list.isChange
                      ? (`${data.question_list.old} -> ${data.question_list.new}`)
                      : data.question_list.old
                  }
                </p>
              </div>
              <div className="apply-conf__box">
                <h5>Cache Response</h5>
                <div className="apply-conf__separator"></div>
                <p>
                  {
                    data.cache_response.isChange
                      ? (`${data.cache_response.old} -> ${data.cache_response.new}`)
                      : data.cache_response.old
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="apply-conf__footer">
          <button type="button" className="btn-base font-base" onClick={onClose}>
            Cancel
          </button>
          <button type="button" onClick={applyConfiguration} className="btn-primary font-primary">
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}

export default ApplyConfigurationModal;