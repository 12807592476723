import React from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import downloadIcon from "../../assets/downloadIcon.svg";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import axiosCMS from "../../libs/axios/axiosCMS";

const DownloadModal = ({ isOpen, onClose }) => {

  const handleExportData = async (filters, URL, nameFile) => {
    try {
      const currentDay = format(new Date(), "yyyy-MM-dd");
      const response = await axiosCMS.get(URL, { params: filters, responseType: 'blob' });
      if (response.status === 200) {
        const contentDisposition = response.headers.get('content-disposition');
        let filename = `${nameFile} ${currentDay}.xlsx`;

        if (contentDisposition && contentDisposition.includes('attachment')) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch && filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }

        const blob = response.data;

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <div className={`download-modal ${isOpen ? "open" : ""}`}>
      <div className="download-modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={cancelIcon} alt="cancel icon" onClick={onClose} />
        </button>
        <h2 className="download-title">Download</h2>
        <hr className="download-separator" />
        <div className="file-download-section">
          <div className="file-download-item">
            <span className="file-name">Metrics.xlsx</span>
            <button
              className="download-icon-button"
              onClick={() => {
                handleExportData(
                  {
                    page: 1,
                    size: 9999,
                    is_export: true,
                    order_by: "updated_at",
                    sort: "desc"
                  },
                  "prompt_metrics/get/9999/1",
                  "Metrics"
                );
              }}
            >
              <img src={downloadIcon} alt="download icon" />
            </button>
          </div>
        </div>
        {/* <div className="download-all-section">
          <button className="download-all-button">Download All</button>
        </div> */}
      </div>
    </div>
  );
};

export default DownloadModal;
