import { useEffect, useState } from "react";
import useBulkTesting from "../../hooks/useBulkTesting";
import Table from "../../components/Table";
import OutsideHandler from "../../components/OutsideHandler";
import kebabMenu from "../../assets/kebabMenu.svg";
import { ReactComponent as DownloadIcon } from "../../assets/downloadIcon24.svg";
import { ReactComponent as EyeIcon } from "../../assets/eyeIcon24.svg";
import { ReactComponent as DoubleCheckIcon } from "../../assets/doubleCheckIcon16.svg";
import LoadingTable from "../../components/LoadingTable";
import { useDispatch } from "react-redux";
import ManualPagination from "../../components/ManualPagination";
import { convertFormatedDate } from "../../utils/FunctionHelpers";
import { useNavigate } from "react-router-dom";
import ExecuteBulkTestingModal from "./ExecuteBulkTestingModal";

const TOTAL_PAGE = 1;
const GenerateActualAnswer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedActualAnswerFilters,
    currentActualAnswerPage,
    setCurrentActualAnswerPage,
    actualAnswersData,
    getActualAnswers,
    isActualAnswerLoading,
  } = useBulkTesting();

  const [paginatedData, setPaginatedData] = useState([]);
  const [sortBy, setSortBy] = useState({ column: 'created_at', direction: 'Descending' });
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [executeBulkTesting, setExecuteBulkTesting] = useState(null);
  const [totalPage, setTotalPage] = useState(TOTAL_PAGE);

  const headColumns = [
    {
      title: 'ID', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    },
    {
      title: 'Metrics Type', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    },
    {
      title: 'Granularity Type', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    },
    {
      title: 'Test Case Count', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'test_case_count',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'test_case_count', direction: sortBy.direction === 'Descending' ? 'Ascending' : 'Descending' })
      }
    },
    {
      title: 'Status', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'status',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'status', direction: sortBy.direction === 'Descending' ? 'Ascending' : 'Descending' })
      }
    },
    {
      title: 'Date Created', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'created_at',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'created_at', direction: sortBy.direction === 'Descending' ? 'Ascending' : 'Descending' })
      }
    },
    {
      title: '', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    }
  ]

  useEffect(() => {
    getActualAnswers({
      ...selectedActualAnswerFilters,
      sorting_type: sortBy.direction,
      order_by: sortBy.column
    });
  }, [selectedActualAnswerFilters, sortBy])

  const renderStatus = (status) => {
    let badge = null;
    switch (status) {
      case "SUCCESS":
        badge = <span className="status-badge--success">Success</span>;
        break;
      case "PENDING":
        badge = <span className="status-badge--pending">Pending</span>;
        break;
      case "FAILED":
        badge = <span className="status-badge--failed">Failed</span>;
        break;
      default:
        badge = "-";
        break;
    }
    return badge;
  }

  const handleActiveMenu = (index) => {
    if (activeMenuIndex === index) {
      setActiveMenuIndex(null);
    } else {
      setActiveMenuIndex(index);
    }
  }

  const handlePageChange = (page) => {
    setCurrentActualAnswerPage(page);
  };

  useEffect(() => {
    if (actualAnswersData.length > 0) {
      const start = (currentActualAnswerPage - 1) * 10;
      const end = start + 10;
      setPaginatedData(actualAnswersData.slice(start, end));
    }
  }, [currentActualAnswerPage, actualAnswersData]);

  const handleDownloadFile = (url) => {
    const filename = url.split('/').pop();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className="bulk-table">
      <div className="bulk-table__wrapper">
        <Table headColumn={headColumns} classes={''} >
          {
            isActualAnswerLoading ?
              <LoadingTable columnSize={headColumns.length} rowSize={4} />
              : (
                paginatedData.length > 0 ? paginatedData.map(bulk => (
                  <tr key={bulk.id}>
                    <td>{bulk.id}</td>
                    <td>{bulk.metrics_type}</td>
                    <td>{bulk.granularity_type}</td>
                    <td>{bulk.test_cases_count}</td>
                    <td>{renderStatus(bulk.status)}</td>
                    <td>{convertFormatedDate(bulk.created_at)}</td>
                    <td>
                      <div className="kebab-menu-container">
                        <img
                          src={kebabMenu}
                          alt="kebab menu"
                          onClick={() => handleActiveMenu(bulk.id)}
                          style={{ cursor: "pointer" }}
                        />
                        {activeMenuIndex === bulk.id && (
                          <OutsideHandler handleClose={() => handleActiveMenu(null)}>
                            <div className="bulk-menu">
                              <div
                                className="bulk-menu-option"
                                onClick={
                                  () => navigate("/bulk-testing/actual-answer-details", {
                                    state: { data: bulk },
                                  })
                                }
                              >
                                <EyeIcon className="bulk-menu-icon" />
                                View Details
                              </div>
                              <div
                                className="bulk-menu-option"
                                onClick={() => setExecuteBulkTesting(bulk)}>
                                <DoubleCheckIcon className="bulk-menu-icon" />
                                Execute Bulk Test
                              </div>
                              {
                                bulk.status === "SUCCESS" && (<div
                                  className="bulk-menu-option"
                                  onClick={() => handleDownloadFile(bulk.result_url)}
                                >
                                  <DownloadIcon className="bulk-menu-icon" />
                                  Download
                                </div>)
                              }
                            </div>
                          </OutsideHandler>
                        )}
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={headColumns.length} className="text-center">No data available</td>
                  </tr>
                )
              )
          }
        </Table>
      </div>
      <ManualPagination
        currentPage={currentActualAnswerPage}
        itemsLength={actualAnswersData.length > 0 ? actualAnswersData.length : currentActualAnswerPage}
        itemsPerPage={10}
        handlePageChange={handlePageChange}
      />
      <ExecuteBulkTestingModal
        actualAnswer={executeBulkTesting}
        onSuccess={() => getActualAnswers(selectedActualAnswerFilters)}
        onClose={() => setExecuteBulkTesting(null)}
      />
    </div>
  );
}

export default GenerateActualAnswer;