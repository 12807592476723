
import { sortIcon, sortIconAsc, sortIconDesc } from "../assets";

const Sortable = ({ sort }) => {
  return (
    <>
      {
        sort.isSortable &&
        <img
        src={
          sort.isSorted
            ? sort.isDesc
              ? sortIconDesc
              : sortIconAsc
            : sortIcon
        }
        className="sort-icon"
        alt="Sort icon"
        style={{ cursor: "pointer" }}
        onClick={sort.onClick}
      />
      }
    </>
  )
}

export default Sortable;