import React, { useRef } from "react";
import Alert from "../../components/Alert";

import cancelIcon from "../../assets/cancelIcon.svg";
import downloadIcon from "../../assets/downloadIcon.svg";
import { format } from "date-fns";
import axiosCMS from "../../libs/axios/axiosCMS";

const DownloadModal = ({ isOpen, onClose, filters }) => {
  const alertRef = useRef();
  const currentDay = format(new Date(), "yyyy-MM-dd");

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const downloadFile = (url, defaultFilename, params) => {
    axiosCMS({
      method: "post",
      url: url,
      data: {
        ...params,
        function_name: params.function_name.map((option) => option.value),
        
      },
      responseType: "blob",
    })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        let filename = defaultFilename;
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        const { message } = error;

        showAlert({
          type: "error",
          title: message,
          message: "Failed to download file",
        });
      });
  };

  return (
    <>
      <Alert ref={alertRef} />
      <div className={`download-modal ${isOpen ? "open" : ""}`}>
        <div className="download-modal-content">
          <button className="close-button" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" onClick={onClose} />
          </button>
          <h2 className="download-title">Download</h2>
          <hr className="download-separator" />
          <div className="file-download-section">
            <div className="file-download-item">
              <span className="file-name">Function.xlsx</span>
              <button
                className="download-icon-button"
                onClick={() =>
                  downloadFile(
                    "prompt_function/get/",
                    `Function ${currentDay}.xlsx`,
                    {
                      page: 1,
                      size: 99999,
                      is_export: true,
                      order_by: "updated_at",
                      sort: "desc",
                      ...filters,
                    }
                  )
                }
              >
                <img src={downloadIcon} alt="download icon" />
              </button>
            </div>
            <div className="file-download-item">
              <span className="file-name">Sample Question.xlsx</span>
              <button
                className="download-icon-button"
                onClick={() =>
                  downloadFile(
                    "prompt_function/get/",
                    `Sample Question ${currentDay}.xlsx`,
                    {
                      page: 1,
                      size: 99999,
                      export_sample_question: true,
                      order_by: "updated_at",
                      sort: "desc",
                      ...filters,
                    }
                  )
                }
              >
                <img src={downloadIcon} alt="download icon" />
              </button>
            </div>
          </div>
          <div className="download-all-section">
            <button
              className="download-all-button"
              onClick={() => {
                downloadFile(
                  "prompt_function/get/",
                  `Function ${currentDay}.xlsx`,
                  {
                    page: 1,
                    size: 99999,
                    is_export: true,
                    export_sample_question: false,
                    order_by: "updated_at",
                    sort: "desc",
                    ...filters,
                  }
                );
                downloadFile(
                  "prompt_function/get/",
                  `Sample Question ${currentDay}.xlsx`,
                  {
                    page: 1,
                    size: 99999,
                    is_export: false,
                    export_sample_question: true,
                    order_by: "updated_at",
                    sort: "desc",
                    ...filters,
                  }
                );
              }}
            >
              Download All
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadModal;
