import { Outlet } from "react-router-dom";
import { handleSwitchSidePanel } from "../libs/redux/slices/sidePanelSlice";
import { useDispatch, useSelector } from "react-redux";

import hamburgerMenu from "../assets/arrowLeftLineIcon24.svg";
import sinarmasLogo from "../assets/sinarmasLogo.svg";
import SideBar from "./SideBar";
import Toasts from "./Toasts";
import SessionExpiredModal from "./SessionExpiredModal";

const MainLayout = () => {
  const isSidePanelOpen = useSelector((state) => state.sidePanel.isSidePanelOpen);
  const dispatch = useDispatch();
  const toggleSidePanel = () => {
    dispatch(handleSwitchSidePanel());
  }
  return (
    <>
      <Toasts />
      <SessionExpiredModal />
      <div className="main-container">
        <div className={`side-panel ${isSidePanelOpen ? "open" : "closed"}`}>
          <div className="group-flex-8 justify-between align-center">
            {isSidePanelOpen && <img
              src={isSidePanelOpen ? sinarmasLogo : hamburgerMenu}
              className="logo"
              alt="sinarmas"
            />}

            <img
              src={hamburgerMenu}
              className={!isSidePanelOpen ? "rotate cursor-pointer" : "cursor-pointer"}
              style={{ height: "24px", width: "24px" }}
              onClick={toggleSidePanel}
              alt="sinarmas"
            />
          </div>

          {!isSidePanelOpen && <div className="separator"></div>}
          <div className={`tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
            <SideBar />
          </div>
          {/* {renderBottomTabs()} */}
        </div>
        <div className={`content-area ${isSidePanelOpen ? "shifted" : ""}`}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default MainLayout;