import { createContext, useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import debounce from "../utils/Debounce";
import axiosBulk from "../libs/axios/axiosBulk";
import { useDispatch } from "react-redux";
import { addToast } from "../libs/redux/slices/toastSlice";
import axios from "axios";


export const BulkTestingContext = createContext();

const LIMIT_PER_PAGE = 99999999;
const DEFUALT_CURRENT_PAGE = 1;
export default function BulkTestingProvider() {
  const dispatch = useDispatch();
  const [actualAnswersData, setActualAnswersData] = useState([]);
  const [bulkTestingData, setBulkTestingData] = useState([]);
  const [isBuklTestingLoading, setIsBulkTestingLoading] = useState(true);
  const [isActualAnswerLoading, setIsActualAnswerLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("Generate Actual Answer");

  const [searchBulkTest, setSearchBulkTest] = useState("");
  const [selectedBulkTestFilters, setSelectedBulkTestFilters] = useState({
    page: DEFUALT_CURRENT_PAGE,
    limit: LIMIT_PER_PAGE,
  });
  const [currentBulkTestPage, setCurrentBulkTestPage] = useState(DEFUALT_CURRENT_PAGE);

  const [searchActualAnswer, setSearchActualAnswer] = useState("");
  const [selectedActualAnswerFilters, setSelectedActualAnswerFilters] = useState({
    page: DEFUALT_CURRENT_PAGE,
    limit: LIMIT_PER_PAGE,
  });
  const [currentActualAnswerPage, setCurrentActualAnswerPage] = useState(1);

  const [appliedConfiguration, setAppliedConfiguration] = useState([]);

  const getBulkTest = useCallback(debounce(async (filters) => {
    setIsBulkTestingLoading(true);
    const URL = "testing/history";
    try {
      const response = await axiosBulk.get(URL, {
        params: filters,
      });
      if (response.status === 200) {
        const result = response.data;
        setBulkTestingData(result.data);
      }
    } catch (error) {
      console.error('failed to fetch bulk test data', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch bulk test data',
      }))
    }
    setIsBulkTestingLoading(false);
  }, 1000), [])

  const getActualAnswers = useCallback(debounce(async (filters) => {
    const URL = "testing/generated/history"
    setIsActualAnswerLoading(true);
    try {
      const response = await axiosBulk.get(URL, {
        params: filters
      })
      if (response.status === 200) {
        setActualAnswersData(response.data.data);
      }
    } catch (error) {
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch actual answer data',
      }))
    }
    setIsActualAnswerLoading(false);
  }, 1000), [])

  const getCurrentActiveConfiguration = useCallback(debounce(async (filters) => {
    const URL = "configuration";
    try {
      const response = await axiosBulk.get(URL, {
        params: filters,
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 200) {
        const result = response.data;
        const tempList = [];
        if(result.data.intent_detector){
          tempList.push('intent_detector');
        }
        if(result.data.context_schema){
          tempList.push('context_schema');
        }
        if(result.data.question_list){
          tempList.push('question_list');
        }
        if(result.data.cached_response){
          tempList.push('cached_response');
        }
        setAppliedConfiguration(tempList);
      }
    } catch (error) {
      console.error('failed to fetch bulk test data', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch current applied configuration',
      }))
    }
  }, 1000), [])

  return (
    <BulkTestingContext.Provider value={{
      activeTab,
      setActiveTab,
      searchBulkTest,
      setSearchBulkTest,
      currentBulkTestPage,
      setCurrentBulkTestPage,
      selectedBulkTestFilters,
      setSelectedBulkTestFilters,
      searchActualAnswer,
      setSearchActualAnswer,
      currentActualAnswerPage,
      setCurrentActualAnswerPage,
      selectedActualAnswerFilters,
      setSelectedActualAnswerFilters,
      appliedConfiguration,
      setAppliedConfiguration,
      actualAnswersData,
      setActualAnswersData,
      getActualAnswers,
      bulkTestingData,
      setBulkTestingData,
      getBulkTest,
      getCurrentActiveConfiguration,
      isActualAnswerLoading,
      isBuklTestingLoading,
      setIsActualAnswerLoading,
      setIsBulkTestingLoading
    }}>
      <Outlet />
    </BulkTestingContext.Provider>
  )
}