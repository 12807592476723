import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Tooltip,
  BarController,
} from 'chart.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Chart, getElementsAtEvent } from 'react-chartjs-2';
import LoadingChart from '../../components/LoadingChart';
import debounce from '../../utils/Debounce';
import axios from 'axios';
import tooltipIcon from "../../assets/tooltipIcon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axiosCMS from '../../libs/axios/axiosCMS';

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, PointElement, Tooltip);

const TopMetricByQueryChart = ({ selectedFilters }) => {
  const [selectedDashboard, setSelectedDashboard] = useState({ value: "", label: "All Dashboard" });
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [listColors, setListColors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const firstRenderRef = useRef(true);

  const dashboardTypeOptions = [
    { value: "", label: "All Dashboard" },
    { value: "MVD Production", label: "MVD Production" },
    { value: "MVD Investor", label: "MVD Investor" },
    { value: "iCON Metrics SPB", label: "iCON Metrics SPB" },
    { value: "iCON IPD Effectiveness", label: "iCON IPD Effectiveness" },
    { value: "iCON Metrics Denda", label: "iCON Metrics Denda" },
    // Add more options as necessary
  ];

  const handleDashboardChange = (selectedOption) => {
    setSelectedDashboard(selectedOption);
  };

  const generateColor = (ratio) => {
    let r = 209; // Mengurangi nilai merah secara bertahap
    let g = 90;       // Meningkatkan nilai hijau secara bertahap
    let b = 90;
    if (ratio < 0.5) {
      // Dari merah ke kuning
      r = 209;
      g = Math.round(2 * ratio * 90 + 90); // Mengatur hijau dari 0 ke 255
    } else {
      // Dari kuning ke hijau
      r = Math.round(209 - (119 * (ratio - 0.5) * 2)); // Mengurangi merah dari 255 ke 0
      g = 209;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }

  const generateListColors = (dataLength) => {
    const colors = [];
    if (dataLength === 0) return;
    if (dataLength === 1) {
      colors.push('rgb(209, 90, 90)');
    } else {
      for (let i = 0; i < dataLength; i++) {
        const ratio = i / (dataLength - 1);
        colors.push(generateColor(ratio));
      }
    }
    setListColors(colors);
  }

  const generateListColor = useCallback(() => {
    const colors = [];
    for (let i = 0; i < chartLabels.length; i++) {
      colors.push('rgb(113, 174, 235)');
    }
    return colors;
  }, [chartLabels.length]);

  const generateFocusListColor = (dataLength, focusIndex) => {
    const colors = [];

    for (let i = 0; i < dataLength; i++) {
      const ratio = i / (dataLength - 1);
      if (i === focusIndex) {
        colors.push('rgb(113, 174, 235)');
      } else {
        colors.push('rgb(218, 224, 222)');
      }
    }
    return colors;
  }


  const getTopMetrics = useCallback(debounce(async (filters, dashboard_type) => {
    setIsLoading(true);
    try {
      const URL = "user-engagement/top-metrics";
      const response = await axiosCMS.post(URL, { ...filters, dashboard_type: dashboard_type.value });
      const result = response.data;
      setChartData(result.data.map(x => x.total));
      setChartLabels(result.data.map(x => x.metric));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setIsLoading(false);
  }, 1500), []);

  useEffect(() => {
    getTopMetrics(selectedFilters, selectedDashboard);
  }, [selectedDashboard]);

  useEffect(() => {
    setSelectedDashboard(dashboardTypeOptions.find(x => x.value === selectedFilters.dashboard_type));
  }, [selectedFilters]);

  // useEffect(() => {
  //   generateListColors(chartLabels.length);
  // }, [chartLabels])

  const data = {
    labels: chartLabels,
    datasets: [
      {
        type: 'bar',
        backgroundColor: 'rgb(113, 174, 235)',
        data: chartData,
        maxBarThickness: 70
      },
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      x: {
        display: true,
        ticks: {
          callback: (value, index, ticks) => {
            console.log(ticks)
            return chartLabels[index].length > 20 ? chartLabels[index].substring(0, 20) + '...' : chartLabels[index];
          }
        }
      },
      y: {
        display: true,
        type: 'logarithmic',
        min: 0.5,
      }
    },
  };

    const chartRef = useRef(null)
    const handleEventChart = debounce((event) => {
      const element = getElementsAtEvent(chartRef.current, event);
      const chart = chartRef.current;
      if (element.length > 0) {
        chart.data.datasets[0].backgroundColor = generateFocusListColor(chartLabels.length, element[0].index);
      } else {
        chart.data.datasets[0].backgroundColor = generateListColor();
      }
      chart.update();
    }, 200);

  return (
    <div className='ue-section'>
      <div className='ue-filter-container'>
        <h3 className="section-title">
          Top Metrics by Query
          <img
            src={tooltipIcon}
            alt="tooltip icon"
            className="tooltip-icon anchor-top-metric-by-query"
          />
          <ReactTooltip
            id='tooltip'
            place="top"
            content="Most frequently queried Metric by users, sorted from highest to lowest"
            anchorSelect=".anchor-top-metric-by-query"
            style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
          />
        </h3>
        <Select
          id="listRole"
          name="listRole"
          isSearchable={false}
          options={dashboardTypeOptions}
          value={selectedDashboard}
          onChange={handleDashboardChange}
          placeholder={"-- Select Dashboard --"}
          classNamePrefix="select"
        />
      </div>
      <div style={{ position: 'relative', height: '300px' }}>
        {isLoading && <LoadingChart />}
        <Chart type='bar' ref={chartRef} data={data} onClick={handleEventChart} options={chartOptions} style={{ height: '300px' }} />
      </div>
    </div>
  )
}

export default TopMetricByQueryChart;