import { format } from "date-fns";

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function convertFormatedDate(date) {
    if(!date) return '-';
    const newDate = new Date(date).toLocaleString('en-US');
    return format(newDate, "yyyy-MM-dd kk:mm:ss");
}