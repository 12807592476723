import React from 'react';
import THead from './THead';
import TBody from './TBody';

const Table = ({ children, headColumn, classes }) => {
  return (
    <table className={classes}>
      <THead headColumn={headColumn} />
      <TBody>
        {children}
      </TBody>
    </table>
  );
};

export default Table;