import { useEffect, useState } from "react";
import useBulkTesting from "../../hooks/useBulkTesting";
import Table from "../../components/Table";
import OutsideHandler from "../../components/OutsideHandler";
import kebabMenu from "../../assets/kebabMenu.svg";
import { ReactComponent as DownloadIcon } from "../../assets/downloadIcon24.svg";
import { ReactComponent as EyeIcon } from "../../assets/eyeIcon24.svg";
import { ReactComponent as DoubleCheckIcon } from "../../assets/doubleCheckIcon16.svg";
import LoadingTable from "../../components/LoadingTable";
import { useDispatch } from "react-redux";
import ManualPagination from "../../components/ManualPagination";
import { useNavigate } from "react-router-dom";
import ApplyConfigurationModal from "./ApplyConfigrationModal";
import { convertFormatedDate } from "../../utils/FunctionHelpers";

const TOTAL_PAGE = 1;
const BulkTesting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedBulkTestFilters,
    currentBulkTestPage,
    setCurrentBulkTestPage,
    appliedConfiguration,
    getAppliedConfiguration,
    getBulkTest,
    bulkTestingData,
    isBuklTestingLoading,
  } = useBulkTesting();
  
  const [sortBy, setSortBy] = useState({ column: 'created_at', direction: 'Descending' });
  const [paginatedData, setPaginatedData] = useState([]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [applyConfiguration, setApplyConfiguration] = useState(null);

  const headColumns = [
    {
      title: 'ID', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    },
    {
      title: 'Configuration', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    },
    {
      title: 'Relevan Score LLM Refine', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'score',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'score', direction: sortBy.direction === 'Descending' ? 'Ascending' : 'Descending' })
      }
    },
    {
      title: 'Avg. Response Time', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'avg_response_time',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'avg_response_time', direction: sortBy.direction === 'Descending' ? 'Ascending' : 'Descending' })
      }
    },
    {
      title: 'Avg. Token', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'avg_token',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'avg_token', direction: sortBy.direction === 'Descending' ? 'Ascending' : 'Descending' })
      }
    },
    {
      title: 'Avg. Cost', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'avg_cost',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'avg_cost', direction: sortBy.direction === 'Descending' ? 'Ascending' : 'Descending' })
      }
    },
    {
      title: 'Status', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    },
    // {
    //   title: 'Date Created', sort: {
    //     isSortable: true,
    //     isSorted: sortBy.column === 'date_created',
    //     isDesc: sortBy.direction === 'desc',
    //     onClick: () => setSortBy({ column: 'date_created', direction: sortBy.direction === 'desc' ? 'asc' : 'desc' })
    //   }
    // },
    {
      title: 'Date Created', sort: {
        isSortable: true,
        isSorted: sortBy.column === 'created_at',
        isDesc: sortBy.direction === 'Descending',
        onClick: () => setSortBy({ column: 'created_at', direction: sortBy.direction === 'Descending' ? 'asc' : 'Descending' })
      }
    },
    {
      title: '', sort: {
        isSortable: false,
        isSorted: false,
        isDesc: false,
        onClick: undefined
      }
    }
  ]

  useEffect(() => {
    getBulkTest({
      ...selectedBulkTestFilters,
      sorting_type: sortBy.direction,
      order_by: sortBy.column
    });
  }, [selectedBulkTestFilters, sortBy])

  const renderStatus = (status) => {
    let badge = null;
    switch (status) {
      case "SUCCESS":
        badge = <span className="status-badge--success">Success</span>;
        break;
      case "PENDING":
        badge = <span className="status-badge--pending">Pending</span>;
        break;
      case "FAILED":
        badge = <span className="status-badge--failed">Failed</span>;
        break;
      default:
        badge = "-";
        break;
    }
    return badge;
  }

  const handleActiveMenu = (index) => {
    if (activeMenuIndex === index) {
      setActiveMenuIndex(null);
    } else {
      setActiveMenuIndex(index);
    }
  }

  const handlePageChange = (page) => {
    setCurrentBulkTestPage(page);
  };

  useEffect(() => {
    if (bulkTestingData.length > 0) {
      const start = (currentBulkTestPage - 1) * 10;
      const end = start + 10;
      setPaginatedData(bulkTestingData.slice(start, end));
    }
  }, [currentBulkTestPage, bulkTestingData]);

  const handleDownloadFile = (url) => {
    const filename = url.split('/').pop();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className="bulk-table">
      <div className="bulk-table__wrapper">
        <Table headColumn={headColumns} classes={''} >
          {
            isBuklTestingLoading ?
              <LoadingTable columnSize={headColumns.length} rowSize={4} />
              : (
                paginatedData.length > 0 ? paginatedData.map(bulk => (
                  <tr key={bulk.id}>
                    <td>{bulk.id}</td>
                    <td>{bulk.configuration}</td>
                    <td>{bulk.refine_score}</td>
                    <td>{bulk.avg_response_time}</td>
                    <td>{bulk.avg_token}</td>
                    <td>{bulk.avg_cost}</td>
                    <td>
                      {renderStatus(bulk.status)
                      }
                    </td>
                    <td>{convertFormatedDate(bulk.created_at)}</td>
                    <td>
                      <div className="kebab-menu-container">
                        <img
                          src={kebabMenu}
                          alt="kebab menu"
                          onClick={() => handleActiveMenu(bulk.id)}
                          style={{ cursor: "pointer" }}
                        />
                        {activeMenuIndex === bulk.id && (
                          <OutsideHandler handleClose={() => handleActiveMenu(null)}>
                            <div className="bulk-menu">
                              <div
                                className="bulk-menu-option"
                                onClick={
                                  () => navigate("/bulk-testing/details", {
                                    state: { data: bulk },
                                  })
                                }
                              >
                                <EyeIcon className="bulk-menu-icon" />
                                View Details
                              </div>
                              <div
                                className="bulk-menu-option"
                                onClick={() => setApplyConfiguration(bulk)}>
                                <DoubleCheckIcon className="bulk-menu-icon" />
                                Apply Configuration
                              </div>
                              {
                                bulk.status === 'SUCCESS' &&
                                <div
                                className="bulk-menu-option"
                                onClick={() => handleDownloadFile(bulk.result_url)}
                              >
                                <DownloadIcon className="bulk-menu-icon" />
                                Download
                              </div>
                              }
                            </div>
                          </OutsideHandler>
                        )}
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={headColumns.length} className="text-center">No data available</td>
                  </tr>
                )
              )
          }
        </Table>
      </div>
      <ManualPagination
        currentPage={currentBulkTestPage}
        itemsLength={bulkTestingData.length > 0 ? bulkTestingData.length : currentBulkTestPage}
        itemsPerPage={10}
        handlePageChange={handlePageChange}
      />
      <ApplyConfigurationModal
        bulk={applyConfiguration}
        appliedConfiguration={appliedConfiguration}
        getAppliedConfiguration={getAppliedConfiguration}
        onClose={() => setApplyConfiguration(null)}
      />
    </div >
  );
}

export default BulkTesting;