import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditModal from "./EditModal";
import { Alert } from "../../components";
import backArrow from "../../assets/backArrow.svg";
import { format } from "date-fns";
import axiosCMS from "../../libs/axios/axiosCMS";

const FunctionDetails = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dataFunction, setDataFunction] = useState({});
  const [dataSampleQuestions, setDataSampleQuestions] = useState([]);

  const functionId = location.state?.id || "";

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleOpenModal = () => {
    setIsEditModalOpen(true);
  };

  const getDataFunction = useCallback((params) => {
    // API call to get function details
    axiosCMS({
      method: "post",
      url: `prompt_function/get/detail/`,
      params: params,
    }).then(
      (response) => {
        const {
          data: {
            code,
            data: { function: dataFunction, sample_question: sampleQuestions },
          },
        } = response;

        if (code !== 200) {
          console.error("Error fetching function details: ", response);
          return;
        }

        setDataFunction(dataFunction);
        setDataSampleQuestions(sampleQuestions || []);
      },
      (error) => {
        const { message, response: { data: { detail } = {} } = {} } = error;

        showAlert({
          type: "error",
          title: message || "Error",
          message: detail || "Error fetching data",
        });
      }
    );
  }, []);

  useEffect(() => {
    getDataFunction({ id: functionId });
  }, [functionId, getDataFunction]);

  return (
    <>
      <Alert ref={alertRef} />

      <div className="function-details">
        <div className="function-details-header">
          <img
            src={backArrow}
            className="back-button"
            alt="return button"
            onClick={() => navigate(-1)}
          />
          <h2 className="function-details-title">Function Details</h2>
          <button className="function-edit-button" onClick={handleOpenModal}>
            Edit Function
          </button>
        </div>
        <div className="function-overview-container">
          <h3 className="overview-title">Overview</h3>
          <div className="function-container">
            <div className="overview-box function-name">
              <p className="overview-box-title">Function Name:</p>
              <hr className="overview-separator" />
              <p className="overview-description">{dataFunction.name}</p>
            </div>
            <div className="overview-box function-name">
              <p className="overview-box-title">Last Updated:</p>
              <hr className="overview-separator" />
              <p className="overview-description">
                {dataFunction.updated_at ? format(
                  new Date(dataFunction.updated_at),
                  "yyyy-MM-dd hh:mm:ss.SSX"
                ) : "-"}
              </p>
            </div>
            <div className="overview-box description">
              <p className="overview-box-title">Description:</p>
              <hr className="overview-separator" />
              <p className="overview-description">{dataFunction.description}</p>
            </div>
          </div>
        </div>
        <div className="overview-container sample-questions-container">
          <h3 className="overview-title">Sample Question</h3>
          <ul className="sample-questions-list">
            {dataSampleQuestions.map((question, index) => (
              <li key={index}>{question.question}</li>
            ))}
          </ul>
        </div>

        <EditModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSuccess={() => {
            setIsEditModalOpen(false);
            getDataFunction({ id: functionId });
          }}
          formData={{
            function: dataFunction,
            sampleQuestions: dataSampleQuestions,
          }}
        />
      </div>
    </>
  );
};

export default FunctionDetails;
