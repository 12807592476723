import closeIcon from "../../assets/cancelIcon.svg";
import copyIcon from "../../assets/clipboardCopyIconWhite24.svg";

const QueryModal = ({ query, onClose }) => {
  if (query === null) return null;
  return (
    <div className="query-result__overlay">
      <div className="query-result__modal">
        <div className="query-result__header">
          <h2 className="query-result__header-title">Query</h2>
          <button className="query-result__close" onClick={onClose}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="query-result__separator"></div>
        <div className="query-result__content">
          <div className="query-result__sql">
            <div className="query-result__copy">
              <img
              onClick={() => navigator.clipboard.writeText(query)}
               className="cursor-pointer"
               src={copyIcon} 
               alt="copy" 
                />
            </div>
            <div className="query-result__separator"></div>
            <p className="query-result__sql-styled">
              {query}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QueryModal;