import closeIcon from "../../assets/cancelIcon.svg";

import { useState } from "react";
import useBulkTesting from "../../hooks/useBulkTesting";
import Select from "react-select";
import OutsideHandler from "../../components/OutsideHandler";

const FilterModal = ({ isOpen, onClose }) => {
  const { selectedBulkTestFilters, setSelectedBulkTestFilters } = useBulkTesting();
  const [filters, setFilters] = useState({});
  const [statusOptions, setStatusOptions] = useState([
    { label: "Pending", value: "PENDING" },
    { label: "Success", value: "SUCCESS" },
  ]);

  const [configurationOptions, setConfigurationOptions] = useState([
    { label: "intent_detector", value: "intent_detector" },
    { label: "context_schema", value: "context_schema" },
    { label: "question_list", value: "question_list" },
    { label: "cache_response", value: "cache_response" },
  ]);

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  };

  const handleClear = () => {
    setSelectedBulkTestFilters({
      ...selectedBulkTestFilters,
      configuration: null,
      status: null
    })
    setFilters({
      configuration: null,
      status: null
    })
    onClose();
  }

  const handleApply = () => {
    setSelectedBulkTestFilters({
      ...selectedBulkTestFilters,
      configuration: filters.configuration ? filters.configuration.value : null,
      status: filters.status ? filters.status.value : null
    });
    onClose();
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onClose()}>
      <div className="bulk-filter__wrapper">
        <div className="bulk-filter__header">
          <h2 className="bulk-filter__title">Filter</h2>
          <button className="bulk-filter__close" onClick={() => onClose()}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="bulk-filter__separator" />
        <div className="bulk-filter__content">
          <div className="bulk-filter__form-row">
            <div className="bulk-filter__form-group">
              <label htmlFor="configuration" className="bulk-filter__input-label">Configuration</label>
              <Select
                id="configuration"
                name="configuration"
                options={configurationOptions}
                value={filters.configuration}
                onChange={handleInputChange}
                placeholder="-- Select Configuration --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="bulk-filter__form-group">
              <label htmlFor="status" className="bulk-filter__input-label">Status</label>
              <Select
                id="status"
                name="status"
                options={statusOptions}
                value={filters.status}
                onChange={handleInputChange}
                placeholder={"-- Select Status --"}
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="bulk-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;