import React, { useCallback, useEffect, useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import starIcon from "../../assets/starIcon.svg"
import fullStarIcon from "../../assets/fullStar.svg"
import halfStarIcon from "../../assets/halfStar.svg"
import emptyStarIcon from "../../assets/emptyStar.svg"
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";
import "./ResponseQuality.css";
import { format } from "date-fns";
import LoadingComponent from "../../components/Loading";
import debounce from "../../utils/Debounce";
import axiosCMS from "../../libs/axios/axiosCMS";
import { convertFormatedDate } from "../../utils/FunctionHelpers";

const UserRating = ({ isOpen, onClose, selectedFilters }) => {

  const [userRatingData, setUserRatingData] = useState({});
  const [listFeedback, setListFeedback] = useState([]);
  const [listFeedbackPaginated, setListFeedbackPaginated] = useState([]);
  const [isLoadingUserRating, setIsLoadingUserRating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState({ order_by: "timestamp", sort: "desc" });
  const rowsPerPage = 5;
  const TOTAL_STARS = 5;

  const indexOfLastUserRating = currentPage * rowsPerPage;
  const indexOfFirstUserRating = indexOfLastUserRating - rowsPerPage;

  const paginate = (direction) => {
    if (direction === 'next' && currentPage < Math.ceil(listFeedback.length / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getFilledStars = () => {
    return Math.floor(userRatingData.average_rating ?? 5);
  }

  const getHalfStar = () => {
    return userRatingData.average_rating % 1 > 0 ? 1 : 0;
  }

  const getEmptyStars = () => {
    return TOTAL_STARS - getFilledStars() - getHalfStar();
  }

  const getUserRating = useCallback(debounce(async (filters, sortBy) => {
    setIsLoadingUserRating(true);
    try {
      const URL = "response-quality/rating-detail"
      const response = await axiosCMS.post(URL,
        { ...filters, ...sortBy }
      )
      if (response.status === 200) {
        const result = response.data;
        if (result.data.list_feedback.length === 0) {
          setListFeedback([]);
        } else {
          const sortedListFeedback = result.data.list_feedback.sort((a, b) => {
            if (sortBy.sort === 'asc') {
              return new Date(a.timestamp) - new Date(b.timestamp);
            } else {
              return new Date(b.timestamp) - new Date(a.timestamp);
            }
          });
          setListFeedback(sortedListFeedback);
        }
        setUserRatingData({
          average_rating: result.data.average_rating,
          total_feedback: result.data.total_feedback,
          star_1: result.data.star_1,
          star_2: result.data.star_2,
          star_3: result.data.star_3,
          star_4: result.data.star_4,
          star_5: result.data.star_5,
        });
        setCurrentPage(1);
      }
    } catch (error) {
      console.error("Error getting user rating data:", error);
    }
    setIsLoadingUserRating(false);
  }, 1500), []);

  useEffect(() => {
    if (isOpen) {
      getUserRating(selectedFilters, selectedSort);
    }
  }, [isOpen]);

  const userRatingPaginator = () => {
    const paginated = listFeedback.slice(indexOfFirstUserRating, indexOfLastUserRating);
    setListFeedbackPaginated(paginated);
  }

  useEffect(() => {
    userRatingPaginator();
  }, [currentPage, listFeedback])

  if (!isOpen) return null;

  return (
    <div className={`rq-user-rating ${isOpen ? "open" : ""}`}>
      <div className="rq-user-rating-content">
        <div className="rq-user-rating-header">
          <h1 className="rq-user-rating-title">User Rating</h1>
          <button className="rq-rating-close-button" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" />
          </button>
        </div>
        <div className="rq-rating-separator" />
        <div className="rq-rating-summary">
          <div className="rq-rating-number">
            <h2>{userRatingData.average_rating?.toFixed(1) ?? "5.0"}</h2>
            <div className="stars">
              {Array.from({ length: getFilledStars() }, (_, index) => (
                <span key={`filled-${index}`}><img src={fullStarIcon} alt="full star" /></span>
              ))}
              {getHalfStar() === 1 && <span><img src={halfStarIcon} alt="half star" /></span>}
              {Array.from({ length: getEmptyStars() }, (_, index) => (
                <span key={`empty-${index}`} className="empty-star"><img src={emptyStarIcon} alt="empty star" /></span>
              ))}
            </div>
            <p>{userRatingData.total_feedback} feedback</p>
          </div>
          <div className="rq-rating-breakdown">
            <div className="rq-rating-bar">
              <div className="rq-rating-star">
                <img src={starIcon} alt="star" />
                5
              </div>
              <div className="rq-bar">
                <div
                  className="rq-bar-fill"
                  style={{
                    width: `${(userRatingData.star_5 / userRatingData.total_feedback) * 100}%`
                  }}
                ></div>
              </div>
              <span className="rq-rating-star-number text-right">{userRatingData.star_5}</span>
            </div>
            <div className="rq-rating-bar">
              <div className="rq-rating-star">
                <img src={starIcon} alt="star" />
                4
              </div>
              <div className="rq-bar">
                <div
                  className="rq-bar-fill"
                  style={{
                    width: `${(userRatingData.star_4 / userRatingData.total_feedback) * 100}%`
                  }}
                ></div>
              </div>
              <span className="rq-rating-star-number text-right">{userRatingData.star_4}</span>
            </div>
            <div className="rq-rating-bar">
              <div className="rq-rating-star">
                <img src={starIcon} alt="star" />
                3
              </div>
              <div className="rq-bar">
                <div
                  className="rq-bar-fill"
                  style={{
                    width: `${(userRatingData.star_3 / userRatingData.total_feedback) * 100}%`
                  }}
                ></div>
              </div>
              <span className="rq-rating-star-number text-right">{userRatingData.star_3}</span>
            </div>
            <div className="rq-rating-bar">
              <div className="rq-rating-star">
                <img src={starIcon} alt="star" />
                2
              </div>
              <div className="rq-bar">
                <div
                  className="rq-bar-fill"
                  style={{
                    width: `${(userRatingData.star_2 / userRatingData.total_feedback) * 100}%`
                  }}
                ></div>
              </div>
              <span className="rq-rating-star-number text-right">{userRatingData.star_2}</span>
            </div>
            <div className="rq-rating-bar">
              <div className="rq-rating-star">
                <img src={starIcon} alt="star" />
                1
              </div>
              <div className="rq-bar">
                <div
                  className="rq-bar-fill"
                  style={{
                    width: `${(userRatingData.star_1 / userRatingData.total_feedback) * 100}%`
                  }}
                ></div>
              </div>
              <span className="rq-rating-star-number text-right">{userRatingData.star_1}</span>
            </div>
          </div>
        </div>

        <div className="rq-user-rating-section">
          <table className="rq-user-rating-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>
                  <div className="group-flex-8 items-center">
                    <span>Timestamp</span>
                    <img
                      src={
                        selectedSort.order_by === "timestamp"
                          ? selectedSort.sort === "desc"
                            ? sortIconAsc
                            : sortIconDesc
                          : sortIcon
                      }
                      className="sort-icon"
                      alt="Sort icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedSort({
                          order_by: "timestamp",
                          sort:
                            selectedSort.order_by === "timestamp"
                              ? selectedSort.sort === "asc"
                                ? "desc"
                                : "asc"
                              : "asc",
                        });
                      }}
                    />
                  </div>
                </th>
                <th>Rating</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingUserRating
                ? <tr><td colSpan="5" className="text-center"><LoadingComponent message={'Loading data...'} /></td></tr>
                : listFeedbackPaginated.map((data, index) => (
                  <tr key={index}>
                    <td>{data.email}</td>
                    <td>{data.timestamp && convertFormatedDate(new Date(data.timestamp), "yyyy-MM-dd hh:mm:ss.SSX")}</td>
                    <td>{data.rating}</td>
                    <td>{data.reason ?? "-"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="rq-user-rating-pagination">
            <button onClick={() => paginate('prev')} disabled={currentPage === 1}>Previous</button>
            {/* <span>Page {currentPage} of {Math.ceil(feedbacks.length / rowsPerPage)}</span> */}
            <button onClick={() => paginate('next')} disabled={currentPage === Math.ceil((listFeedback.length > 0 ? listFeedback.length : 1) / rowsPerPage)}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRating;
