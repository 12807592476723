import { useState } from "react";
import OutsideHandler from "../../components/OutsideHandler";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";

const FilterModal = ({ isOpen, onFilterModal, onApply, roleOptions, dashboardOptions, cmsPermissionOptions }) => {
  const [statusOptions, setStatusOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]);

  const [cmsAccessOptions, setCmsAccessOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]);

  const [filters, setFilters] = useState({
    role_group: [],
    cms_access: { label: "ALL", value: "" },
    cms_permission: [],
    status: { label: "ALL", value: "" },
  })

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  };

  const handleClear = () => {
    onApply({
      role_group: [],
      cms_access: "",
      cms_permission: [],
      status: "",
    });
    setFilters({
      role_group: [],
      cms_access: { label: "ALL", value: "" },
      cms_permission: [],
      status: { label: "ALL", value: "" },
    });
    onFilterModal(false);
  }

  const handleApply = () => {
    onApply({
      role_group: filters.role_group.map((role) => role.value),
      cms_access: filters.cms_access.value,
      cms_permission: filters.cms_permission.map((permission) => permission.value),
      status: filters.status.value,
    });
    onFilterModal(false);
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onFilterModal(false)}>
      <div className="user-filter__wrapper">
        <div className="user-filter__header">
          <h2 className="user-filter__title">Filter</h2>
          <button className="user-filter__close" onClick={() => onFilterModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="user-filter__separator" />
        <div className="user-filter__content">
          <div className="user-filter__form-row">
            <div className="user-filter__form-group">
              <label htmlFor="role_group" className="user-filter__input-label">Role Group</label>
              <Select
                id="role_group"
                name="role_group"
                isMulti={true}
                options={roleOptions}
                value={filters.role_group}
                onChange={handleInputChange}
                placeholder="-- Select Role Group --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
            <div className="user-filter__form-group">
              <label htmlFor="cms_access" className="user-filter__input-label">CMS Access</label>
              <Select
                id="cms_access"
                name="cms_access"
                options={cmsAccessOptions}
                value={filters.cms_access}
                onChange={handleInputChange}
                placeholder={"-- Select CMS Access --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
          </div>
          <div className="user-filter__form-row">
            <div className="user-filter__form-group">
              <label htmlFor="cms_permission" className="user-filter__input-label">Permission Group</label>
              <Select
                id="cms_permission"
                name="cms_permission"
                isMulti={true}
                options={cmsPermissionOptions}
                value={filters.cms_permission}
                onChange={handleInputChange}
                placeholder="-- Select Permission Group --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
            <div className="user-filter__form-group">
              <label htmlFor="status" className="user-filter__input-label">Status</label>
              <Select
                id="status"
                name="status"
                options={statusOptions}
                value={filters.status}
                onChange={handleInputChange}
                placeholder={"-- Select Status --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
          </div>
        </div>
        <div className="user-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;