import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputErrorMessage from "../../components/InputErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch } from "react-redux";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";
import { addToast } from "../../libs/redux/slices/toastSlice";
import axiosBulk from "../../libs/axios/axiosBulk";

const AddGenerateActualAnswerModal = ({ isOpen, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const btnFinalSubmitRef = useRef(null);

  const [granularityTypeOptions, setGranularityTypeOptions] = useState([
    { label: "PSM", value: "PSM" },
    { label: "Region", value: "Region" },
    { label: "Estate", value: "Estate" },
    { label: "Division", value: "Division" },
    { label: "Complex", value: "Complex" },
  ]);

  const [metricsTypeOptions, setMetricsTypeOptions] = useState([
    { label: "MVD Production", value: "MVD Production" },
    { label: "MVD Investor Downstream", value: "MVD Investor Downstream" },
    { label: "MVD Investor Upstream", value: "MVD Investor Upstream" },
    { label: "MVD Investor Yield", value: "MVD Investor Yield" },
    { label: "MVD Investor Seed", value: "MVD Investor Seed" },
    { label: "iCON SPB", value: "iCON SPB" },
    { label: "iCON Denda", value: "iCON Denda" },
    { label: "iCON IPD", value: "iCON IPD" },
  ]);

  const [fileUpload, setFileUpload] = useState(null);
  const [otherErrors, setOtherErrors] = useState({
    fileUpload: null
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { register, reset, watch, setValue, control, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const handleFileUpload = (e) => {
    setOtherErrors({ ...otherErrors, fileUpload: null });
    const file = e.target.files[0];
    if (file.size > (20 * 1000 * 1000)) {
      setOtherErrors({ ...otherErrors, fileUpload: "Please upload file smaller than 20 MB." });
      return;
    }
    if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setOtherErrors({ ...otherErrors, fileUpload: "Invalid file type. Only Excel (.xlsx) files are allowed" });
      return;
    }
    setFileUpload(file);
  }

  const postActualAnswer = async (data) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    const URL = "testing/generate";
    try {
      const response = await axiosBulk.post(URL, {}, {
        params: data
      });
      if (response.status === 200) {
        dispatch(addToast({
          type: "success",
          title: "Successfully generated actual answer",
        }))
        onClose();
        reset()
        onSuccess();
      }
    } catch (error) {
      dispatch(addToast({
        type: "error",
        title: "Failed to generate actual answer",
      }))
    }
    btnFinalSubmitRef.current.removeAttribute("disabled");
  }

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = (data) => {
    const formatedData = {
      metrics_type: data.metrics_type.value,
      granularity_type: data.granularity_type.value,
      test_case_count: data.test_case_count,
    }
    postActualAnswer(formatedData);
  }

  if (!isOpen) return null;
  return (
    <div className="bulk-add__overlay">
      <div className="bulk-add__modal">
        <div className="bulk-add__header">
          <h2 className="bulk-add__title">New Bulk Test - Generate Actual Answer</h2>
          <button className="bulk-add__close" onClick={() => {
            handleConfirmation(false)
            onClose()
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="bulk-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="bulk-add__content">
                  <h3 className="bulk-add__confirmation-title">Do you really wish to submit new bulk test?</h3>
                  <p className="bulk-add__confirmation-desc">Once you submit, the new bulk test will be added to the system.</p>
                  <div className="bulk-add__footer">
                    <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button type="submit" ref={btnFinalSubmitRef} className="btn-primary font-primary" >Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="bulk-add__content">
                  <div className="bulk-add__form-group">
                    <label className="bulk-add__input-label" htmlFor="metrics_type">Metrics Type <span className="bulk-add__input-required">*</span></label>
                    <Controller
                      control={control}
                      name="metrics_type"
                      rules={{
                        required: "Metrics Type must be filled",
                      }}
                      render={({ field }) => (
                        <Select
                          id="metrics_type"
                          name="metrics_type"
                          {...field}
                          options={metricsTypeOptions}
                          placeholder="-- Select Metrics Type --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="metrics_type"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="bulk-add__form-group">
                    <label className="bulk-add__input-label" htmlFor="granularity_type">Granularity Type <span className="bulk-add__input-required">*</span></label>
                    <Controller
                      control={control}
                      name="granularity_type"
                      rules={{
                        required: "Granularity Type must be filled",
                      }}
                      render={({ field }) => (
                        <Select
                          id="granularity_type"
                          name="granularity_type"
                          {...field}
                          options={granularityTypeOptions}
                          placeholder="-- Select Granularity Type --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="granularity_type"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="bulk-add__form-group">
                    <label className="bulk-add__input-label" htmlFor="test_case_count">Test Case Count <span className="bulk-add__input-required">*</span></label>
                    <input
                      type="number"
                      className="bulk-add__input-box"
                      {...register("test_case_count", {
                        required: "Test Case Count must be filled",
                        valueAsNumber: {
                          value: true,
                          message: "Test Case Count must be a number"
                        },
                        min: {
                          value: 1,
                          message: "Test Case Count should not be less than 1"
                        },
                        max: {
                          value: 5,
                          message: "Test Case Count should not exceed 5"
                        }
                      })}
                    />
                    <div style={{ fontSize: '14px', marginTop: '2px', color: '#7B7B86' }}>Maximum: 5</div>
                    <ErrorMessage
                      errors={errors}
                      name="test_case_count"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>

                  {/* <div className="bulk-add__form-group bulk-add__input-box">
                    <div className="group-flex-2 justify-between align-center">
                      <div>
                        <label className="bulk-add__input-label" htmlFor="file_upload">Upload Required File <span className="bulk-add__input-required">*</span></label>
                        <p className="bulk-add__input-sub-label">File type: Excel (.xlsx)</p>
                      </div>
                      <div>
                        <input type="file" id="file_upload" className="bulk-add__file-upload" onChange={handleFileUpload} />
                        <button type="button" className="bulk-add__file-upload-btn with-icon" onClick={() => document.getElementById('file_upload').click()}>
                          <UploadIcon className="bulk-add__file-upload-icon" />
                          <span>Upload</span>
                        </button>
                      </div>
                    </div>
                    {
                      fileUpload && (
                        <div className="bulk-add__input-box bulk-add__file-item">
                          <FileExcelIcon className="bulk-add__file-excel-icon" />
                          <div>
                            <p className="bulk-add__file-name">{fileUpload.name}</p>
                            <p className="bulk-add__file-size">
                              {
                                fileUpload.size > 1000000
                                  ? `${Math.round((fileUpload.size / 1000000) * 100) / 100} MB`
                                  : fileUpload.size > 1000
                                    ? `${Math.round((fileUpload.size / 1000) * 100) / 100} KB`
                                    : `${fileUpload.size} B`
                              }</p>
                          </div>
                          <img src={closeIcon} alt="close icon" className="cursor-pointer" onClick={() => setFileUpload(null)} />
                        </div>
                      )
                    }
                    {
                      otherErrors.fileUpload && <InputErrorMessage message={otherErrors.fileUpload} />
                    }
                  </div> */}
                  <div className="bulk-add__footer">
                    <button type="button" className="btn-base font-base" onClick={onClose}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Execute
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default AddGenerateActualAnswerModal;