import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backArrow from "../../assets/backArrow.svg";
import TopBar from "../../components/TopBar";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Table from "../../components/Table";
import BotAnswerModal from "./BotAnswerModal";
import ApplyConfigurationModal from "./ApplyConfigrationModal";
import useBulkTesting from "../../hooks/useBulkTesting";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import QueryModal from "./QueryModal";
import { convertFormatedDate } from "../../utils/FunctionHelpers";
import { ReactComponent as DoubleCheckIcon } from "../../assets/doubleCheckIcon16.svg";
import { ReactComponent as DownloadIcon } from "../../assets/downloadIcon24.svg";

const DEFAULT_SORT = {
  isSortable: false,
  isSorted: false,
  isDesc: false,
  onClick: undefined
};

const BulkTestDetail = () => {
  useDocumentTitle("Bulk Testing Details")
  const { appliedConfiguration, setAppliedConfiguration } = useBulkTesting();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const data = useMemo(() => location.state?.data || {}, [location.state]);
  const [bulkResultData, setBulkResultData] = useState([]);
  const [applyConfiguration, setApplyConfiguration] = useState(null);
  const [botAnswer, setBotAnswer] = useState(null);
  const [queryResult, setQueryResult] = useState(null);

  const handleClick = () => {
    navigate("/bulk-testing");
  };

  const prevRoutes = [
    { name: 'Bulk Testing', route: null }
  ]

  const headColumns = [
    {
      title: 'Question', sort: DEFAULT_SORT, width: '300px'
    },
    {
      title: 'Actual Answer', sort: DEFAULT_SORT, width: '300px'
    },
    {
      title: 'Bot Answer', sort: DEFAULT_SORT
    },
    {
      title: 'Relevant Score LLM Refine', sort: DEFAULT_SORT
    },
    {
      title: 'Relevant Score LLM Original', sort: DEFAULT_SORT
    },
    {
      title: 'Query Result', sort: DEFAULT_SORT, width: '400px'
    },
    {
      title: 'Response Time', sort: DEFAULT_SORT
    },
    {
      title: 'Status Code', sort: DEFAULT_SORT
    },
    {
      title: 'Capability', sort: DEFAULT_SORT
    },
    {
      title: 'Fallback', sort: DEFAULT_SORT
    },
    {
      title: 'Query', sort: DEFAULT_SORT
    },
    {
      title: 'Kwargs', sort: DEFAULT_SORT, width: '300px'
    },
    {
      title: 'Configuration', sort: DEFAULT_SORT
    },
    {
      title: 'Token Consumption Detail', sort: DEFAULT_SORT, width: '400px'
    },
    {
      title: 'Token Consumption Total', sort: DEFAULT_SORT
    },
    {
      title: 'Cost Consumption Total', sort: DEFAULT_SORT
    },
    {
      title: 'Token Delta Bulk', sort: DEFAULT_SORT
    },
  ]

  const getBulkResultData = async () => {
    if (!data.result_url_json) return;
    try {
      const response = await axios.get(data.result_url_json, {
        responseType: 'blob'
      });
      if (response.status === 200) {
        const text = await response.data.text();
        const jsonData = JSON.parse(text);
        console.log(jsonData);
        setBulkResultData(jsonData);
      }
    } catch (error) {
      dispatch(addToast({
        type: "error",
        title: "Failed to fetch bulk detail data."
      }))
    }
  }

  useEffect(() => {
    if (data) {
      getBulkResultData();
    }
  }, [])

  const handleDownloadFile = (url) => {
    const filename = url.split('/').pop();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  const renderStatus = (status) => {
    let badge = null;
    switch (status) {
      case "SUCCESS":
        badge = <span className="status-badge--success">Success</span>;
        break;
      case "PENDING":
        badge = <span className="status-badge--pending">Pending</span>;
        break;
      case "FAILED":
        badge = <span className="status-badge--failed">Failed</span>;
        break;
      default:
        badge = "-";
        break;
    }
    return badge;
  }

  return (
    <>
      <TopBar
        prevRoutes={prevRoutes}
        currentRoute={"Test Details"}
      />
      <div className="bulk-testing-detail">
        <div className="bulk-detail__header">
          <div className="group-flex-16 align-center">
            <img src={backArrow} alt="Back" className="cursor-pointer" onClick={handleClick} />
            <h5 className="bulk-detail__title">Bulk Testing Details</h5>
          </div>
          <div className="group-flex-16">
            {
              data.status === 'SUCCESS' &&
              <button
                className="btn-base font-base with-icon"
                onClick={() => handleDownloadFile(data.result_url)}
              >
                <DownloadIcon className="bulk-menu-icon" />
                Download
              </button>
            }
            <button
              className="btn-primary font-primary with-icon"
              onClick={() => setApplyConfiguration(data)}
            >
              <DoubleCheckIcon className="bulk-menu-icon" />
              Apply Configuration</button>
          </div>
        </div>
        <div>
          Overviews
        </div>
        <div className="bulk-detail__content">
          <div className="bulk-detail__boxes">
            <div className="bulk-detail__box">
              <h5>Email</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.email}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>ID</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.id}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Revelant Score LLM Refine</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.refine_score}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Avg. Response Time</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.avg_response_time}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Avg. Token</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.avg_token}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Avg. Cost</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.avg_cost}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Date Created</h5>
              <div className="bulk-detail__separator"></div>
              <p>{convertFormatedDate(data.created_at)}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Status</h5>
              <div className="bulk-detail__separator"></div>
              <p>{renderStatus(data.status)}</p>
            </div>
          </div>
        </div>
        <div className="bulk-detail__box">
          <h5>Configuration</h5>
          <div className="bulk-detail__separator"></div>
          <p>{data.configuration}</p>
        </div>
        <div>
          Table Details
        </div>
        <div className="bulk-testing">
          <div className="bulk-table">
            <div className="bulk-table__wrapper">
              <Table headColumn={headColumns} classes={''}>
                {bulkResultData.length > 0 ? bulkResultData.map((bulk, index) => (
                  <tr key={index}>
                    <td>{bulk.question}</td>
                    <td>{bulk.actual_answer}</td>
                    <td>
                      <button
                        className="btn-base-sm font-base"
                        onClick={() => setBotAnswer(bulk.bot_answer)}
                      >View</button>
                    </td>
                    <td>{bulk.relevant_score_llm_refine ? "true" : "false"}</td>
                    <td>{bulk.relevant_score_llm_original ? "true" : "false"}</td>
                    <td>{JSON.stringify(bulk.query_result)}</td>
                    <td>{bulk.response_time}</td>
                    <td>{bulk.status_code}</td>
                    <td>{bulk.capability}</td>
                    <td>{bulk.fallback}</td>
                    <td>
                      <button
                        className="btn-base-sm font-base"
                        onClick={() => setQueryResult(bulk.query)}
                      >View</button>
                    </td>
                    <td>{bulk.kwargs ? JSON.stringify(bulk.kwargs) : "-"}</td>
                    <td>{bulk.configuration}</td>
                    <td>{JSON.stringify(bulk.token_consumption_detail)}</td>
                    <td>{bulk.token_consumption_total}</td>
                    <td>{bulk.cost_consumption_total}</td>
                    <td>{bulk.token_delta_bulk}</td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={headColumns.length}>No data available</td>
                  </tr>
                )}
              </Table>
            </div>
          </div>
          <QueryModal query={queryResult} onClose={() => setQueryResult(null)} />
          <BotAnswerModal botAnswer={botAnswer} onClose={() => setBotAnswer(null)} />
          <ApplyConfigurationModal
            bulk={applyConfiguration}
            appliedConfiguration={appliedConfiguration}
            onClose={() => setApplyConfiguration(null)}
          />
        </div>
      </div>
    </>
  )
}

export default BulkTestDetail;