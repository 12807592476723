import { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputErrorMessage from "../../components/InputErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import { addToast } from "../../libs/redux/slices/toastSlice";
import { useDispatch } from "react-redux";
import axiosCMS from "../../libs/axios/axiosCMS";
import { FixedSizeList } from "react-window";

const AddDashboardModal = ({ isOpen, dashboard, onClose, psmList, regionList, estateList, divisionList, complexList, getDashboards }) => {
  const dispatch = useDispatch();
  const btnFinalSubmitRef = useRef(null);
  const { register, reset, control, getValues, watch, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all",
  });
  // const { fields: fieldsAllAccess, append: appendAllAccess, remove: removeAllAccess, replace: replaceAllAccess } = useFieldArray({ control, name: "permissionAllAccess" });
  // const { fields: fieldsMdo, append: appendMdo, remove: removeMdo, replace: replaceMdo } = useFieldArray({ control, name: "permissionMDO" });
  const { fields: fieldsPsm, append: appendPsm, remove: removePsm, replace: replacePsm } = useFieldArray({ control, name: "permissionPSM" });
  const { fields: fieldsRegion, append: appendRegion, remove: removeRegion, replace: replaceRegion } = useFieldArray({ control, name: "permissionRegion" });
  const { fields: fieldsEstate, append: appendEstate, remove: removeEstate, replace: replaceEstate } = useFieldArray({ control, name: "permissionEstate" });
  const { fields: fieldsDivision, append: appendDivision, remove: removeDivision, replace: replaceDivision } = useFieldArray({ control, name: "permissionDivision" });
  const { fields: fieldsComplex, append: appendComplex, remove: removeComplex, replace: replaceComplex } = useFieldArray({ control, name: "permissionComplex" });

  useEffect(() => {
    // replaceMdo(mdoList);
    // replaceAllAccess(allAccessList);
    replacePsm(psmList);
    replaceRegion(regionList);
    replaceEstate(estateList);
    replaceDivision(divisionList);
    replaceComplex(complexList);
  }, [psmList, regionList, estateList, divisionList, complexList]);

  const [panelAccess, setPanelAccess] = useState({});
  const [filterAccess, setFilterAccess] = useState({});
  const [permissionTabActive, setPermissionTabActive] = useState('PSM');
  const [permissionTabList, setPermissionTabList] = useState(['PSM', 'Region', 'Estate', 'Division', 'Complex']);

  const panelAccessOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const filterAccessOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const isWaroomOptions = [
    { value: true, label: 'Warroom' },
    { value: false, label: 'Desktop' }
  ];

  const statusOptions = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' }
  ];

  const handleSelectChange = (selectedOptions, key) => {
    switch (key.name) {
      case 'panel_access':
        setPanelAccess(selectedOptions);
        break;
      case 'filter_access':
        setFilterAccess(selectedOptions);
        break;
      default:
        break;
    }
  };

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = async (data) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    const formatedData = {
      name: data.dashboard_name,
      description: data.description,
      report_id: data.report_id,
      dataset_id: data.dataset_id,
      is_panel: data.panel_access.value,
      is_filter: data.filter_access.value,
      is_warroom: data.is_warroom.value,
      status: data.is_active.value,
      access: [
        // ...data.permissionAllAccess.filter((item) => item.acc_name),
        // ...data.permissionMDO.filter((item) => item.acc_name),
        ...data.permissionPSM.filter((item) => item.acc_name),
        ...data.permissionRegion.filter((item) => item.acc_name),
        ...data.permissionEstate.filter((item) => item.acc_name),
        ...data.permissionDivision.filter((item) => item.acc_name),
        ...data.permissionComplex.filter((item) => item.acc_name)
      ]
    }
    const URL = 'dashboard/create/';
    try {
      const response = await axiosCMS.post(URL, formatedData);
      if (response.status === 200) {
        dispatch(addToast({
          type: 'success',
          title: 'New data was successfully added',
        }));
        onClose(false);
        setIsConfirmationOpen(false);
        reset();
        getDashboards();
      }
    } catch (error) {
      console.error('Failed to add new data', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to add new data',
      }));
    }
    btnFinalSubmitRef.current.removeAttribute("disabled");
  }

  if (!isOpen) return null;
  return (
    <div className="dashboard-add__overlay">
      <div className="dashboard-add__modal">
        <div className="dashboard-add__header">
          <h2 className="dashboard-add__title">Add Dashboard</h2>
          <button className="dashboard-add__close" onClick={() => {
            onClose(false);
            handleConfirmation(false);
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="dashboard-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="dashboard-add__content">
                  <h3 className="dashboard-add__confirmation-title">Do you really wish to submit new dashboard?</h3>
                  <p className="dashboard-add__confirmation-desc">Once you submit, the new dashboard will be added to the system.</p>
                  <div className="dashboard-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button type="submit" ref={btnFinalSubmitRef} className="btn-primary font-primary">Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="dashboard-add__content">
                  <div className="dashboard-add__form-group">
                    <label className="dashboard-add__input-label" htmlFor="dashboard_name">Dashboard Name <span className="dashboard-add__input-required">*</span></label>
                    <input type="text" className="dashboard-add__input-box"
                      {...register("dashboard_name", {
                        required: "Dashboard name must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dashboard_name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="dashboard-add__form-group">
                    <label className="dashboard-add__input-label" htmlFor="description">Description</label>
                    <input type="text" className="dashboard-add__input-box"
                      {...register("description")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="dashboard-add__form-row">
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="report_id">Report ID <span className="dashboard-add__input-required">*</span></label>
                      <input type="text" className="dashboard-add__input-box"
                        {...register("report_id", {
                          required: "Report ID must be filled",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="report_id"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="dataset_id">Dataset ID <span className="dashboard-add__input-required">*</span></label>
                      <input type="text" className="dashboard-add__input-box"
                        {...register("dataset_id", {
                          required: "Dataset ID must be filled",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="dataset_id"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="dashboard-add__form-row">
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="panel_access">Report Section <span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="panel_access"
                        rules={{ required: "Panel Access must be filled" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={panelAccessOptions}
                            placeholder="-- Select Panel Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="panel_access"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="filter_access">Filter Panel <span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="filter_access"
                        rules={{ required: "Filter Access must be filled" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={filterAccessOptions}
                            placeholder="-- Select Filter Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="filter_access"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="dashboard-add__form-row">
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="is_warroom">Platform <span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="is_warroom"
                        rules={{ required: "Panel Access must be filled" }}
                        defaultValue={isWaroomOptions[1]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={isWaroomOptions}
                            placeholder="-- Select Panel Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="is_warroom"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="is_active">Status<span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="is_active"
                        rules={{ required: "Filter Access must be filled" }}
                        defaultValue={statusOptions[0]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            placeholder="-- Select Filter Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="is_active"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="dashboard-add__separator-line"></div>
                  <div className="dashboard-add__form-group">
                    <label className="dashboard-add__input-label">Permission</label>
                    <div className="dashboard-add__permission">
                      <div className="dashboard-add__permission-sidebar">
                        <ul className="dashboard-add__permission-menus">
                          {permissionTabList.map((tab, index) => (
                            <li key={index} className={`dashboard-add__permission-menu ${tab === permissionTabActive ? 'active' : ''}`} onClick={() => setPermissionTabActive(tab)}>
                              {tab}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="dashboard-add__permission-form">
                        <div className="dashboard-add__permission-wrapper">
                          <div className="dashboard-add__permission-header">
                            <div className="dashboard-add__permission-title">
                              Data Access
                            </div>
                            <div className="dashboard-add__permission-title">
                              RLS
                            </div>
                          </div>
                          {/* {permissionTabActive === 'All Access' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsAllAccess.length}
                              itemData={fieldsAllAccess}
                              itemKey={(i) => fieldsAllAccess[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionAllAccess'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionAllAccess.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )} */}
                          {/* {permissionTabActive === 'MDO' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsMdo.length}
                              itemData={fieldsMdo}
                              itemKey={(i) => fieldsMdo[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionMDO'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionMDO.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )} */}
                          {permissionTabActive === 'PSM' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsPsm.length}
                              itemData={fieldsPsm}
                              itemKey={(i) => fieldsPsm[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionPSM'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionPSM.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Region' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsRegion.length}
                              itemData={fieldsRegion}
                              itemKey={(i) => fieldsRegion[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionRegion'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionRegion.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Estate' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsEstate.length}
                              itemData={fieldsEstate}
                              itemKey={(i) => fieldsEstate[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionEstate'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionEstate.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Division' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsDivision.length}
                              itemData={fieldsDivision}
                              itemKey={(i) => fieldsDivision[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionDivision'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionDivision.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Complex' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsComplex.length}
                              itemData={fieldsComplex}
                              itemKey={(i) => fieldsComplex[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionComplex'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionComplex.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-add__footer">
                  <button type="button" className="btn-base font-base" onClick={() => onClose(false)}>
                    Cancel
                  </button>
                  <button type="submit" className="btn-primary font-primary" >
                    Submit
                  </button>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default AddDashboardModal