import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { me, sessionExpired } from "../libs/redux/slices/authSlice";
import { useEffect } from "react";
import LoadingPage from "../LoadingPage";
import { setSessionExpiredHandler } from "../libs/axios/axiosCMS";
import NotFoundPage from "../NotFoundPage";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { isLoading, isUserLoggedIn, user } = useSelector(state => state.auth);
  const { mappedPaths } = useSelector(state => state.sidePanel);

  const isAuthorized = (user) => {
    const access_permission = user.cms_access.map((access) => access.menu_name);
    if (!mappedPaths[location.pathname]) return false
    return access_permission.includes(mappedPaths[location.pathname].access)
  }

  useEffect(() => {
    setSessionExpiredHandler(() => dispatch(sessionExpired()))
    if (isUserLoggedIn === null) {
      dispatch(me());
    }
  }, [dispatch]);

  if (isLoading) return <LoadingPage />

  return (
    isUserLoggedIn
      ? isAuthorized(user)
        ? <Outlet />
        : <NotFoundPage message="Forbidden Access" code="403" />
      : <Navigate to={'/login'} />
  )
}


export default PrivateRoute;