import { useMemo } from "react";
import TopBar from "../../components/TopBar"
import { useLocation, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import backArrow from "../../assets/backArrow.svg";
import { ReactComponent as DownloadIcon } from "../../assets/downloadIcon24.svg";

const GenerateActualAnswerDetail = () => {
  useDocumentTitle("Bulk Testing Details")
  const location = useLocation();
  const navigate = useNavigate();
  const data = useMemo(() => location.state?.data || {}, [location.state]);

  const prevRoutes = [
    { name: 'Bulk Testing', route: null }
  ]

  const handleBack = () => {
    navigate("/bulk-testing");
  };

  const handleDownloadFile = (url) => {
    const filename = url.split('/').pop();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  const renderStatus = (status) => {
    let badge = null;
    switch (status) {
      case "SUCCESS":
        badge = <span className="status-badge--success">Success</span>;
        break;
      case "PENDING":
        badge = <span className="status-badge--pending">Pending</span>;
        break;
      case "FAILED":
        badge = <span className="status-badge--failed">Failed</span>;
        break;
      default:
        badge = "-";
        break;
    }
    return badge;
  }
  
  return (
    <>
      <TopBar
        prevRoutes={prevRoutes}
        currentRoute={"Actual Answer Details"}
      />
      <div className="bulk-testing-detail">
        <div className="bulk-detail__header">
          <div className="group-flex-16 align-center">
            <img src={backArrow} alt="Back" className="cursor-pointer" onClick={handleBack} />
            <h5 className="bulk-detail__title">Bulk Testing Details</h5>
          </div>
          <div className="group-flex-16">
            {
              data.status === 'SUCCESS' &&
              <button
                className="btn-base font-base with-icon"
                onClick={() => handleDownloadFile(data.result_url)}
              >
                <DownloadIcon className="bulk-menu-icon" />
                Download
              </button>
            }
          </div>
        </div>
        <div className="bulk-detail__content">
          <div className="actual-detail__boxes">
            <div className="bulk-detail__box">
              <h5>ID</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.id}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Metrics Type</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.metrics_type}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Granularity Type</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.granularity_type}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Test Case Count</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.test_cases_count}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Date Created</h5>
              <div className="bulk-detail__separator"></div>
              <p>{data.created_at}</p>
            </div>
            <div className="bulk-detail__box">
              <h5>Status</h5>
              <div className="bulk-detail__separator"></div>
              <p>{renderStatus(data.status)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GenerateActualAnswerDetail;