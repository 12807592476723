import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import closeIcon from "../../assets/cancelIcon.svg";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputErrorMessage from "../../components/InputErrorMessage";
import chevronDown from "../../assets/chevronDownIcon24.svg";
import { ReactComponent as Checkmark } from "../../assets/checkIcon16.svg";
import { ReactComponent as Uncheckmark } from "../../assets/minusIcon16.svg";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon20.svg";
import { ReactComponent as ClearIcon } from "../../assets/xIcon20.svg";
import { FixedSizeList } from "react-window";

const AddRoleModal = ({ isOpen, onClose, defaultPSMAccess, defaultRegionAccess, defaultEstateAccess, defaultDivisionAccess, defaultComplexAccess, dashboardAccess, onSuccess }) => {
  const { register, control, reset, clearErrors, setError, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const btnFinalSubmitRef = useRef(null);

  const [psmList, setPsmList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [estateList, setEstateList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [complexList, setComplexList] = useState([]);

  const [selectedPSM, setSelectedPSM] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedEstate, setSelectedEstate] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [selectedComplex, setSelectedComplex] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState([]);

  const [selectedAllAccess, setSelectedAllAccess] = useState(false);
  const [selectedAllPSM, setSelectedAllPSM] = useState(false);
  const [selectedAllRegion, setSelectedAllRegion] = useState(false);
  const [selectedAllEstate, setSelectedAllEstate] = useState(false);
  const [selectAllDivision, setSelectedAllDivision] = useState(false);
  const [selectAllComplex, setSelectedAllComplex] = useState(false);

  const [selectedAllDashboard, setSelectedAllDashboard] = useState(false);

  const [searchPSM, setSearchPSM] = useState('');
  const [searchRegion, setSearchRegion] = useState('');
  const [searchEstate, setSearchEstate] = useState('');
  const [searchDashboard, setSearchDashboard] = useState('');
  const [searchDivision, setSearchDivision] = useState('');
  const [searchComplex, setSearchComplex] = useState('');

  const [roleName, setRoleName] = useState();
  const [errorMessages, setErrorMessages] = useState({

  });

  const [isActiveOptions, setIsActiveOptions] = useState([
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]);

  const [isOpenAccordian, setIsOpenAccordian] = useState('');
  const [isOpenDashboardAccordian, setIsOpenDashboardAccordian] = useState(true);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const storageDataAccessRef = useRef({});

  const getRoleAccessDetail = async (accessMasterId, groupName) => {
    try {
      const response = await axiosCMS.get('role/get-data-access-detail', {
        params: {
          access_master_id: accessMasterId,
          group: groupName,
        }
      })

      const result = response.data;
      storageDataAccessRef.current[accessMasterId] = result.data;
      return result.data;
    } catch (error) {
      console.error("Error fetching data access detail: ", error);
    }

  };

  const setAutoChecked = (groupName, result, currentSelected, setSelectedData, listData, setListData, isResetList) => {
    const groupIndex = result.findIndex((obj) => obj.group_name === groupName);
    if (currentSelected.length === 0) {
      setSelectedData(result[groupIndex].list_group);
      setListData(listData.filter((obj) => result[groupIndex].list_group.findIndex((item) => item.name === obj.name) !== -1));
    } else {
      const updatedSelectedData = [...currentSelected, ...result[groupIndex].list_group].filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      const updatedListData = isResetList ? listData : listData.filter((obj) => updatedSelectedData.findIndex((item) => item.name === obj.name) !== -1)
      setSelectedData(updatedSelectedData);
      setListData(updatedListData);
    }
  }

  const setAutoUnchecked = (groupName, result, currentSelected, setSelectedData, listData, setListData, isResetList) => {
    const groupIndex = result.findIndex((obj) => obj.group_name === groupName);
    const updatedSelectedData = currentSelected.filter((obj) => result[groupIndex].list_group.findIndex((item) => item.name === obj.name) === -1);
    const updatedListData = isResetList
      ? listData
      : currentSelected.length === 0
        ? listData
        : listData.filter((obj) => updatedSelectedData.findIndex((item) => item.name === obj.name) !== -1);

    setSelectedData(updatedSelectedData);
    setListData(updatedListData);
  }

  const handleOnChangeCheck = async (e, data, groupName, currentChecked, setChecked) => {
    const { id, name, access_master_id } = data;
    const isChecked = e.target.checked;
    if (isChecked) {
      clearErrors("data_access");
      const updatedListChecked = [...currentChecked, data]
      setChecked(updatedListChecked);
      if (groupName === 'PSM') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('REGION', result, selectedRegion, setSelectedRegion, defaultRegionAccess, setRegionList);
        setAutoChecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList);
        setAutoChecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      } else if (groupName === 'REGION') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList);
        setAutoChecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      } else if (groupName === 'ESTATE') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      } else if (groupName === 'DIVISION') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      }
    } else {
      const updatedListChecked = currentChecked.filter((obj) => obj.name !== name);
      setChecked(updatedListChecked);
      if (groupName === 'PSM') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('REGION', result, selectedRegion, setSelectedRegion, defaultRegionAccess, setRegionList, updatedListChecked.length === 0);
        setAutoUnchecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList, updatedListChecked.length === 0);
        setAutoUnchecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList, updatedListChecked.length === 0);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      } else if (groupName === 'REGION') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList, updatedListChecked.length === 0);
        setAutoUnchecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList, updatedListChecked.length === 0);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      } else if (groupName === 'ESTATE') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList, updatedListChecked.length === 0);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      } else if (groupName === 'DIVISION') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      }
    }
  }

  const handleSelectAllAccess = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedPSM(defaultPSMAccess);
      setSelectedRegion(defaultRegionAccess);
      setSelectedEstate(defaultEstateAccess);
      setSelectedDivision(defaultDivisionAccess);
      setSelectedComplex(defaultComplexAccess);

      setPsmList(defaultPSMAccess);
      setRegionList(defaultRegionAccess);
      setEstateList(defaultEstateAccess);
      setDivisionList(defaultDivisionAccess);
      setComplexList(defaultComplexAccess);

      clearErrors("data_access");
    } else {
      setSelectedPSM([]);
      setSelectedRegion([]);
      setSelectedEstate([]);
      setSelectedDivision([]);
      setSelectedComplex([]);
    }
    setSelectedAllAccess(isChecked);
    setSelectedAllPSM(isChecked);
    setSelectedAllRegion(isChecked);
    setSelectedAllEstate(isChecked);
    setSelectedAllDivision(isChecked);
    setSelectedAllComplex(isChecked);
  }

  const handleSelectAllSub = (e, targetSelected, listSelected, setChecked) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      targetSelected(listSelected);
      clearErrors("data_access");
    } else {
      targetSelected([]);
    }
    setChecked(isChecked);
  }

  const handleChangeDashboard = (e, data) => {
    if (e.target.checked) {
      setSelectedDashboard([...selectedDashboard, data]);
      clearErrors("dashboard_type");
    } else {
      setSelectedDashboard(selectedDashboard.filter((obj) => obj.id !== data.id));
    }
  }

  const handleSelectAllDashboard = (e) => {
    if (e.target.checked) {
      setSelectedDashboard(dashboardAccess);
      setSelectedAllDashboard(true);
      clearErrors("dashboard_type");
    } else {
      setSelectedDashboard([]);
      setSelectedAllDashboard(false);
    }
  }

  const postAddRole = async (data) => {
    try {
      const response = await axiosCMS.post('role/insert-role', data);
      if (response.status === 200) {
        dispatch(addToast({
          type: 'success',
          title: 'New data was successfully added'
        }))
        reset();
        setSelectedPSM([]);
        setSelectedRegion([]);
        setSelectedEstate([]);
        setSelectedDivision([]);
        setSelectedComplex([]);

        setSelectedAllAccess(false);
        setSelectedAllPSM(false);
        setSelectedAllRegion(false);
        setSelectedAllEstate(false);
        setSelectedAllDivision(false);
        setSelectedAllComplex(false);

        setSelectedDashboard([]);
        setSelectedAllDashboard(false);

        setPsmList(defaultPSMAccess);
        setRegionList(defaultRegionAccess);
        setEstateList(defaultEstateAccess);
        setDivisionList(defaultDivisionAccess);
        setComplexList(defaultComplexAccess);

        onClose(false);
        setIsConfirmationOpen(false);
        onSuccess();
      }
    } catch (error) {
      console.error("Error adding role: ", error);
      dispatch(addToast({
        type: 'error',
        title: 'Error submitting role'
      }))
    }
  }

  const handleConfirmation = (action = null) => {
    let isValid = true;
    let message = '';
    if (selectedPSM.length === 0 && selectedRegion.length === 0 && selectedEstate.length === 0 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Data access must be filled";
      isValid = false;
    } else if (selectedPSM.length >= 1 && selectedRegion.length === 0 && selectedEstate.length === 0 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Region, Estate, Division and Complex must be filled";
      isValid = false;
    } else if (selectedRegion.length >= 1 && selectedEstate.length === 0 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Estate, Division and Complex must be filled";
      isValid = false;
    } else if (selectedEstate.length >= 1 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Division and Complex must be filled";
      isValid = false;
    } else if (selectedDivision.length >= 1 && selectedComplex.length === 0) {
      message = "Complex must be filled";
      isValid = false;
    }

    if (!isValid) {
      setError("data_access", {
        types: {
          required: message,
        }
      });
      setIsOpenAccordian('');
      return;
    }

    if (selectedDashboard.length === 0) {
      setError("dashboard_type", {
        types: {
          required: "Dashboard type must be filled",
        }
      });
      return;
    }

    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = (form) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    const psm = selectedPSM.map((obj) => obj.id);
    const region = selectedRegion.map((obj) => obj.id);
    const estate = selectedEstate.map((obj) => obj.id);
    const division = selectedDivision.map((obj) => obj.id);
    const complex = selectedComplex.map((obj) => obj.id);
    const dashboardType = selectedDashboard.map((obj) => obj.id);
    const data = {
      role_name: form.name,
      data_access: [...psm, ...region, ...estate, ...division, ...complex],
      dashboard_type: dashboardType,
      status: form.is_active.value
    }
    postAddRole(data)
      .finally(() => btnFinalSubmitRef.current.removeAttribute("disabled"));
  }

  const handleAccordian = (type) => {
    setIsOpenAccordian(isOpenAccordian === type ? '' : type);
  }

  useEffect(() => {
    setPsmList(defaultPSMAccess);
    setRegionList(defaultRegionAccess);
    setEstateList(defaultEstateAccess);
    setDivisionList(defaultDivisionAccess);
    setComplexList(defaultComplexAccess);
  }, [defaultEstateAccess, defaultPSMAccess, defaultRegionAccess, defaultDivisionAccess, defaultComplexAccess]);

  const accordianList = [
    {
      dataName: 'PSM',
      selectedData: selectedPSM,
      selectedDataLength: selectedPSM.length,
      setSelectedData: setSelectedPSM,
      dataList: psmList,
      dataListLength: psmList.length,
      selectedAllData: selectedAllPSM,
      setSelectedAllData: setSelectedAllPSM,
      searchData: searchPSM,
      setSearchData: setSearchPSM,
    },
    {
      dataName: 'REGION',
      selectedData: selectedRegion,
      selectedDataLength: selectedRegion.length,
      setSelectedData: setSelectedRegion,
      dataList: regionList,
      dataListLength: regionList.length,
      selectedAllData: selectedAllRegion,
      setSelectedAllData: setSelectedAllRegion,
      searchData: searchRegion,
      setSearchData: setSearchRegion,
    },
    {
      dataName: 'ESTATE',
      selectedData: selectedEstate,
      selectedDataLength: selectedEstate.length,
      setSelectedData: setSelectedEstate,
      dataList: estateList,
      dataListLength: estateList.length,
      selectedAllData: selectedAllEstate,
      setSelectedAllData: setSelectedAllEstate,
      searchData: searchEstate,
      setSearchData: setSearchEstate,
    },
    {
      dataName: 'DIVISION',
      selectedData: selectedDivision,
      selectedDataLength: selectedDivision.length,
      setSelectedData: setSelectedDivision,
      dataList: divisionList,
      dataListLength: divisionList.length,
      selectedAllData: selectAllDivision,
      setSelectedAllData: setSelectedAllDivision,
      searchData: searchDivision,
      setSearchData: setSearchDivision,
    },
    {
      dataName: 'COMPLEX',
      selectedData: selectedComplex,
      selectedDataLength: selectedComplex.length,
      setSelectedData: setSelectedComplex,
      dataList: complexList,
      dataListLength: complexList.length,
      selectedAllData: selectAllComplex,
      setSelectedAllData: setSelectedAllComplex,
      searchData: searchComplex,
      setSearchData: setSearchComplex,
    }
  ]

  if (!isOpen) return null;
  return (
    <div className="role-add__overlay">
      <div className="role-add__modal">
        <div className="role-add__header">
          <h2 className="role-add__title">Add Role</h2>
          <button className="role-add__close" onClick={() => {
            handleConfirmation(false)
            onClose(false)
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="role-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="role-add__content">
                  <h3 className="role-add__confirmation-title">Do you really wish to submit new role?</h3>
                  <p className="role-add__confirmation-desc">Once you submit, the new role will be added to the system.</p>
                  <div className="role-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button type="submit" ref={btnFinalSubmitRef} className={`btn-primary font-primary`} disabled={isLoading}>Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="role-add__content">
                  <div className="role-add__form-group">
                    <label className="role-add__input-label" htmlFor="name">Role Name <span className="role-add__input-required">*</span></label>
                    <input type="text" className="role-add__input-box"
                      {...register("name", {
                        required: "Role name must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label className="role-add__input-label">Data Access</label>
                    <div className="role-add__accordian">
                      <div className="role-add__accordian-header">
                        <label className='role-add__form-checkbox'>
                          <input type='checkbox'
                            onChange={handleSelectAllAccess}
                            checked={selectedAllAccess}
                          />
                          <div className='role-add__checkmark'>
                            <Checkmark className="role-add__checkmark-icon" />
                          </div>
                          ALL ACCESS
                        </label>
                      </div>
                      <div className="role-add__accordian-separator">
                      </div>
                      {accordianList.map((accordian, index) => (
                        <AccordianCheckbox
                          key={index}
                          dataList={accordian.dataList}
                          dataName={accordian.dataName}
                          selectedData={accordian.selectedData}
                          dataListLength={accordian.dataListLength}
                          setSelectedData={accordian.setSelectedData}
                          selectedAllData={accordian.selectedAllData}
                          setSelectedAllData={accordian.setSelectedAllData}
                          searchData={accordian.searchData}
                          setSearchData={accordian.setSearchData}
                          handleAccordian={handleAccordian}
                          isOpenAccordian={isOpenAccordian}
                          handleOnChangeCheck={handleOnChangeCheck}
                          handleSelectAllSub={handleSelectAllSub}
                          selectedDataLength={accordian.selectedDataLength}
                        />
                      ))}
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="data_access"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label className="role-add__input-label" htmlFor="name">Dashboard Type</label>
                    <div className="role-add__accordian">
                      <div className="role-add__accordian-header">
                        <label className='role-add__form-checkbox'>
                          <input type='checkbox'
                            onChange={handleSelectAllDashboard}
                            checked={selectedAllDashboard}
                          />
                          <div className={`role-add__checkmark ${selectedDashboard.length >= 1 && !selectedAllDashboard ? 'show' : ''}`}>
                            {
                              selectedDashboard.length >= 1 && !selectedAllDashboard
                                ? <Uncheckmark className="role-add__checkmark-icon show" />
                                : <Checkmark className="role-add__checkmark-icon" />
                            }
                          </div>
                          ALL
                        </label>
                        <img src={chevronDown} onClick={() => setIsOpenDashboardAccordian(!isOpenDashboardAccordian)} alt="chevron-down" className={`role-add__accordian-icon cursor-pointer ${isOpenDashboardAccordian ? 'rotate' : ''}`} />
                      </div>
                      <div className={`role-add__accordian-wrapper ${isOpenDashboardAccordian ? 'show' : ''}`}>
                        <div className={`role-add__accordian-content `}>
                          <div className="role-add__accordian-search-wrapper">
                            <input type="text" placeholder="Search by Role Name" className="role-add__accordian-search" value={searchDashboard} onChange={(e) => setSearchDashboard(e.target.value)} />
                            {searchDashboard
                              ? <ClearIcon className="role-add__accordian-search-icon" onClick={() => setSearchDashboard('')} />
                              : <SearchIcon className="role-add__accordian-search-icon" />
                            }
                          </div>
                          <ul className="role-add__accordian-list">
                            {searchDashboard
                              ? dashboardAccess.filter((obj) => obj.dashboard_name.toLowerCase().includes(searchDashboard.toLowerCase())).map((obj) => (
                                <li key={obj.id}>
                                  <label className='role-add__form-checkbox'>
                                    <input type='checkbox'
                                      onChange={(e) => handleChangeDashboard(e, obj)}
                                      checked={selectedDashboard.some(item => item.id === obj.id)}
                                    />
                                    <span className='role-add__checkmark'>
                                      <Checkmark className="role-add__checkmark-icon" />
                                    </span>
                                    {obj.dashboard_name}
                                  </label>
                                </li>
                              ))
                              : dashboardAccess.map((obj) => (
                                <li key={obj.id}>
                                  <label className='role-add__form-checkbox'>
                                    <input type='checkbox'
                                      onChange={(e) => handleChangeDashboard(e, obj)}
                                      checked={selectedDashboard.some(item => item.id === obj.id)}
                                    />
                                    <span className='role-add__checkmark'>
                                      <Checkmark className="role-add__checkmark-icon" />
                                    </span>
                                    {obj.dashboard_name}
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="dashboard_type"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label htmlFor="is_active" className="role-add__input-label">Status</label>
                    <Controller
                      control={control}
                      name="is_active"
                      rules={{ required: "Status must be filled" }}
                      defaultValue={isActiveOptions[0]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={isActiveOptions}
                          placeholder="-- Select Status --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="is_active"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => onClose(false)}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

const AccordianCheckbox = ({
  key,
  dataName,
  selectedData,
  selectedDataLength,
  setSelectedData,
  dataList,
  dataListLength,
  selectedAllData,
  setSelectedAllData,
  handleAccordian,
  isOpenAccordian,
  searchData,
  setSearchData,
  handleSelectAllSub,
  handleOnChangeCheck
}) => {
  return (
    <>
      <div key={key} className="role-add__accordian-separator">
        <div className="role-add__accordian-header">
          <label className='role-add__form-checkbox'>
            <input type='checkbox'
              onChange={(e) => handleSelectAllSub(e, setSelectedData, dataList, setSelectedAllData)}
              checked={selectedAllData}
            />
            <div className={`role-add__checkmark ${selectedDataLength >= 1 && !selectedAllData ? 'show' : ''}`}>
              {
                selectedDataLength >= 1 && !selectedAllData
                  ? <Uncheckmark className="role-add__checkmark-icon show" />
                  : <Checkmark className="role-add__checkmark-icon" />
              }
            </div>
            {dataName}
          </label>
          <img src={chevronDown} onClick={() => handleAccordian(dataName)} alt="chevron-down" className={`role-add__accordian-icon cursor-pointer ${isOpenAccordian === dataName ? 'rotate' : ''}`} />
        </div>
        <div className={`role-add__accordian-wrapper ${isOpenAccordian === dataName ? 'show' : ''}`}>
          <div className="role-add__accordian-content">
            <div className="role-add__accordian-search-wrapper">
              <input
                type="text"
                placeholder="Search"
                className="role-add__accordian-search"
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
              />
              {searchData
                ? <ClearIcon className="role-add__accordian-search-icon" onClick={() => setSearchData('')} />
                : <SearchIcon className="role-add__accordian-search-icon" />
              }
            </div>
            <ul className="role-add__accordian-list">
              <FixedSizeList
                height={400}
                itemCount={searchData ? dataList.filter((obj) => obj.name.toLowerCase().includes(searchData.toLowerCase())).length : dataList.length}
                itemSize={40}
                itemData={searchData ? dataList.filter((obj) => obj.name.toLowerCase().includes(searchData.toLowerCase())) : dataList}
                itemKey={(index, data) => data[index]?.id || index}
              >
                {({ index, style, data }) => {
                  const item = data[index];
                  if (!item) return "No Item";
                  return (
                    <li key={item.id} style={style}>
                      <label className='role-add__form-checkbox'>
                        <input type='checkbox'
                          onChange={(e) => handleOnChangeCheck(e, item, dataName, selectedData, setSelectedData)}
                          checked={selectedData.some(obj => obj.id === item.id)}
                        />
                        <span className='role-add__checkmark'>
                          <Checkmark className="role-add__checkmark-icon" />
                        </span>
                        {item.name}
                      </label>
                    </li>
                  )
                }}
              </FixedSizeList>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddRoleModal;