import { roleef, useState } from "react";
import OutsideHandler from "../../components/OutsideHandler";
import Select from "react-select";

import closeIcon from "../../assets/cancelIcon.svg";

const FilterModal = ({ isOpen, onApply, onFilterModal }) => {
  const [panelAccessOptions, setPanelAccessOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ]);

  const [filterAccessOptions, setFilterAccessOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ]);

  const [isWarroomOptions, setIsWarroomOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Warroom", value: "warroom" },
    { label: "Desktop", value: "desktop" },
  ]);

  const [isActiveOptions, setIsActiveOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ]);

  const [filters, setFilters] = useState({
    dashboard_type: { label: "ALL", value: "" },
    is_panel: { label: "ALL", value: "" },
    is_filter: { label: "ALL", value: "" },
    status: { label: "ALL", value: "" },
    is_warroom: { label: "ALL", value: "" },
  })

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  }

  const handleClear = () => {
    onApply({
      report_section: "",
      filter_panel: "",
      status: "",
      is_warroom: "",
    });
    setFilters({
      is_panel: { label: "ALL", value: "" },
      is_filter: { label: "ALL", value: "" },
      status: { label: "ALL", value: "" },
      is_warroom: { label: "ALL", value: "" },
    })
    onFilterModal(false);
  }

  const handleApply = () => {
    onApply({
      report_section: filters.is_panel.value,
      filter_panel: filters.is_filter.value,
      status: filters.status.value,
      is_warroom: filters.is_warroom.value,
    });
    onFilterModal(false);
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onFilterModal(false)}>
      <div className="dashboard-filter__wrapper">
        <div className="dashboard-filter__header">
          <h2 className="dashboard-filter__title">Filter</h2>
          <button className="dashboard-filter__close" onClick={() => onFilterModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="dashboard-filter__separator" />
        <div className="dashboard-filter__content">
          <div className="dashboard-filter__form-row">
            {/* <div className="dashboard-filter__form-group">
              <label htmlFor="dashboard_name" className="dashboard-filter__input-label">Dashboard Name</label>
              <Select
                id="dashboard_name"
                name="dashboard_name"
                options={roleNameOptions}
                value={filters.role}
                onChange={handleInputChange}
                placeholder="-- Select Dashboard Name --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
              />
            </div> */}
            <div className="dashboard-filter__form-group">
              <label htmlFor="is_panel" className="dashboard-filter__input-label">Report Section</label>
              <Select
                id="is_panel"
                name="is_panel"
                options={panelAccessOptions}
                value={filters.is_panel}
                onChange={handleInputChange}
                placeholder={"-- Select Panel Type --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
            <div className="dashboard-filter__form-group">
              <label htmlFor="is_filter" className="dashboard-filter__input-label">Filter Panel</label>
              <Select
                id="is_filter"
                name="is_filter"
                options={filterAccessOptions}
                value={filters.is_filter}
                onChange={handleInputChange}
                placeholder={"-- Select Filter Type --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
          </div>
          <div className="dashboard-filter__form-row">
            <div className="dashboard-filter__form-group">
              <label htmlFor="is_warroom" className="dashboard-filter__input-label">Platform</label>
              <Select
                id="is_warroom"
                name="is_warroom"
                options={isWarroomOptions}
                value={filters.is_warroom}
                onChange={handleInputChange}
                placeholder={"-- Select Filter Type --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
            <div className="dashboard-filter__form-group">
              <label htmlFor="status" className="dashboard-filter__input-label">Status</label>
              <Select
                id="status"
                name="status"
                options={isActiveOptions}
                value={filters.status}
                onChange={handleInputChange}
                placeholder={"-- Select Filter Type --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
          </div>
        </div>
        <div className="dashboard-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;