import sinarmasLogo from "./assets/sinarmas.png"

const NotFoundPage = ({ message = "Page not found", code = "404" }) => {
    return (
            <div className="not-found-container">
                <img src={sinarmasLogo} style={{ height: "60px" }} />
                <div>{message} | {code}</div>
            </div>
    )
}

export default NotFoundPage