import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EditModal from "./EditModal";
import { Alert } from "../../components";

import backArrow from "../../assets/backArrow.svg";
import { format } from "date-fns";
import axiosCMS from "../../libs/axios/axiosCMS";

const MetricDetails = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dataMetric, setDataMetric] = useState({});

  const metricId = location.state?.id || "";

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleOpenModal = () => {
    setIsEditModalOpen(true);
  };

  const getDataMetric = useCallback((params) => {
    // API call to get metric details
    axiosCMS({
      method: "post",
      url: `prompt_metrics/get/detail/`,
      params: params,
    }).then(
      (response) => {
        const {
          data: {
            code,
            data: { metrics: dataMetric },
            detail,
          },
        } = response;

        if (code !== 200) {
          showAlert({
            type: "error",
            title: code || "Error",
            message: detail || "Error fetching data",
          });
          return;
        }

        setDataMetric(dataMetric ? dataMetric : {});
      },
      (error) => {
        const { message, response: { data: { detail } = {} } = {} } = error;

        showAlert({
          type: "error",
          title: message || "Error",
          message: detail || "Error fetching data",
        });
      }
    );
  }, []);

  useEffect(() => {
    getDataMetric({ id: metricId });
  }, [metricId, getDataMetric]);

  return (
    <>
      <Alert ref={alertRef} />

      <div className="metric-details">
        <div className="metric-details-header">
          <img
            src={backArrow}
            className="back-button"
            alt="return button"
            onClick={() => navigate(-1)}
          />
          <h2 className="metric-details-title">Metric Details</h2>
          <button className="metric-edit-button" onClick={handleOpenModal}>
            Edit Metric
          </button>
        </div>
        <div className="metric-details-container">
          <div className="metric-details-metric">
            <p className="metric-details-metric-name">Metric Name:</p>
            <hr className="metrics-detail-separator" />
            <p className="metrics-detail-description">
              {dataMetric.metrics_name}
            </p>
          </div>
          <div className="metric-details-metric">
            <p className="metric-details-metric-name">Unit:</p>
            <hr className="metrics-detail-separator" />
            <p className="metrics-detail-description">{dataMetric.unit ?? '-'}</p>
          </div>
          <div className="metric-details-metric">
            <p className="metric-details-metric-name">Location Granularity:</p>
            <hr className="metrics-detail-separator" />
            <p className="metrics-detail-description">
              {dataMetric.location_granuality ?? '-'}
            </p>
          </div>
          <div className="metric-details-metric">
            <p className="metric-details-metric-name">Period Granularity:</p>
            <hr className="metrics-detail-separator" />
            <p className="metrics-detail-description">
              {dataMetric.period_granuality ?? '-'}
            </p>
          </div>
          <div className="metric-details-metric">
            <p className="metric-details-metric-name">Dashboard Type:</p>
            <hr className="metrics-detail-separator" />
            <p className="metrics-detail-description">
              {dataMetric.dashboard_type ?? '-'}
            </p>
          </div>
          <div className="metric-details-metric">
            <p className="metric-details-metric-name">Last Updated:</p>
            <hr className="metrics-detail-separator" />
            <p className="metrics-detail-description">
              {dataMetric.updated_at ? format(
                new Date(dataMetric.updated_at),
                "yyyy-MM-dd hh:mm:ss.SSX"
              ) : "-"}
            </p>
          </div>
          <div className="metric-details-metric description">
            <p className="metric-details-metric-name">Description:</p>
            <hr className="metrics-detail-separator" />
            <p className="metrics-detail-description">
              {dataMetric.description ?? '-'}
            </p>
          </div>
        </div>

        <EditModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSuccess={() => {
            setIsEditModalOpen(false);
            getDataMetric({ id: metricId });
          }}
          formData={dataMetric}
        />
      </div>
    </>
  );
};

export default MetricDetails;
