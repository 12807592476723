import closeIcon from "../../assets/cancelIcon.svg";
import MarkDown from "react-markdown";
import remarkGfm from 'remark-gfm'

const BotAnswerModal = ({ botAnswer, onClose }) => {
  if (botAnswer === null) return null;
  return (
    <div className="bot-ans__overlay">
      <div className="bot-ans__modal">
        <div className="bot-ans__header">
          <h2 className="bot-ans__header-title">Bot Answer</h2>
          <button className="bot-ans__close" onClick={onClose}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="bot-ans__separator"></div>
        <div className="bot-ans__content">
          <div className="bot-ans__markdown-style">
            <MarkDown remarkPlugins={remarkGfm}>{botAnswer.text}</MarkDown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BotAnswerModal;