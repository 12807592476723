import { useCallback, useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import OutsideHandler from "../../components/OutsideHandler";
import "./MenuManagement.css";
import Pagination from "../../components/Pagination";
import FilterModal from "./FilterModal";
import LoadingComponent from "../../components/Loading";
import EditMenuModal from "./EditMenuModal";
import DeleteMenuModal from "./DeleteMenuModal";
import AddMenuModal from "./AddMenuModal";

import searchIcon from "../../assets/searchIcon20.svg";
import clearIcon from "../../assets/xIcon20.svg";
import plusIcon from "../../assets/plusIconWhite20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";
import kebabMenuIcon from "../../assets/kebabMenu.svg";
import { ReactComponent as TrashIcon } from "../../assets/trashIcon16.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import debounce from "../../utils/Debounce";
import LoadingTable from "../../components/LoadingTable";
import { convertFormatedDate } from "../../utils/FunctionHelpers";

const PAGE_SIZE = 10;
const DEFAULT_SORT = "desc";
const DEFAULT_ORDER_BY = "updated_at";

const MenuManagement = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddMenuModalOpen, setIsAddMenuModalOpen] = useState(false);
  const [isEditMenuModalOpen, setIsEditMenuModalOpen] = useState(null);
  const [isDeleteMenuModalOpen, setIsDeleteMenuModalOpen] = useState(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [privileges, setPrivileges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [menuListFilter, setMenuListFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSort, setSelectedSort] = useState({ order_by: DEFAULT_ORDER_BY, sort: DEFAULT_SORT });
  const [selectedFilters, setSelectedFilters] = useState({
    search: "",
    status: "",
    permission: [],
    page: 1,
    size: PAGE_SIZE,
    sort: DEFAULT_SORT,
    order_by: DEFAULT_ORDER_BY,
  });

  useDocumentTitle('CMS Permission')

  const prevRoutes = [
    { name: 'User & Role Management', route: null }
  ]

  const handleActiveMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  }

  const handleFilterModal = (action = null) => {
    if (action === null) {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else {
      setIsFilterModalOpen(action);
    }
  }

  const handleAddMenuModal = (action = null) => {
    if (action === null) {
      setIsAddMenuModalOpen(!isAddMenuModalOpen);
    } else {
      setIsAddMenuModalOpen(action);
    }
  }

  const handleFilterApply = (filters) => {
    // Apply filters
    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...filters }));
  }

  const handlePageChange = (action) => {
    setCurrentPage(action);
  }

  const getMenuList = useCallback(debounce(async () => {
    const URL = "cms/get-menu-list/";
    try {
      const response = await axiosCMS.get(URL);
      if (response.status === 200) {
        const result = response.data;
        const formatedDataFilter = result.data.map(menu => ({ label: menu.menu_name, value: menu.menu_name }));
        const formatedData = result.data.map(menu => ({ label: menu.menu_name, value: menu.id }));
        setMenuListFilter(formatedDataFilter);
        setMenuList(formatedData);
      }
    } catch (error) {
      console.error('Failed to fetch menu list', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch menu list',
      }))
    }
  }), [])

  useEffect(() => {
    getMenuList();
  }, [])

  const getPermissions = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    const URL = "cms/get/"
    try {
      const response = await axiosCMS.post(URL, filters);
      if (response.status === 200) {
        const result = response.data;
        setPrivileges(result.data.result);
        setTotalPages(result.data.total_page);
      }
    } catch (error) {
      console.error('Failed to fetch permissions data', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch permissions data',
      }))
    }
    setIsLoading(false);
  }, 1000), []);

  useEffect(() => {
    getPermissions({
      ...selectedFilters,
      page: currentPage,
      sort: selectedSort.sort,
      order_by: selectedSort.order_by
    });
  }, [selectedFilters, currentPage, selectedSort])

  const handleChangeSearch = debounce((filters, search) => {
    const newFilters = { ...filters, search: search };
    setSelectedFilters(newFilters);
  }, 300);

  useEffect(() => {
    handleChangeSearch(selectedFilters, search);
  }, [search]);

  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'CMS Permission'} />
      <div className="privilege-mg">
        <div className="privilege-mg__header-container">
          <h1 className="privilege-mg__header-title">CMS Permission</h1>
          <div className="privilege-mg__action-container">
            <div style={{ position: 'relative' }}>
              <input type="text" placeholder="Search by Permission Group" className="privilege-search" value={search} onChange={(e) => setSearch(e.target.value)} />
              {search
                ? <img src={clearIcon} alt="search icon" className="privilege-search__icon" onClick={() => setSearch('')} />
                : <img src={searchIcon} alt="search icon" className="privilege-search__icon" />
              }
            </div>
            <div className="privilege-filter">
              <button onClick={handleFilterModal} className="btn-base with-icon font-base">
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className="filter-button-icon"
                />
                Filter
              </button>
              <FilterModal
                menuOptions={menuListFilter}
                isOpen={isFilterModalOpen}
                onFilterModal={handleFilterModal}
                onApply={handleFilterApply}
              />
            </div>
            <button onClick={handleAddMenuModal} className="btn-primary with-icon font-primary" >
              <img
                src={plusIcon}
                alt="filter icon"
                className="filter-button-icon"
              />
              Add Permission
            </button>
            <AddMenuModal
              menuOptions={menuList}
              isOpen={isAddMenuModalOpen}
              onClose={handleAddMenuModal}
              onSuccess={() => getPermissions({
                ...selectedFilters,
                page: currentPage,
                sort: selectedSort.sort,
                order_by: selectedSort.order_by
              })}
            />
          </div>
        </div>
        <div className="privilege-table">
          <div className="privilege-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th>Permission Group</th>
                  <th>Permission</th>
                  <th>Status</th>
                  <th>
                    <div className="group-flex-8 align-center">
                      <span>Last Updated</span>
                      <img
                        src={
                          selectedSort.order_by === "updated_at"
                            ? selectedSort.sort === "asc"
                              ? sortIconAsc
                              : sortIconDesc
                            : sortIcon
                        }
                        className="sort-icon"
                        alt="Sort icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedSort({
                            order_by: "updated_at",
                            sort:
                              selectedSort.order_by === "updated_at"
                                ? selectedSort.sort === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc",
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th style={{ width: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ?
                    <LoadingTable columnSize={5} rowSize={4} />
                    : (
                      privileges.length > 0 ? privileges.map(privilege => (
                        <tr key={privilege.id}>
                          <td>{privilege.permission_group_name}</td>
                          <td>{privilege.access.length > 0 ? privilege.access.map((x => x.menu_name)).join(', ') : "-"}</td>
                          <td>
                            {privilege.status === 1
                              ? <span className="privilege-badge--active">Active</span>
                              : <span className="privilege-badge--inactive">Inactive</span>
                            }
                          </td>
                          <td>{privilege.updated_at && convertFormatedDate(privilege.updated_at)}</td>
                          <td>
                            <div className="kebab-menu-container">
                              <img
                                src={kebabMenuIcon}
                                alt="kebab menu"
                                onClick={() => handleActiveMenu(privilege.id)}
                                style={{ cursor: "pointer" }}
                              />
                              {activeMenuIndex === privilege.id && (
                                <OutsideHandler handleClose={handleActiveMenu}>
                                  <div className="privilege-menu">
                                    <div
                                      className="privilege-menu-option"
                                      onClick={() => setIsEditMenuModalOpen(privilege)}
                                    >
                                      <EditIcon className="privilege-menu-icon" />
                                      Edit
                                    </div>
                                    {/* <div
                                      className="privilege-menu-option"
                                      onClick={() => setIsDeleteMenuModalOpen(privilege)}
                                    >
                                      <TrashIcon className="privilege-menu-icon" />
                                      Delete
                                    </div> */}
                                  </div>
                                </OutsideHandler>
                              )}
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={6} className="text-center">No data available</td>
                        </tr>
                      )
                    )
                }
              </tbody>
            </table>
          </div>
          <Pagination 
          currentPage={currentPage} 
          handlePageChange={handlePageChange} 
          totalPages={totalPages} 
          />
        </div>
        <EditMenuModal 
        menuOptions={menuList} 
        permissionGroup={isEditMenuModalOpen} 
        onClose={() => setIsEditMenuModalOpen(null)} 
        onSuccess={() => getPermissions({
          ...selectedFilters,
          page: currentPage,
          sort: selectedSort.sort,
          order_by: selectedSort.order_by
        })}
        />
        {/* <DeleteMenuModal menu={isDeleteMenuModalOpen} onClose={() => setIsDeleteMenuModalOpen(null)} /> */}
      </div>
    </>
  )
}

export default MenuManagement;