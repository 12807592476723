import React, { useEffect, useRef, useState } from "react";
import { Alert } from "../../components";
import axiosCMS from "../../libs/axios/axiosCMS";

const EditSpecificProp = ({ isOpen, onClose, onSuccess, formData }) => {
  const [formSpecificProp, setFormSpecificProp] = useState({});

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleFormChange = (name, value) => {
    setFormSpecificProp((prev) => ({ ...prev, [name]: value }));
  };

  const updateDataSpecificProp = (data) => {
    axiosCMS({
      method: "post",
      url: `prompt_properties/update`,
      params: data,
    }).then(
      (response) => {
        const {
          data: { code, message, detail },
        } = response;

        if (code === 200) {
          showAlert({
            type: "success",
            message: message,
          });
          onSuccess();
        }
      },
    ).catch((error) => {
      const { message, response: { data: { detail } = {} } = {} } = error;
      showAlert({
        type: "error",
        title: message || "Error",
        message: detail || "Error updating data",
      });
    });
  };

  useEffect(() => {
    if (isOpen) {
      setFormSpecificProp(formData);
    } else {
      // Reset form data when modal is closed
      setFormSpecificProp({});
    }
  }, [isOpen, formData]);

  return (
    <>
      <Alert ref={alertRef} />

      <div className={`edit-modal ${isOpen ? "open" : ""}`}>
        <div className="edit-modal-content">
          <h2 className="edit-title">Edit Properties</h2>
          <hr className="edit-separator" />
          <div className="edit-section">
            <h3 className="edit-subtitle">Specific Properties</h3>
            <div className="related-functions">
              <h4>Related Function</h4>
              <div className="function-boxes">
                {formSpecificProp.relatedFunctions?.map((func, index) => (
                  <div key={index} className="function-box">
                    {func}
                  </div>
                ))}
              </div>
            </div>
            <div className="immutable-fields">
              <div className="field-group">
                <label>Properties Name</label>
                <input type="text" value={formSpecificProp.name} readOnly />
              </div>
              <div className="field-group">
                <label>Properties Type</label>
                <input type="text" value={formSpecificProp.type} readOnly />
              </div>
              <div className="field-group">
                <label>Mandatory Properties</label>
                <input
                  type="text"
                  value={formSpecificProp.isRequired ? "Yes" : "No"}
                  readOnly
                />
              </div>
            </div>
            <div className="editable-field">
              <label>Properties Description</label>
              <textarea
                onChange={(e) =>
                  handleFormChange("description", e.target.value)
                }
                rows={5}
                value={formSpecificProp.description}
              />
            </div>
          </div>
          <div className="edit-buttons">
            <button className="edit-button cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button
              className="edit-button submit-button"
              onClick={() =>
                updateDataSpecificProp({
                  id: formSpecificProp.id,
                  description: formSpecificProp.description,
                })
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSpecificProp;
