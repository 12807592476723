
import TopFunctionChart from "./TopTriFunctionChart";
import TopMetricsChart from "./TopTriMetricsChart";
import TopQueries from "./TopQueries";
import TotalInteractionChart from "./TotalInteractionChart";
import UserOverview from "./UserOverview";
import TopFunctionByQueryChart from "./TopFunctionByQueryChart";
import calendarIcon from '../../assets/baseCalendarIcon20.svg';

import "react-datepicker/dist/react-datepicker.css";
import "./UserEngagement.css";
import { useCallback, useEffect, useRef, useState } from "react";
import FilterModal from "./FilterModal";
import DateModal from "./DateModal";
import TopBar from "../../components/TopBar";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import debounce from "../../utils/Debounce";
import { useSelector } from "react-redux";
import TopMetricByQueryChart from "./TopMetricByQueryChart";
import TotalInteractionByRoleChart from "./TotalInteractionByRoleChart";



const UserEngagement = () => {
  useDocumentTitle("User Engagement");
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    year: "",
    psm: "",
    estate: "",
    month: "",
    metrics: "",
    region: "",
    role: "",
    dashboard_type: "",
  });

  const lineChartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState("100%");

  const isSidePanelOpen = useSelector((state) => state.sidePanel.isSidePanelOpen);

  const months = [
    { title: "January", altTitle: 'Jan', value: 1 },
    { title: "February", altTitle: 'Feb', value: 2 },
    { title: "March", altTitle: 'Mar', value: 3 },
    { title: "April", altTitle: 'Apr', value: 4 },
    { title: "May", altTitle: 'May', value: 5 },
    { title: "June", altTitle: 'Jun', value: 6 },
    { title: "July", altTitle: 'Jul', value: 7 },
    { title: "August", altTitle: 'Aug', value: 8 },
    { title: "September", altTitle: 'Sep', value: 9 },
    { title: "October", altTitle: 'Oct', value: 10 },
    { title: "November", altTitle: 'Nov', value: 11 },
    { title: "December", altTitle: 'Dec', value: 12 }
  ];

  const currentMonth = months[new Date().getMonth()];
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState([currentMonth]);
  const [selectedYear, setSelectedYear] = useState([currentYear]);
  const [listLabelChooseDate, setListLabelChooseDate] = useState("");
  const [listLabelChooseDateCount, setListLabelChooseDateCount] = useState(0);
  const [topTriFuncChartRole, setTopTriFuncChartRole] = useState({label:"All Access", value:""});
  const [topTriMetricsChartRole, setTopTriMetricsChartRole] = useState({label:"All Access", value:""});
  const [totalInteractionByRole, setTotalInteractionByRole] = useState({label:"All Access", value:""});
  const [indexChart, setIndexChart] = useState(null);

  const handleDateModal = (action = null) => {
    if (action === null) {
      setIsDateModalOpen(!isDateModalOpen)
    } else {
      setIsDateModalOpen(action)
    }
  }

  const getListLabelChooseDate = (selectedMonth, selectedYear) => {
    const listLabel = []
    for (let i = 0; i < selectedYear.length; i++) {
      for (let j = 0; j < selectedMonth.length; j++) {
        listLabel.push(`${selectedMonth[j].altTitle} ${selectedYear[i]}`);
      }
    }
    setListLabelChooseDate(listLabel.slice(0, 3).join(', '));
  }

  const getListLabelChooseDateCount = (selectedMonth, selectedYear) => {
    const listLabelCount = selectedMonth.length * selectedYear.length;
    if (listLabelCount > 5) {
      setListLabelChooseDateCount(listLabelCount - 4);
    } else {
      setListLabelChooseDateCount(0);
    }
  }

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      date_months: selectedMonth.map((data) => data.value).sort((a, b) => a - b),
      date_years: selectedYear.sort((a, b) => a - b)
    }));
    getListLabelChooseDate(selectedMonth, selectedYear);
    getListLabelChooseDateCount(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear])

  const handleFiltersApply = (filters) => {
    setSelectedFilters((prev) => ({ ...prev, ...filters }));
  }

  const handleResize = useCallback(debounce(() => {
    if (!lineChartRef.current) return;
    const oldWidth = lineChartRef.current.offsetWidth;
    if (oldWidth > 800) {
      const newWidth = oldWidth - 500;
      setChartWidth(`${newWidth}px`);
    } else {
      setChartWidth('100%');
    }
  }, 300), [])

  useEffect(() => {
    if (lineChartRef.current) {
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [lineChartRef])

  useEffect(() => {
    if (lineChartRef.current) {
      handleResize();
    }
  }, [isSidePanelOpen])

  const prevRoutes = [
    { name: 'Analytics Dashboard', route: null }
  ]
  

  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'User Engagement'} />
      <div className="ue-header-container user-engagement">
        <h3 className="ue-header-title">
          User Engagement
        </h3>
        <div className="group-flex-8">
          <div className="dropdown-calendar">
            <button className="btn-date large cursor-pointer" onClick={() => handleDateModal()}>
              <img
                src={calendarIcon}
                alt="calendar icon"
                className="date-button-icon"
              />
              {listLabelChooseDate ?? "Choose Date"} {listLabelChooseDateCount > 0 && `+${listLabelChooseDateCount}`}
            </button>
            <DateModal
              isOpen={isDateModalOpen}
              handleDateModal={handleDateModal}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              setSelectedMonth={setSelectedMonth}
              setSelectedYear={setSelectedYear}
              currentMonth={currentMonth}
              currentYear={currentYear}
            />
          </div>
          <FilterModal
            onApply={handleFiltersApply}
          />
        </div>
      </div>
      <UserOverview
        selectedFilters={selectedFilters}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
      />
      <div className="ue-section-chart-container" ref={lineChartRef}>
        <TotalInteractionChart
          chartWidth={chartWidth}
          selectedFilters={selectedFilters}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
        <TopQueries
          selectedFilters={selectedFilters}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      </div>
      <TopFunctionByQueryChart
        selectedFilters={selectedFilters}
      />
      <TopMetricByQueryChart
        selectedFilters={selectedFilters}
      />
      <TotalInteractionByRoleChart
        selectedFilters={selectedFilters}
        selectedRole={totalInteractionByRole}
        setSelectedRole={setTotalInteractionByRole}
        isFocusChart={totalInteractionByRole.value === topTriFuncChartRole.value && totalInteractionByRole.value === topTriMetricsChartRole.value}
        indexChart={indexChart}
        setIndexChart={setIndexChart}
      />
      <TopFunctionChart
        selectedFilters={selectedFilters}
        selectedRole={topTriFuncChartRole}
        setSelectedRole={setTopTriFuncChartRole}
        isFocusChart={totalInteractionByRole.value === topTriFuncChartRole.value && totalInteractionByRole.value === topTriMetricsChartRole.value}
        indexChart={indexChart}
        setIndexChart={setIndexChart}
      />
      <TopMetricsChart
        selectedFilters={selectedFilters}
        selectedRole={topTriMetricsChartRole}
        setSelectedRole={setTopTriMetricsChartRole}
        isFocusChart={totalInteractionByRole.value === topTriFuncChartRole.value && totalInteractionByRole.value === topTriMetricsChartRole.value}
        indexChart={indexChart}
        setIndexChart={setIndexChart}
      />
    </>
  );
}

export default UserEngagement;