import React, { useState } from "react";
import warningIcon from "../../assets/warningIcon.svg";
import axiosCMS from "../../libs/axios/axiosCMS";

const UploadFunctionModal = ({ isOpen, onClose, onUploaded, onError }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [file, setFile] = useState(null);
  const [activeTab, setActiveTab] = useState("functionTab");
  const [loading, setLoading] = useState(false);

  const uploadFunction = (file) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    return axiosCMS({
      method: "post",
      url: `prompt_function/bulk_update_function`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then(
      (response) => {
        setLoading(false);

        const {
          data: {
            code,
            message,
            data: { success, failed_list },
          },
        } = response;

        if (code !== 200) {
          onUploaded({
            success: isNaN(success) ? 0 : success,
            failed: Array.isArray(failed_list) ? failed_list.length : 0,
            detail: {
              title: code,
              message:
                Array.isArray(failed_list) && failed_list.length > 0
                  ? failed_list.join(", ")
                  : message,
            },
          });
          return;
        }

        onUploaded({
          success: isNaN(success) ? 0 : success,
          failed: Array.isArray(failed_list) ? failed_list.length : 0,
          detail: {
            title: "Error Message",
            message:
              Array.isArray(failed_list) && failed_list.length > 0
                ? failed_list.join(", ")
                : message,
          },
        });
        handleClearData();
      },
      (error) => {
        setLoading(false);
        onError(error);
      }
    );
  };

  const uploadSampleQuestion = (file) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    return axiosCMS({
      method: "post",
      url: `prompt_function/bulk_update_sample_question`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then(
      (response) => {
        setLoading(false);

        const {
          data: {
            code,
            message,
            data: { success, failed_list },
          },
        } = response;

        if (code !== 200) {
          onUploaded({
            success: isNaN(success) ? 0 : success,
            failed: Array.isArray(failed_list) ? failed_list.length : 0,
            detail: {
              title: code,
              message:
                Array.isArray(failed_list) && failed_list.length > 0
                  ? failed_list.join(", ")
                  : message,
            },
          });
          return;
        }

        onUploaded({
          success: isNaN(success) ? 0 : success,
          failed: Array.isArray(failed_list) ? failed_list.length : 0,
          detail: {
            title: "Error Message",
            message:
              Array.isArray(failed_list) && failed_list.length > 0
                ? failed_list.join(", ")
                : message,
          },
        });
        handleClearData();
      },
      (error) => {
        setLoading(false);
        onError(error);
      }
    );
  };

  const handleFileChange = (e) => {
    const selectedFileName = e.target.files[0]?.name || "No file chosen";
    // Add file into state
    setFile(e.target.files[0]);
    setFileName(selectedFileName);
  };

  const handleSubmit = () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    setIsSubmitted(true);
  };

  const handleFinalSubmit = () => {
    // Prevent multiple submission
    setIsSubmitted(false);

    if (activeTab === "functionTab") {
      uploadFunction(file);
    } else if (activeTab === "sampleQuestionTab") {
      uploadSampleQuestion(file);
    }
  };

  const handleClearData = () => {
    // Reset state
    setIsSubmitted(false);
    // Clear data
    setFileName("No file chosen");
    setFile("");
  };

  const handleCancel = () => {
    handleClearData();
    // Close modal
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="upload-modal-overlay">
      <div className="upload-modal-content">
        {isSubmitted ? (
          <>
            <h2 className="upload-modal-title">Upload Bulk Confirmation</h2>
            <hr className="upload-modal-separator" />
            <p className="confirmation-text" style={{ textAlign: "center" }}>
              Do you really wish to submit this file?
            </p>
            <p className="confirmation-subtext" style={{ textAlign: "center" }}>
              Once you submit your file, multiple data based on your uploaded
              file will be added to the table.
            </p>
            <div className="upload-modal-buttons">
              <button
                className="upload-modal-button cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="upload-modal-button submit-button"
                disabled={loading}
                onClick={handleFinalSubmit}
              >
                Submit
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="upload-modal-title">Upload Bulk</h2>
            <hr className="upload-modal-separator" />
            <div className="props-toggle-buttons">
              <button
                className={`props-toggle-button ${
                  activeTab === "functionTab" ? "active" : ""
                }`}
                onClick={() => setActiveTab("functionTab")}
              >
                Function
              </button>
              <button
                className={`props-toggle-button ${
                  activeTab === "sampleQuestionTab" ? "active" : ""
                }`}
                onClick={() => setActiveTab("sampleQuestionTab")}
              >
                Sample Question
              </button>
            </div>
            <div className="file-upload-section">
              <label htmlFor="file-upload" className="file-upload-label">
                Upload Excel *
              </label>
              <div className="file-upload-input-container">
                <input
                  type="text"
                  id="file-name"
                  className="file-upload-input"
                  value={fileName}
                  readOnly
                />
                <input
                  type="file"
                  id="file-upload"
                  className="file-upload-hidden-input"
                  onChange={handleFileChange}
                />
                <button
                  className="file-upload-button"
                  onClick={() => document.getElementById("file-upload").click()}
                >
                  Select File
                </button>
              </div>
              <p className="file-upload-warning">
                <img src={warningIcon} alt="warning icon" />
                supported file excel max. 2MB
              </p>
            </div>
            <div className="upload-modal-buttons">
              <button
                className="upload-modal-button cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="upload-modal-button submit-button"
                disabled={loading}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadFunctionModal;
