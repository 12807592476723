import axios from "axios";
const BASE_URL = 'https://genai-cms-api.nawatech.co/';
// const BASE_URL = 'http://localhost:8000/';

const axiosAuth = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosAuth.interceptors.response.use((response) => {
    return response;
}, (error) => {
    return Promise.reject(error);
});

axiosAuth.interceptors.request.use((config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosAuth;