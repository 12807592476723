import { useState } from "react";
import OutsideHandler from "../../components/OutsideHandler";
import Select from "react-select";

import closeIcon from "../../assets/cancelIcon.svg";

const FilterModal = ({ isOpen, onApply, onFilterModal, dataAccessOptions, dashboardAccessOptions }) => {
  const [filters, setFilters] = useState({
    data_access: [],
    dashboard_type: [],
    status: { label: "ALL", value: "" },
  })

  const [isActiveOptions, setIsActiveOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]);

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  }

  const handleClear = () => {
    onApply({
      data_access: [],
      dashboard_type: [],
      status: ""
    });
    setFilters({
      data_access: [],
      dashboard_type: [],
      status: { label: "ALL", value: "" },
    })
    onFilterModal(false);
  }

  const handleApply = () => {
    onApply({
      data_access: filters.data_access.map((item) => item.value),
      dashboard_type: filters.dashboard_type.map((item) => item.value),
      status: filters.status.value,
    });
    onFilterModal(false);
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onFilterModal(false)}>
      <div className="role-filter__wrapper">
        <div className="role-filter__header">
          <h2 className="role-filter__title">Filter</h2>
          <button className="role-filter__close" onClick={() => onFilterModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="role-filter__separator" />
        <div className="role-filter__content">
          <div className="role-filter__form-row">
            <div className="role-filter__form-group">
              <label htmlFor="data_access" className="role-filter__input-label">Data Access</label>
              <Select
                id="data_access"
                name="data_access"
                isMulti={true}
                options={dataAccessOptions}
                value={filters.data_access}
                onChange={handleInputChange}
                placeholder="-- Select Data Access --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
              />
            </div>
            <div className="role-filter__form-group">
              <label htmlFor="dashboard_type" className="role-filter__input-label">Dashboard Type</label>
              <Select
                id="dashboard_type"
                name="dashboard_type"
                isMulti={true}
                options={dashboardAccessOptions}
                value={filters.dashboard_type}
                onChange={handleInputChange}
                placeholder={"-- Select Dashboard Type --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
              />
            </div>
          </div>
          <div className="role-filter__form-row">
            <div className="role-filter__form-group">
              <label htmlFor="status" className="role-filter__input-label">Status</label>
              <Select
                id="status"
                name="status"
                options={isActiveOptions}
                value={filters.status}
                onChange={handleInputChange}
                placeholder="-- Select Data Access --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="role-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;