import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    isSidePanelOpen: true,
    openDropdown: null,
    mappedPaths: {
        "/fallback-management": {
        title: "Fallback Management",
        access: "fallback_management",
      },
      "/fallback-management/details": {
        title: "Fallback Management",
        access: "fallback_management",
      },
      "/prompt-management": {
        title: "Promp Management",
        access: "prompt_management",
      },
      "/prompt-management/functions": {
        title: "Functions",
        access: "prompt_function",
      },
      "/prompt-management/functions/details": {
        title: "Functions",
        access: "prompt_function",
      },
      "/prompt-management/properties": {
        title: "Properties",
        access: "prompt_properties",
      },
      "/prompt-management/properties/general-properties-details": {
        title: "Properties",
        access: "prompt_properties",
      },
      "/prompt-management/properties/specific-properties-details": {
        title: "Properties",
        access: "prompt_properties",
      },
      "/prompt-management/metrics": {
        title: "Metrics",
        access: "prompt_metric",
      },
      "/prompt-management/metrics/details": {
        title: "Metrics",
        access: "prompt_metric",
      },
      "/analytics-dashboard": {
        title: "Analytics Dashboard",
        access: "analytics_dashboard",
      },
      "/analytics-dashboard/user-engagement": {
        title: "User Engagement",
        access: "user_engagement",
      },
      "/analytics-dashboard/response-quality": {
        title: "Response Quality",
        access: "response_quality",
      },
      "/analytics-dashboard/token-cost-consumption": {
        title: "Token/Cost Consumption",
        access: "token_cost_consumption",
      },
      "/user-role-management": {
        title: "User & Role Management",
        access: "user_role_management",
      },
      "/user-role-management/role-management": {
        title: "Role Management",
        access: "role_management",
      },
      "/user-role-management/user-management": {
        title: "User Management",
        access: "user_management",
      },
      "/user-role-management/dashboard-management": {
        title: "Dashboard Management",
        access: "dashboard_management",
      },
      "/user-role-management/cms-permission": {
        title: "CMS Permission",
        access: "menu_management",
      },
      // "/fallback-report": "Fallback Report",
      "/bulk-testing": {
        title: "Bulk Testing",
        access: "bulk_testing",
      },
      "/bulk-testing/details": {
        title: "Bulk Testing",
        access: "bulk_testing",
      },
      "/bulk-testing/actual-answer-details": {
        title: "Bulk Testing",
        access: "bulk_testing",
      },
    }
}

const sidePanelSlice = createSlice({
    name: "sidePanelSlice",
    initialState,
    reducers: {
        handleSwitchSidePanel: (state, action) => {
            state.isSidePanelOpen = !state.isSidePanelOpen
            state.openDropdown = null;
        },
        handleOpenDropdown: (state, action) => {
            state.openDropdown = action.payload
        },
        setMappedTabs: (state, action) => {
            state.mappedTabs = action.payload
        }
    }
})

export const { handleSwitchSidePanel, handleOpenDropdown, setMappedTabs } = sidePanelSlice.actions
export default sidePanelSlice.reducer