import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputErrorMessage from "../../components/InputErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";

import { addToast } from "../../libs/redux/slices/toastSlice";
import axiosBulk from "../../libs/axios/axiosBulk";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import tooltipIcon from "../../assets/tooltipIcon.svg";

const ExecuteBulkTestingModal = ({ actualAnswer, onSuccess, onClose }) => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const btnFinalSubmitRef = useRef(null);

  const [defaultOptions, setDefaultOptions] = useState([
    { value: "True", label: "True" },
    { value: "False", label: "False" }
  ]);

  const [fileUpload, setFileUpload] = useState(null);
  const [otherErrors, setOtherErrors] = useState({
    fileUpload: null
  });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { register, reset, watch, setValue, control, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const handleFileUpload = (e) => {
    setOtherErrors({ ...otherErrors, fileUpload: null });
    const file = e.target.files[0];
    if (file.size > (20 * 1000 * 1000)) {
      setOtherErrors({ ...otherErrors, fileUpload: "Please upload file smaller than 20 MB." });
      return;
    }
    if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setOtherErrors({ ...otherErrors, fileUpload: "Invalid file type. Only Excel (.xlsx) files are allowed" });
      return;
    }
    setFileUpload(file);
  }

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const postBulkTest = async (data, file) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    try {
      const response = await axiosBulk.post("testing/submit", {
        file: file
      }, {
        params: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      if (response.status === 200) {
        dispatch(addToast({
          type: "success",
          title: "Bulk test submitted successfully",
        }))
        onClose();
        reset();
        setFileUpload(null);
        onSuccess();
      }
    } catch (error) {
      console.error("Failed to execute bulk test", error);
      dispatch(addToast({
        type: "error",
        title: "Failed to execute bulk test",
      }))
    }
    btnFinalSubmitRef.current.removeAttribute("disabled");
  }

  const handleFinalSubmit = (data) => {
    const formatedData = {
      feature_intent_detector: data.intent_detector.value,
      feature_context_schema: data.context_schema.value,
      feature_question_list: data.question_list.value,
      feature_cache_response: data.cache_response.value,
      testId: user.user.email,
      file_url: actualAnswer.result_url,
    }
    postBulkTest(formatedData, "");
  }

  if (actualAnswer ===  null) return null;
  return (
    <div className="bulk-add__overlay">
      <div className="bulk-add__modal">
        <div className="bulk-add__header">
          <h2 className="bulk-add__title">Execute Bulk Test</h2>
          <button className="bulk-add__close" onClick={() => {
            handleConfirmation(false)
            onClose()
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="bulk-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="bulk-add__content">
                  <h3 className="bulk-add__confirmation-title">Are you sure want to execute bulk test?</h3>
                  <p className="bulk-add__confirmation-desc">This configuration will affect the bot. Are you sure want to apply these settings?</p>
                  <div className="bulk-add__footer">
                    <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button type="submit" ref={btnFinalSubmitRef} className="btn-primary font-primary" >Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="bulk-add__content">
                  <div className="bulk-add__form-group">
                    <label className="bulk-add__input-label" htmlFor="intent_detector">Intent Detector
                      <img
                        src={tooltipIcon}
                        alt="tooltip icon"
                        className="tooltip-icon anchor-intent-detector"
                      />
                      <ReactTooltip
                        id='tooltip'
                        place="top"
                        content="This feature helps determine token consumption and costs for each sample question"
                        anchorSelect=".anchor-intent-detector"
                        style={{ maxWidth: "250px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                      />
                    </label>
                    <Controller
                      control={control}
                      name="intent_detector"
                      rules={{
                        required: "Intent Detector must be filled",
                      }}
                      render={({ field }) => (
                        <Select
                          id="intent_detector"
                          name="intent_detector"
                          {...field}
                          options={defaultOptions}
                          placeholder="-- Select Intent Detector --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="intent_detector"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="bulk-add__form-group">
                    <label className="bulk-add__input-label" htmlFor="context_schema">Context Schema
                      <img
                        src={tooltipIcon}
                        alt="tooltip icon"
                        className="tooltip-icon anchor-context-schema"
                      />
                      <ReactTooltip
                        id='tooltip'
                        place="top"
                        content="This feature optimizes chat history to help the chatbot understand conversation context"
                        anchorSelect=".anchor-context-schema"
                        style={{ maxWidth: "250px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                      />
                    </label>
                    <Controller
                      control={control}
                      name="context_schema"
                      rules={{
                        required: "Context Schema must be filled",
                      }}
                      render={({ field }) => (
                        <Select
                          id="context_schema"
                          name="context_schema"
                          {...field}
                          options={defaultOptions}
                          placeholder="-- Select Context Schema --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="context_schema"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="bulk-add__form-group">
                    <label className="bulk-add__input-label" htmlFor="question_list">Question List
                      <img
                        src={tooltipIcon}
                        alt="tooltip icon"
                        className="tooltip-icon anchor-question-list"
                      />
                      <ReactTooltip
                        id='tooltip'
                        place="top"
                        content="This feature is used to check sample questions"
                        anchorSelect=".anchor-question-list"
                        style={{ maxWidth: "250px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                      />
                    </label>
                    <Controller
                      control={control}
                      name="question_list"
                      rules={{
                        required: "Question List must be filled",
                      }}
                      render={({ field }) => (
                        <Select
                          id="question_list"
                          name="question_list"
                          {...field}
                          options={defaultOptions}
                          placeholder="-- Select Question List --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="question_list"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="bulk-add__form-group">
                    <label className="bulk-add__input-label" htmlFor="cache_response">Cache Response
                      <img
                        src={tooltipIcon}
                        alt="tooltip icon"
                        className="tooltip-icon anchor-cache-response"
                      />
                      <ReactTooltip
                        id='tooltip'
                        place="top"
                        content="This feature helps reduce the chatbot's response time and token consumption"
                        anchorSelect=".anchor-cache-response"
                        style={{ maxWidth: "250px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                      />
                    </label>
                    <Controller
                      control={control}
                      name="cache_response"
                      rules={{
                        required: "Cache Response must be filled",
                      }}
                      render={({ field }) => (
                        <Select
                          id="cache_response"
                          name="cache_response"
                          {...field}
                          options={defaultOptions}
                          placeholder="-- Select Cache Response --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="cache_response"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="bulk-add__footer">
                    <button type="button" className="btn-base font-base" onClick={onClose}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Execute
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default ExecuteBulkTestingModal;