import { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputErrorMessage from "../../components/InputErrorMessage";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import { ReactComponent as Checkmark } from "../../assets/checkIcon16.svg";
import { ReactComponent as Uncheckmark } from "../../assets/minusIcon16.svg";
import chevronDown from "../../assets/chevronDownIcon24.svg";
import axiosCMS from "../../libs/axios/axiosCMS";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon20.svg";
import { ReactComponent as ClearIcon } from "../../assets/xIcon20.svg";
import Select from "react-select";
import { FixedSizeList } from "react-window";

const EditRoleModal = ({ role, onClose, defaultPSMAccess, defaultRegionAccess, defaultEstateAccess, defaultDivisionAccess, defaultComplexAccess, dashboardAccess, onSuccess }) => {

  const { register, control, reset, clearErrors, setError, setValue, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const btnFinalSubmitRef = useRef(null);

  const [psmList, setPsmList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [estateList, setEstateList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [complexList, setComplexList] = useState([]);

  const [selectedPSM, setSelectedPSM] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedEstate, setSelectedEstate] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [selectedComplex, setSelectedComplex] = useState([]);

  const [selectedDashboard, setSelectedDashboard] = useState([]);

  const [selectedAllAccess, setSelectedAllAccess] = useState(false);
  const [selectedAllPSM, setSelectedAllPSM] = useState(false);
  const [selectedAllRegion, setSelectedAllRegion] = useState(false);
  const [selectedAllEstate, setSelectedAllEstate] = useState(false);
  const [selectedAllDivision, setSelectedAllDivision] = useState(false);
  const [selectedAllComplex, setSelectedAllComplex] = useState(false);

  const [selectedAllDashboard, setSelectedAllDashboard] = useState(false);

  const [searchPSM, setSearchPSM] = useState('');
  const [searchRegion, setSearchRegion] = useState('');
  const [searchEstate, setSearchEstate] = useState('');
  const [searchDivision, setSearchDivision] = useState('');
  const [searchComplex, setSearchComplex] = useState('');

  const [searchDashboard, setSearchDashboard] = useState('');

  const [isActiveOptions, setIsActiveOptions] = useState([
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]);

  const [isOpenAccordian, setIsOpenAccordian] = useState('');
  const [isOpenDashboardAccordian, setIsOpenDashboardAccordian] = useState(true);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const storageDataAccessRef = useRef({});

  const getRoleAccessDetail = async (accessMasterId, groupName) => {
    try {
      const response = await axiosCMS.get('role/get-data-access-detail', {
        params: {
          access_master_id: accessMasterId,
          group: groupName,
        }
      })

      const result = response.data;
      storageDataAccessRef.current[accessMasterId] = result.data;
      return result.data;
    } catch (error) {
      console.error("Error fetching data access detail: ", error);
    }

  };

  const setAutoChecked = (groupName, result, currentSelected, setSelectedData, listData, setListData, isResetList) => {
    const groupIndex = result.findIndex((obj) => obj.group_name === groupName);
    if (currentSelected.length === 0) {
      setSelectedData(result[groupIndex].list_group);
      setListData(listData.filter((obj) => result[groupIndex].list_group.findIndex((item) => item.name === obj.name) !== -1));
    } else {
      const updatedSelectedData = [...currentSelected, ...result[groupIndex].list_group].filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      const updatedListData = isResetList ? listData : listData.filter((obj) => updatedSelectedData.findIndex((item) => item.name === obj.name) !== -1)
      setSelectedData(updatedSelectedData);
      setListData(updatedListData);
    }
  }

  const setAutoUnchecked = (groupName, result, currentSelected, setSelectedData, listData, setListData, isResetList) => {
    const groupIndex = result.findIndex((obj) => obj.group_name === groupName);
    const updatedSelectedData = currentSelected.filter((obj) => result[groupIndex].list_group.findIndex((item) => item.name === obj.name) === -1);
    const updatedListData = isResetList ? listData : listData.filter((obj) => updatedSelectedData.findIndex((item) => item.name === obj.name) !== -1);
    setSelectedData(updatedSelectedData);
    setListData(updatedListData);
  }

  const handleOnChangeCheck = async (e, data, groupName, currentChecked, setChecked) => {
    const { id, name, access_master_id } = data;
    const isChecked = e.target.checked;
    if (isChecked) {
      clearErrors("data_access");
      const updatedListChecked = [...currentChecked, data]
      setChecked(updatedListChecked);
      if (groupName === 'PSM') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('REGION', result, selectedRegion, setSelectedRegion, defaultRegionAccess, setRegionList);
        setAutoChecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList);
        setAutoChecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      } else if (groupName === 'REGION') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList);
        setAutoChecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      } else if (groupName === 'ESTATE') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      } else if (groupName === 'DIVISION') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList);
      }
    } else {
      const updatedListChecked = currentChecked.filter((obj) => obj.name !== name);
      setChecked(updatedListChecked);
      if (groupName === 'PSM') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('REGION', result, selectedRegion, setSelectedRegion, defaultRegionAccess, setRegionList, updatedListChecked.length === 0);
        setAutoUnchecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList, updatedListChecked.length === 0);
        setAutoUnchecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList, updatedListChecked.length === 0);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      } else if (groupName === 'REGION') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList, updatedListChecked.length === 0);
        setAutoUnchecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList, updatedListChecked.length === 0);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      } else if (groupName === 'ESTATE') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('DIVISION', result, selectedDivision, setSelectedDivision, defaultDivisionAccess, setDivisionList, updatedListChecked.length === 0);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      } else if (groupName === 'DIVISION') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('COMPLEX', result, selectedComplex, setSelectedComplex, defaultComplexAccess, setComplexList, updatedListChecked.length === 0);
      }
    }
  }

  const handleSelectAllAccess = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedPSM(psmList);
      setSelectedRegion(regionList);
      setSelectedEstate(estateList);
      setSelectedDivision(divisionList);
      setSelectedComplex(complexList);

      clearErrors("data_access");
    } else {
      setSelectedPSM([]);
      setSelectedRegion([]);
      setSelectedEstate([]);
      setSelectedDivision([]);
      setSelectedComplex([]);
    }
    setSelectedAllAccess(isChecked);
    setSelectedAllPSM(isChecked);
    setSelectedAllRegion(isChecked);
    setSelectedAllEstate(isChecked);
    setSelectedAllDivision(isChecked);
    setSelectedAllComplex(isChecked);
  }

  const handleSelectAllSub = (e, targetSelected, listSelected, setChecked) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      targetSelected(listSelected);
      clearErrors("data_access");
    } else {
      targetSelected([]);
    }
    setChecked(isChecked);
  }

  const handleChangeDashboard = (e, data) => {
    if (e.target.checked) {
      setSelectedDashboard([...selectedDashboard, data]);
      clearErrors("dashboard_type");
    } else {
      setSelectedDashboard(selectedDashboard.filter((obj) => obj.id !== data.id));
    }
  }

  const handleSelectAllDashboard = (e) => {
    if (e.target.checked) {
      setSelectedDashboard(dashboardAccess);
      setSelectedAllDashboard(true);
      clearErrors("dashboard_type");
    } else {
      setSelectedDashboard([]);
      setSelectedAllDashboard(false);
    }
  }

  const postEditRole = async (data, id) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    console.log(btnFinalSubmitRef.current);
    const URL = "role/update-role/";
    try {
      const response = await axiosCMS.post(URL + id, data);
      if (response.status === 200) {
        dispatch(addToast({
          type: 'success',
          title: 'Changes were successfully saved'
        }))
        onClose(null);
        setIsConfirmationOpen(false);
        onSuccess();
      }
    } catch (error) {
      console.error("Error adding role: ", error);
      dispatch(addToast({
        type: 'error',
        title: 'Error submitting role'
      }))
    }
    btnFinalSubmitRef.current.removeAttribute("disabled");
  }

  const handleConfirmation = (action = null) => {
    let isValid = true;
    let message = '';
    if (selectedPSM.length === 0 && selectedRegion.length === 0 && selectedEstate.length === 0 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Data access must be filled";
      isValid = false;
    } else if (selectedPSM.length >= 1 && selectedRegion.length === 0 && selectedEstate.length === 0 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Region, Estate, Division and Complex must be filled";
      isValid = false;
    } else if (selectedRegion.length >= 1 && selectedEstate.length === 0 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Estate, Division and Complex must be filled";
      isValid = false;
    } else if (selectedEstate.length >= 1 && selectedDivision.length === 0 && selectedComplex.length === 0) {
      message = "Division and Complex must be filled";
      isValid = false;
    } else if (selectedDivision.length >= 1 && selectedComplex.length === 0) {
      message = "Complex must be filled";
      isValid = false;
    }

    if (!isValid) {
      setError("data_access", {
        types: {
          required: message,
        }
      });
      setIsOpenAccordian('');
      return;
    }

    if (selectedDashboard.length === 0) {
      setError("dashboard_type", {
        types: {
          required: "Dashboard type must be filled",
        }
      });
      return;
    }
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }


  const handleFinalSubmit = (form) => {
    const psm = selectedPSM.map((obj) => obj.id);
    const region = selectedRegion.map((obj) => obj.id);
    const estate = selectedEstate.map((obj) => obj.id);
    const division = selectedDivision.map((obj) => obj.id);
    const complex = selectedComplex.map((obj) => obj.id);

    const dashboardType = selectedDashboard.map((obj) => obj.id);
    const data = {
      role_name: form.name,
      data_access: [...psm, ...region, ...estate, ...division, ...complex],
      dashboard_type: dashboardType,
      status: form.is_active.value,
    }
    postEditRole(data, role.id)
  }

  const handleAccordian = (type) => {
    setIsOpenAccordian(isOpenAccordian === type ? '' : type);
  }

  const getRoleById = async (id) => {
    const URL = "role/get-role/";
    try {
      const response = await axiosCMS.get(URL + id);
      const result = response.data;
      if (response.status === 200) {
        setValue('name', result.data.role.role_name);
        setValue('is_active', result.data.role.is_active === 'active' ? isActiveOptions[0] : isActiveOptions[1]);
        const currentSelectedPSM = result.data.data_access.findIndex((obj) => obj.group_name === 'PSM');
        const currentSelectedRegion = result.data.data_access.findIndex((obj) => obj.group_name === 'REGION');
        const currentSelectedEstate = result.data.data_access.findIndex((obj) => obj.group_name === 'ESTATE');
        const currentSelectedDivision = result.data.data_access.findIndex((obj) => obj.group_name === 'DIVISION');
        const currentSelectedComplex = result.data.data_access.findIndex((obj) => obj.group_name === 'COMPLEX');

        currentSelectedPSM !== -1 ? setSelectedPSM(defaultPSMAccess.filter((obj) => result.data.data_access[currentSelectedPSM].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedPSM([]);
        currentSelectedRegion !== -1 ? setSelectedRegion(defaultRegionAccess.filter((obj) => result.data.data_access[currentSelectedRegion].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedRegion([]);
        currentSelectedEstate !== -1 ? setSelectedEstate(defaultEstateAccess.filter((obj) => result.data.data_access[currentSelectedEstate].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedEstate([]);
        currentSelectedDivision !== -1 ? setSelectedDivision(defaultDivisionAccess.filter((obj) => result.data.data_access[currentSelectedDivision].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedDivision([]);
        currentSelectedComplex !== -1 ? setSelectedComplex(defaultComplexAccess.filter((obj) => result.data.data_access[currentSelectedComplex].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedComplex([]);

        setSelectedDashboard(result.data.dashboard_type.map((obj) => ({ id: obj.dashboard_id, dashboard_name: obj.dashboard_name })));
      }
    } catch (error) {
      console.error('Failed to get dashboard data', error);
    }
  }

  useEffect(() => {
    if (role !== null) {
      getRoleById(role.id);
    }
  }, [role]);

  useEffect(() => {
    setPsmList(defaultPSMAccess);
    setRegionList(defaultRegionAccess);
    setEstateList(defaultEstateAccess);
    setDivisionList(defaultDivisionAccess);
    setComplexList(defaultComplexAccess);
  }, [defaultEstateAccess, defaultPSMAccess, defaultRegionAccess, defaultDivisionAccess, defaultComplexAccess]);

  const accordianList = [
    {
      dataName: 'PSM',
      selectedData: selectedPSM,
      selectedDataLength: selectedPSM.length,
      setSelectedData: setSelectedPSM,
      dataList: psmList,
      dataListLength: psmList.length,
      selectedAllData: selectedAllPSM,
      setSelectedAllData: setSelectedAllPSM,
      searchData: searchPSM,
      setSearchData: setSearchPSM,
    },
    {
      dataName: 'REGION',
      selectedData: selectedRegion,
      selectedDataLength: selectedRegion.length,
      setSelectedData: setSelectedRegion,
      dataList: regionList,
      dataListLength: regionList.length,
      selectedAllData: selectedAllRegion,
      setSelectedAllData: setSelectedAllRegion,
      searchData: searchRegion,
      setSearchData: setSearchRegion,
    },
    {
      dataName: 'ESTATE',
      selectedData: selectedEstate,
      selectedDataLength: selectedEstate.length,
      setSelectedData: setSelectedEstate,
      dataList: estateList,
      dataListLength: estateList.length,
      selectedAllData: selectedAllEstate,
      setSelectedAllData: setSelectedAllEstate,
      searchData: searchEstate,
      setSearchData: setSearchEstate,
    },
    {
      dataName: 'DIVISION',
      selectedData: selectedDivision,
      selectedDataLength: selectedDivision.length,
      setSelectedData: setSelectedDivision,
      dataList: divisionList,
      dataListLength: divisionList.length,
      selectedAllData: selectedAllDivision,
      setSelectedAllData: setSelectedAllDivision,
      searchData: searchDivision,
      setSearchData: setSearchDivision,
    },
    {
      dataName: 'COMPLEX',
      selectedData: selectedComplex,
      selectedDataLength: selectedComplex.length,
      setSelectedData: setSelectedComplex,
      dataList: complexList,
      dataListLength: complexList.length,
      selectedAllData: selectedAllComplex,
      setSelectedAllData: setSelectedAllComplex,
      searchData: searchComplex,
      setSearchData: setSearchComplex,
    }
  ]

  if (role === null) return null;
  return (
    <div className="role-add__overlay">
      <div className="role-add__modal">
        <div className="role-add__header">
          <h2 className="role-add__title">Edit Role</h2>
          <button className="role-add__close" onClick={() => {
            handleConfirmation(false)
            onClose(null)
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="role-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="role-add__content">
                  <h3 className="role-add__confirmation-title">Do you really wish to submit edited role?</h3>
                  <p className="role-add__confirmation-desc">Once you submit, the edited role will be updated to the system.</p>
                  <div className="role-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => handleConfirmation(null)}>Cancel</button>
                    <button type="submit" ref={btnFinalSubmitRef} className="btn-primary font-primary">Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="role-add__content">
                  <div className="role-add__form-group">
                    <label className="role-add__input-label" htmlFor="name">Role Name <span className="role-add__input-required">*</span></label>
                    <input type="text" className="role-add__input-box"
                      {...register("name", {
                        required: "Role name must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label className="role-add__input-label">Data Access</label>
                    <div className="role-add__accordian">
                      <div className="role-add__accordian-header">
                        <label className='role-add__form-checkbox'>
                          <input type='checkbox'
                            onChange={handleSelectAllAccess}
                            checked={selectedAllAccess}
                          />
                          <div className='role-add__checkmark'>
                            <Checkmark className="role-add__checkmark-icon" />
                            <Uncheckmark className="role-add__checkmark-icon" />
                          </div>
                          ALL ACCESS
                        </label>
                      </div>
                      <div className="role-add__accordian-separator">
                      </div>
                      {accordianList.map((accordian, index) => (
                        <AccordianCheckbox
                          key={index}
                          dataList={accordian.dataList}
                          dataName={accordian.dataName}
                          selectedData={accordian.selectedData}
                          dataListLength={accordian.dataListLength}
                          setSelectedData={accordian.setSelectedData}
                          selectedAllData={accordian.selectedAllData}
                          setSelectedAllData={accordian.setSelectedAllData}
                          searchData={accordian.searchData}
                          setSearchData={accordian.setSearchData}
                          handleAccordian={handleAccordian}
                          isOpenAccordian={isOpenAccordian}
                          handleOnChangeCheck={handleOnChangeCheck}
                          handleSelectAllSub={handleSelectAllSub}
                          selectedDataLength={accordian.selectedDataLength}
                        />
                      ))}
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="dashboard_type"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label htmlFor="is_active" className="role-add__input-label">Status</label>
                    <Controller
                      control={control}
                      name="is_active"
                      rules={{ required: "Status must be filled" }}
                      defaultValue={isActiveOptions[0]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={isActiveOptions}
                          placeholder="-- Select Status --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="is_active"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => onClose(null)}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

const AccordianCheckbox = ({
  key,
  dataName,
  selectedData,
  selectedDataLength,
  setSelectedData,
  dataList,
  dataListLength,
  selectedAllData,
  setSelectedAllData,
  handleAccordian,
  isOpenAccordian,
  searchData,
  setSearchData,
  handleSelectAllSub,
  handleOnChangeCheck
}) => {
  return (
    <>
      <div key={key} className="role-add__accordian-separator">
        <div className="role-add__accordian-header">
          <label className='role-add__form-checkbox'>
            <input type='checkbox'
              onChange={(e) => handleSelectAllSub(e, setSelectedData, dataList, setSelectedAllData)}
              checked={selectedAllData}
            />
            <div className={`role-add__checkmark ${selectedDataLength >= 1 && !selectedAllData ? 'show' : ''}`}>
              {
                selectedDataLength >= 1 && !selectedAllData
                  ? <Uncheckmark className="role-add__checkmark-icon show" />
                  : <Checkmark className="role-add__checkmark-icon" />
              }
            </div>
            {dataName}
          </label>
          <img src={chevronDown} onClick={() => handleAccordian(dataName)} alt="chevron-down" className={`role-add__accordian-icon cursor-pointer ${isOpenAccordian === dataName ? 'rotate' : ''}`} />
        </div>
        <div className={`role-add__accordian-wrapper ${isOpenAccordian === dataName ? 'show' : ''}`}>
          <div className="role-add__accordian-content">
            <div className="role-add__accordian-search-wrapper">
              <input
                type="text"
                placeholder="Search"
                className="role-add__accordian-search"
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
              />
              {searchData
                ? <ClearIcon className="role-add__accordian-search-icon" onClick={() => setSearchData('')} />
                : <SearchIcon className="role-add__accordian-search-icon" />
              }
            </div>
            <ul className="role-add__accordian-list">
              <FixedSizeList
                height={400}
                itemCount={searchData ? dataList.filter((obj) => obj.name.toLowerCase().includes(searchData.toLowerCase())).length : dataList.length}
                itemSize={40}
                itemData={searchData ? dataList.filter((obj) => obj.name.toLowerCase().includes(searchData.toLowerCase())) : dataList}
                itemKey={(index, data) => data[index]?.id || index}
              >
                {({ index, style, data }) => {
                  const item = data[index];
                  if (!item) return "No Item";
                  return (
                    <li key={item.id} style={style}>
                      <label className='role-add__form-checkbox'>
                        <input type='checkbox'
                          onChange={(e) => handleOnChangeCheck(e, item, dataName, selectedData, setSelectedData)}
                          checked={selectedData.some(obj => obj.id === item.id)}
                        />
                        <span className='role-add__checkmark'>
                          <Checkmark className="role-add__checkmark-icon" />
                        </span>
                        {item.name}
                      </label>
                    </li>
                  )
                }}
              </FixedSizeList>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditRoleModal;