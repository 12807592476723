import { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";

import Select from "react-select";
import InputErrorMessage from "../../components/InputErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";

const EditUserModal = ({ user, onClose, onSuccess, roleOptions, cmsPermissionOptions }) => {
  const dispatch = useDispatch();
  const btnFinalSubmitRef = useRef(null);
  const { register, control, setValue, watch, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all",
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ]

  const cmsAccessOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]

  const cmsAccessWatch = watch("cms_access", cmsAccessOptions[1]);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const updateUser = async (id, user) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    const URL = "user-management/update-user/";
    try {
      const response = await axiosCMS.post(URL + id, user);
      if (response.status === 200) {
        onSuccess();
        dispatch(addToast({
          type: 'success',
          title: "Changes were successfully saved"
        }))
        onClose();
        setIsConfirmationOpen(false);
      }
    } catch (error) {
      console.error("Failed to update user", error);
    }
    btnFinalSubmitRef.current.removeAttribute("disabled");
  }

  const handleFinalSubmit = (data) => {
    const formatedData = {
      name: data.name,
      email: data.email,
      is_cms: data.cms_access.value,
      priv_id: data.cms_permission ? data.cms_permission.value : null,
      role_id: data.role.value,
      is_active: data.status.value
    }
    updateUser(user.id, formatedData)
  }

  const getUserDetail = async (user) => {
    const URL = "user-management/get-user/";
    try {
      const response = await axiosCMS.get(URL + user.id);
      if (response.status === 200) {
        const result = response.data;
        setValue("name", result.data.name);
        setValue("email", result.data.email);
        setValue("cms_access", result.data.is_cms ? cmsAccessOptions[0] : cmsAccessOptions[1]);
        setValue("cms_permission", cmsPermissionOptions.find(option => option.value === result.data.priv_id) ?? {});
        setValue("role", roleOptions.find(option => option.value === result.data.role_id) ?? {});
        setValue("status", result.data.is_active ? statusOptions[0] : statusOptions[1]);
      }
    } catch (error) {
      console.error("Failed to get user detail", error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserDetail(user);
    }
  }, [user])

  if (user === null) return null;
  return (
    <div className="user-add__overlay">
      <div className="user-add__modal">
        <div className="user-add__header">
          <h2 className="user-add__title">Edit User</h2>
          <button className="user-add__close" onClick={() => {
            handleConfirmation(false)
            onClose()
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="user-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="user-add__content">
                  <h3 className="user-add__confirmation-title">Do you really wish to submit edited user?</h3>
                  <p className="user-add__confirmation-desc">Once you submit, the edited user will be updated to the system.</p>
                  <div className="user-add__footer">
                    <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button type="submit" ref={btnFinalSubmitRef} className="btn-primary font-primary">Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="user-add__content">
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="name">Name <span className="user-add__input-required">*</span></label>
                    <input
                      className="user-add__input-box"
                      {...register("name", {
                        required: "Name must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="email">Email <span className="user-add__input-required">*</span></label>
                    <input
                      type="text"
                      className="user-add__input-box"
                      {...register("email", {
                        required: "Email must be filled",
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Email format doesn't recognized "
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="cms_access">CMS Access <span className="user-add__input-required">*</span></label>
                    <Controller
                      control={control}
                      name="cms_access"
                      rules={{
                        required: "CMS Access must be filled",
                      }}
                      defaultValue={cmsAccessOptions[1]}
                      render={({ field }) => (
                        <Select
                          id="cms_access"
                          name="cms_access"
                          {...field}
                          options={cmsAccessOptions}
                          placeholder="-- Select CMS Access --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="cms_access"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  {
                    cmsAccessWatch.value && (
                      <div className="user-add__form-group">
                        <label className="user-add__input-label" htmlFor="cms_permission">CMS Permission <span className="user-add__input-required">*</span></label>
                        <Controller
                          control={control}
                          name="cms_permission"
                          rules={{
                            required: "CMS Permission must be filled",
                          }}
                          defaultValue={cmsPermissionOptions[1]}
                          render={({ field }) => (
                            <Select
                              id="cms_permission"
                              name="cms_permission"
                              {...field}
                              options={cmsPermissionOptions}
                              placeholder="-- Select CMS Permission --"
                              className="multi-select"
                              classNamePrefix="select"
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="cms_permission"
                          render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                              <InputErrorMessage key={type} message={message} />
                            ))
                          }
                        />
                      </div>
                    )
                  }
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="role">Role <span className="user-add__input-required">*</span></label>
                    <Controller
                      control={control}
                      name="role"
                      rules={{
                        required: "Role must be filled",
                      }}
                      render={({ field }) => (
                        <Select
                          id="role"
                          name="role"
                          {...field}
                          options={roleOptions}
                          placeholder="-- Select Role --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="role"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="status">Status</label>
                    <Controller
                      control={control}
                      name="status"
                      rules={{
                        required: "Status must be filled",
                      }}
                      defaultValue={statusOptions[0]}
                      render={({ field }) => (
                        <Select
                          id="status"
                          name="status"
                          {...field}
                          options={statusOptions}
                          placeholder="-- Select Status --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="status"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__footer">
                    <button type="button" className="btn-base font-base" onClick={onClose}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default EditUserModal;