import { useCallback, useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import plusIcon from "../../assets/plusIconWhite20.svg";
import searchIcon from "../../assets/searchIcon20.svg";
import clearIcon from "../../assets/xIcon20.svg";
import "./UserManagement.css";
import Pagination from "../../components/Pagination";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import FilterModal from "./FilterModal";
import debounce from "../../utils/Debounce";

import { ReactComponent as TrashIcon } from "../../assets/trashIcon16.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";

import AddUserModal from "./AddUserModal";
import kebabMenu from "../../assets/kebabMenu.svg";
import OutsideHandler from "../../components/OutsideHandler";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";
import LoadingTable from "../../components/LoadingTable";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import { convertFormatedDate } from "../../utils/FunctionHelpers";

const SIZE_PAGE = 10;
const DEFAULT_SORT = 'desc';
const DEFAULT_ORDER_BY = 'updated_at';

const UserManagement = () => {
  useDocumentTitle('User Management')
  const prevRoutes = [
    { name: 'User & Role Management', route: null }
  ]
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [cmsPermissionOptions, setCmsPermissionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    search: "",
    role_group: [],
    cms_access: "",
    cms_permission: [],
    status: "",
    page: 1,
    size: SIZE_PAGE,
    sort: DEFAULT_SORT,
    order_by: DEFAULT_ORDER_BY,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedSort, setSelectedSort] = useState({ order_by: DEFAULT_ORDER_BY, sort: DEFAULT_SORT });
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);


  const handleFilterApply = (filters) => {
    // Apply filters
    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...filters }));
    setCurrentPage(1);
  }

  const handleFilterModal = (action = null) => {
    if (action === null) {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else {
      setIsFilterModalOpen(action);
    }
  }

  const handleAddUserModal = (action = null) => {
    if (action === null) {
      setIsAddUserModalOpen(!isAddUserModalOpen);
    } else {
      setIsAddUserModalOpen(action);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleActiveMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  };

  const getRoles = useCallback(async () => {
    const URL = "user-management/get-list-role-name/"
    try {
      const response = await axiosCMS.get(URL);
      if (response.status === 200) {
        const result = response.data;
        setRoleOptions(result.data.map(role => ({ label: role.role_name, value: role.id })));
      }
    } catch (error) {
      console.error("Failed to fetch roles", error);
    }
  }, [])

  const getDashboards = useCallback(async () => {
    const URL = "user-management/get-list-dashboard/";
    try {
      const response = await axiosCMS.get(URL);
      if (response.status === 200) {
        const result = response.data;
        setDashboardOptions(result.data.map(dashboard => ({ label: dashboard.dashboard_name, value: dashboard.id })));
      }
    } catch (error) {
      console.error("Failed to fetch dashboards", error);
    }
  }, [])

  const getCMSPermission = useCallback(debounce(async () => {
    const URL = "user-management/get-list-cms-permission/";
    try {
      const response = await axiosCMS.get(URL)
      if (response.status === 200) {
        const result = response.data.data.map((item) => ({ label: item.permission_group_name, value: item.id }));
        setCmsPermissionOptions(result);
      }
    } catch (error) {
      console.error('Failed to fetch Permission Group data', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch Permission Group data',
      }))
    }
  }, 500), [])

  useEffect(() => {
    getRoles();
    getDashboards();
    getCMSPermission();
  }, [])

  const getUsers = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    try {
      const URL = "user-management/get/";
      const response = await axiosCMS.post(URL, filters);
      if (response.status === 200) {
        const result = response.data;
        setUsers(result.data.result || []);
        setTotalPages(result.data.total_page || 1);
      }
    } catch (error) {
      console.error('Error fetching users', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch data users'
      }))
    }
    setIsLoading(false);
  }, 1000), [])

  useEffect(() => {
    getUsers({
      ...selectedFilters,
      page: currentPage,
      order_by: selectedSort.order_by,
      sort: selectedSort.sort
    });
  }, [selectedFilters, currentPage, selectedSort]);

  const handleChangeSearch = debounce((search) => {
    handleFilterApply({ search });
  }, 300)

  useEffect(() => {
    handleChangeSearch(search);
  }, [search])

  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'User Management'} />
      <div className="user-mg">
        <div className="user-mg__header-container">
          <h1 className="user-mg__header-title">User Management</h1>
          <div className="user-mg__action-container">
            <div style={{ position: 'relative' }}>
              <input type="text" placeholder="Search by User Name or Email" className="user-search" value={search} onChange={(e) => setSearch(e.target.value)} />
              {search
                ? <img src={clearIcon} alt="search icon" className="user-search__icon" onClick={() => setSearch('')} />
                : <img src={searchIcon} alt="search icon" className="user-search__icon" />
              }
            </div>
            <div className="user-filter">
              <button onClick={handleFilterModal} className="btn-base with-icon font-base">
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className="filter-button-icon"
                />
                Filter
              </button>
              <FilterModal
                isOpen={isFilterModalOpen}
                onFilterModal={handleFilterModal}
                onApply={handleFilterApply}
                roleOptions={roleOptions}
                dashboardOptions={dashboardOptions}
                cmsPermissionOptions={cmsPermissionOptions}
              />
            </div>
            <button onClick={handleAddUserModal} className="btn-primary with-icon font-primary" >
              <img
                src={plusIcon}
                alt="filter icon"
                className="filter-button-icon"
              />
              Add User
            </button>
            <AddUserModal
              isOpen={isAddUserModalOpen}
              onClose={handleAddUserModal}
              roleOptions={roleOptions}
              cmsPermissionOptions={cmsPermissionOptions}
              onSuccess={() => getUsers({
                ...selectedFilters,
                page: currentPage,
                order_by: selectedSort.order_by,
                sort: selectedSort.sort
              })}
            />
          </div>
        </div>
        <div className="user-table">
          <div className="user-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>CMS Access</th>
                  <th>Permission Group</th>
                  <th>Role Group</th>
                  <th>Status</th>
                  <th>
                    <div className="group-flex-8 align-center">
                      <span>Last Updated</span>
                      <img
                        src={
                          selectedSort.order_by === "updated_at"
                            ? selectedSort.sort === "asc"
                              ? sortIconAsc
                              : sortIconDesc
                            : sortIcon
                        }
                        className="sort-icon"
                        alt="Sort icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedSort({
                            order_by: "updated_at",
                            sort:
                              selectedSort.order_by === "updated_at"
                                ? selectedSort.sort === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc",
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th style={{ width: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ?
                    <LoadingTable columnSize={8} rowSize={4} />
                    : (
                      users.length > 0 ? users.map(user => (
                        <tr key={user.id}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.is_cms ? "Yes" : "No"}</td>
                          <td>{user.is_cms ? user.permission_name : "-"}</td>
                          <td>{user.role_name}</td>
                          <td>
                            {user.is_active
                              ? <span className="user-badge--active">Active</span>
                              : <span className="user-badge--inactive">Inactive</span>
                            }
                          </td>
                          <td>{convertFormatedDate(user.updated_at)}</td>
                          <td>
                            <div className="kebab-menu-container">
                              <img
                                src={kebabMenu}
                                alt="kebab menu"
                                onClick={() => handleActiveMenu(user.id)}
                                style={{ cursor: "pointer" }}
                              />
                              {activeMenuIndex === user.id && (
                                <OutsideHandler handleClose={handleActiveMenu}>
                                  <div className="user-menu">
                                    <div
                                      className="user-menu-option"
                                      onClick={() => setIsEditUserModalOpen(user)}
                                    >
                                      <EditIcon className="user-menu-icon" />
                                      Edit
                                    </div>
                                    <div
                                      className="user-menu-option"
                                      onClick={() => setIsDeleteUserModalOpen(user)}
                                    >
                                      <TrashIcon className="user-menu-icon" />
                                      Delete
                                    </div>
                                  </div>
                                </OutsideHandler>
                              )}
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={7} className="text-center">No data available</td>
                        </tr>
                      )
                    )
                }
              </tbody>
            </table>
          </div>
          <Pagination currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} />
        </div>
        <EditUserModal
          user={isEditUserModalOpen}
          onClose={() => setIsEditUserModalOpen(null)}
          onSuccess={() => getUsers({
            ...selectedFilters,
            page: currentPage,
            order_by: selectedSort.order_by,
            sort: selectedSort.sort
          })}
          roleOptions={roleOptions}
          cmsPermissionOptions={cmsPermissionOptions} />
        <DeleteUserModal
          user={isDeleteUserModalOpen}
          onClose={() => setIsDeleteUserModalOpen(null)}
          onSuccess={() => getUsers({
            ...selectedFilters,
            page: currentPage,
            order_by: selectedSort.order_by,
            sort: selectedSort.sort
          })}
        />
      </div>
    </>
  )
}

export default UserManagement;