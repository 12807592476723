import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "../assets/dashboardIcon.svg";
import { ReactComponent as FunctionIcon } from "../assets/functionMgmtIcon.svg";
import { ReactComponent as FallbackIcon } from "../assets/fallbackMgmtIcon.svg";
import { ReactComponent as UserRoleIcon } from "../assets/usersRoundIcon24.svg";
import { ReactComponent as FallbackReportIcon } from "../assets/fileChartColumnIcon24.svg";
import { ReactComponent as DropDownIcon } from "../assets/dropdownIcon.svg";
import { ReactComponent as BulkTestIcon } from "../assets/squareCodeIcon24.svg";
import { useEffect, useState } from "react";

import userIcon from "../assets/userIcon.jpg";
import signoutIcon from "../assets/signoutIcon.svg";
import { handleOpenDropdown, setMappedTabs } from "../libs/redux/slices/sidePanelSlice";


const SideBar = () => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const dispatch = useDispatch();

  const {isSidePanelOpen, openDropdown, mappedPaths} = useSelector((state) => state.sidePanel);
  const [mappedTabs, setMappedTabs] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    setActiveTab(mappedPaths[location.pathname]?.title || "");

    if (location.pathname === "/") {
      navigate("/fallback-management", { replace: true });
    }
  }, [location.pathname, navigate]);

  let tabs = [
    {
      title: "Fallback Management",
      icon: <FallbackIcon className="tab-icon" />,
      path: "/fallback-management",
      access: 'fallback_management',
      hasDropdown: false,
      dropdownTabs: []
    },
    {
      title: "Analytics Dashboard",
      icon: <DashboardIcon className="tab-icon" />,
      path: "/analytics-dashboard",
      access: null,
      hasDropdown: true,
      dropdownTabs: [
        { title: "User Engagement", path: "/analytics-dashboard/user-engagement", access: 'user_engagement' },
        { title: "Response Quality", path: "/analytics-dashboard/response-quality", access: 'response_quality' },
        { title: "Token/Cost Consumption", path: "/analytics-dashboard/token-cost-consumption", access: 'token_cost_consumption' },
      ],
    },
    {
      title: "Prompt Management",
      icon: <FunctionIcon className="tab-icon" />,
      path: "/prompt-management",
      access: null,
      hasDropdown: true,
      dropdownTabs: [
        { title: "Functions", path: "/prompt-management/functions", access: 'prompt_function' },
        { title: "Properties", path: "/prompt-management/properties", access: 'prompt_properties' },
        { title: "Metrics", path: "/prompt-management/metrics", access: 'prompt_metric' },
      ],
    },
    {
      title: "User & Role Management",
      icon: <UserRoleIcon className="tab-icon" />,
      path: "/user-role-management",
      hasDropdown: true,
      dropdownTabs: [
        { title: "Role Management", path: "/user-role-management/role-management", access: 'role_management' },
        { title: "User Management", path: "/user-role-management/user-management", access: 'user_management' },
        { title: "Dashboard Management", path: "/user-role-management/dashboard-management", access: 'dashboard_management' },
        { title: "CMS Permission", path: "/user-role-management/cms-permission", access: 'menu_management' },
      ],
    },
    // {
    //   title: "Fallback Report",
    //   icon: <FallbackReportIcon className="tab-icon" />,
    //   path: "/fallback-report",
    // },
    {
      title: "Bulk Testing",
      icon: <BulkTestIcon className="tab-icon" />,
      path: "/bulk-testing",
      access: 'bulk_testing',
      hasDropdown: false,
      dropdownTabs: []
    },
  ];

  useEffect(() => {
    if (user.user) {
      const access_permission = user.cms_access.map((access) => access.menu_name);
      let tempTabs = [];
      tabs.forEach(tab => {
        if (!tab.hasDropdown && tab.access !== null) {
          if (access_permission.includes(tab.access)) {
            tempTabs.push(tab);
          }
        } else {
          let tempSubTabs = [];
          tab.dropdownTabs.forEach(subTab => {
            if (access_permission.includes(subTab.access)) {
              tempSubTabs.push(subTab);
            }
          })
          if (tempSubTabs.length > 0) {
            tab.dropdownTabs = tempSubTabs;
            tempTabs.push(tab);
          }
        }
      })
      setMappedTabs(tempTabs);
    }
  }, [user]);

  const handleTabClick = (tabTitle, path) => {
    setActiveTab(tabTitle);
    navigate(path);
  };

  const toggleDropdown = (tabTitle) => {
    dispatch(handleOpenDropdown(openDropdown === tabTitle ? null : tabTitle));
  };

  const renderBottomTabs = () => {
    return (
      <div className={`bottom-tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
        <div className="bottom-tab" onClick={() => alert("User Profile")}>
          <img src={userIcon} className="user-icon" alt="User Icon" />
          {isSidePanelOpen && <p className="tab-name">User</p>}
        </div>
        <div className="bottom-tab" onClick={() => alert("Sign Out")}>
          <img src={signoutIcon} className="tab-icon" alt="Signout Icon" />
          {isSidePanelOpen && <p className="sign-out">Sign Out</p>}
        </div>
      </div>
    );
  };

  return (
    <>{
      mappedTabs.map((tab) => (
        <div key={tab.title} className={`tab-wrapper ${!isSidePanelOpen && 'close'}`}>
          <div
            className={`tab ${activeTab === tab.title ? "active" : ""}`}
            onClick={() =>
              tab.hasDropdown ? toggleDropdown(tab.title) : handleTabClick(tab.title, tab.path)
            }
          >
            <div className="tab-content">
              {tab.icon}
              {isSidePanelOpen && <p className="tab-name">{tab.title}</p>}
            </div>
            {isSidePanelOpen && tab.hasDropdown && (
              <DropDownIcon
                className={`dropdown-icon ${openDropdown === tab.title ? "rotate" : ""}`}
              />
            )}
          </div>
          {tab.hasDropdown && openDropdown === tab.title && (
            <div className={`${isSidePanelOpen ? 'dropdown' : 'sub-tab'}`}>
              {tab.dropdownTabs.map((subTab) => (
                <div
                  key={subTab.title}
                  className={`sub-tab-item ${activeTab === subTab.title ? "active" : ""} ${isSidePanelOpen ? 'side-panel-open' : ''}`}
                  onClick={() => handleTabClick(subTab.title, subTab.path)}
                >
                  <p className="sub-tab-name">{subTab.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))
    }</>
  )
}

export default SideBar