import React, { useState } from 'react';

const ManualPagination = ({ itemsLength, itemsPerPage, currentPage, handlePageChange }) => {
    const pageSize = 5;
    const totalPages = Math.ceil(itemsLength / itemsPerPage);
    const startPage = Math.floor((currentPage - 1) / pageSize) * pageSize + 1;
    const endPage = Math.min(startPage + pageSize - 1, totalPages);
    const pageNumbers = endPage < startPage
        ? [currentPage]
        : [...Array(endPage - startPage + 1).keys()].map(num => startPage + num);
    return (
        <div className="pagination">
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
            >
                <span>&lt;</span>
            </button>
            {pageNumbers.map(page => (
                <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                >
                    {page}
                </button>
            ))}
            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
            >
                <span>&gt;</span>
            </button>
        </div>
    );
};

export default ManualPagination;