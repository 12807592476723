import { useRef, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputErrorMessage from "../../components/InputErrorMessage";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";

const AddMenuModal = ({ isOpen, menuOptions, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const btnFinalSubmitRef = useRef(null);
  const { register, reset, control, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const statusOptions = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' }
  ];

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = async (data) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    const URL = "cms/create/";
    const form = {
      permission_group_name: data.privilege_name,
      menu_ids: data.menu_list.map(menu => menu.value),
      status: data.is_active.value
    }
    try {
      const response = await axiosCMS.post(URL, form);
      if (response.status === 200) {
        onSuccess();
        dispatch(addToast({
          type: "success",
          title: "New data was successfully added"
        }))
        reset();
        handleConfirmation(false);
        onClose(false);
      }
    } catch (error) {
      console.error('failed to add new data', error);
      dispatch(addToast({
        type: "error",
        title: "Failed to add new data"
      }))
    }
    btnFinalSubmitRef.current.removeAttribute("disabled");
  }

  if (!isOpen) return null;
  return (
    <div className="privilege-add__overlay">
      <div className="privilege-add__modal">
        <div className="privilege-add__header">
          <h2 className="privilege-add__title">Add Permission</h2>
          <button className="privilege-add__close" onClick={() => {
            onClose(false)
            handleConfirmation(false)
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="privilege-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="privilege-add__content">
                  <h3 className="privilege-add__confirmation-title">Do you really wish to submit new Permission?</h3>
                  <p className="privilege-add__confirmation-desc">Once you submit, the new Permission will be added to the system.</p>
                  <div className="privilege-add__footer">
                    <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button className="btn-primary font-primary" ref={btnFinalSubmitRef} onClick={handleSubmit}>Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="privilege-add__content">
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="privilege_name">Permission Group <span className="privilege-add__input-required">*</span></label>
                    <input type="text" className="privilege-add__input-box"
                      {...register("privilege_name", {
                        required: "Permission group must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="privilege_name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="menu_list">Permission <span className="privilege-add__input-required">*</span></label>
                    <Controller
                      name="menu_list"
                      control={control}
                      rules={{ required: "Permission must be filled" }}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          name="menu_list"
                          isMulti={true}
                          options={menuOptions}
                          placeholder="-- Select Permission--"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="menu_list"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="is_active">Status</label>
                    <Controller
                      control={control}
                      name="is_active"
                      rules={{ required: "Status must be filled" }}
                      defaultValue={statusOptions[0]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={statusOptions}
                          placeholder="-- Select Status --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="is_active"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="privilege-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => onClose(false)}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default AddMenuModal;