import axios from "axios";
const BASE_URL = 'https://genai-cms-api.nawatech.co/conversation/';
// const BASE_URL = 'http://localhost:8000/';

const axiosBulk = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
});

axiosBulk.interceptors.response.use((response) => {
    return response;
}, (error) => {
    return Promise.reject(error);
});

axiosBulk.interceptors.request.use((config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosBulk;