import React, { useRef, useState } from "react";
import uploadIcon from "../../assets/whiteUploadIcon20.svg";
import DownloadModal from "./DownloadModal";
import UploadPropertiesModal from "./UploadPropertiesModal";
import GeneralProperties from "./GeneralProperties";
import SpecificProperties from "./SpecificProperties";
import FunctionFilter from "./FunctionFilter";
import { Alert } from "../../components";

import "./Properties.css";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import usePromptMetrics from "../../hooks/usePromptProperties";
import TopBar from "../../components/TopBar";

const Properties = () => {
  useDocumentTitle("Prompt Properties");

  const {
    activeTab,
    setActiveTab,
    currentGeneralPage,
    setCurrentGeneralPage,
    selectedSpesificFilters,
    setSelectedSpesificFilters,
    currentSpesificPage,
    setCurrentSpesificPage
  } = usePromptMetrics();

  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleFilterApply = (value) => {
    setSelectedSpesificFilters(value);
    setCurrentSpesificPage(1);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleOpenModal = (option) => {
    if (option === "Download") {
      setDownloadModalOpen(true);
    } else if (option === "Upload") {
      setUploadModalOpen(true);
    }
  };

  const handleCloseModal = (option) => {
    if (option === "Download") {
      setDownloadModalOpen(false);
    } else if (option === "Upload") {
      setUploadModalOpen(false);
    }
  };

  const handleUploadSuccess = (data) => {
    const { code, message, detail } = data;

    if (code !== 200) {
      showAlert({ type: "error", title: code, message: detail });
      return;
    }

    showAlert({ type: "success", message: message });
  };

  const handleUploadError = (error) => {
    const { message, response: { data: { detail } = {} } = {} } = error;

    showAlert({
      type: "error",
      title: message,
      message: detail,
    });
  };

  const prevRoutes = [
    { name: 'Promp Management', route: null }
  ]
  return (
    <>
      <Alert ref={alertRef} />
      <TopBar prevRoutes={prevRoutes} currentRoute={'Properties'}/>
      <div className="functions-container">
        <div className="functions-header">
          <h1>Properties</h1>
          <button
            className="btn-primary with-icon font-primary"
            onClick={() => handleOpenModal("Upload")}
          >
            <img src={uploadIcon} className="upload-icon" alt="upload icon" />
            Upload Bulk Properties
          </button>
        </div>
        <div className="functions-buttons">
          <div className="toggle-buttons">
            <button
              className={`toggle-button ${activeTab === "General Properties" ? "active" : ""
                }`}
              onClick={() => handleTabClick("General Properties")}
            >
              General Properties
            </button>
            <button
              className={`toggle-button ${activeTab === "Specific Properties" ? "active" : ""
                }`}
              onClick={() => handleTabClick("Specific Properties")}
            >
              Specific Properties
            </button>
          </div>

          {/* New container for dropdown and button */}
          <div className="functions-right-container">
            {activeTab === "Specific Properties" && (
              <>
                <FunctionFilter
                  onApply={handleFilterApply}
                  oldSelectedValue={selectedSpesificFilters}
                />
              </>
            )}
            <button
              className="btn-base font-base"
              onClick={() => handleOpenModal("Download")}
            >
              Download Properties
            </button>
          </div>
        </div>
        {activeTab === "General Properties" ? (
          <GeneralProperties
            currentPage={currentGeneralPage}
            setCurrentPage={setCurrentGeneralPage}
          />
        ) : (
          <SpecificProperties
            filters={selectedSpesificFilters}
            currentPage={currentSpesificPage}
            setCurrentPage={setCurrentSpesificPage}
          />
        )}
        <DownloadModal
          filters={selectedSpesificFilters}
          isOpen={isDownloadModalOpen}
          onClose={() => handleCloseModal("Download")}
        />
        <UploadPropertiesModal
          // isOpen={isUploadModalOpen}
          // onClose={() => handleCloseModal("Upload")}
          // onSuccess={handleUploadSuccess}

          isOpen={isUploadModalOpen}
          onClose={() => handleCloseModal("Upload")}
          onUploaded={handleUploadSuccess}
          onError={handleUploadError}
        />
      </div>
    </>
  );
};

export default Properties;
