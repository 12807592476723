import { useCallback, useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import OutsideHandler from "../../components/OutsideHandler";
import "./RoleManagement.css";

import searchIcon from "../../assets/searchIcon20.svg";
import clearIcon from "../../assets/xIcon20.svg";
import plusIcon from "../../assets/plusIconWhite20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import FilterModal from "./FilterModal";
import AddRoleModal from "./AddRoleModal";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";
import kebabMenu from "../../assets/kebabMenu.svg";
import { ReactComponent as TrashIcon } from "../../assets/trashIcon16.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import DeleteRoleModal from "./DeleteRoleModal";
import Pagination from "../../components/Pagination";
import EditRoleModal from "./EditRoleModal";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import LoadingTable from "../../components/LoadingTable";
import debounce from "../../utils/Debounce";
import { convertFormatedDate } from "../../utils/FunctionHelpers";

const SIZE_PAGE = 10;
const DEFAULT_SORT = 'desc';
const DEFAULT_ORDER_BY = 'updated_at';

const RoleManagement = () => {
  useDocumentTitle('Role Management')
  const prevRoutes = [
    { name: 'User & Role Management', route: null }
  ]
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
  const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(null);
  const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSort, setSelectedSort] = useState({ order_by: DEFAULT_ORDER_BY, sort: DEFAULT_SORT });

  const [selectedFilters, setSelectedFilters] = useState({
    search: "",
    role_name: [],
    data_access: [],
    dashboard_type: [],
    page: 1,
    size: SIZE_PAGE,
    sort: DEFAULT_SORT,
    order_by: DEFAULT_ORDER_BY,
    status: "",
  });

  const [defaultPSMAccess, setDefaultPSMAccess] = useState([]);
  const [defaultRegionAccess, setDefaultRegionAccess] = useState([]);
  const [defaultEstateAccess, setDefaultEstateAccess] = useState([]);
  const [defaultDivisionAccess, setDefaultDivisionAccess] = useState([]);
  const [defaultComplexAccess, setDefaultComplexAccess] = useState([]);

  const [dashboardAccess, setDashboardAccess] = useState([]);
  const [dataAccessOptions, setDataAccessOptions] = useState([]);
  const [dashboardTypeOptions, setDashboardTypeOptions] = useState([]);

  const handleFilterModal = (action = null) => {
    if (action === null) {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else {
      setIsFilterModalOpen(action);
    }
  }

  const handleAddRoleModal = (action = null) => {
    if (action === null) {
      setIsAddRoleModalOpen(!isAddRoleModalOpen);
    } else {
      setIsAddRoleModalOpen(action);
    }
  }

  const handleEditRoleModal = (index = null) => {
    setIsEditRoleModalOpen(isEditRoleModalOpen === index ? null : index);
  }

  const handleDeleteRoleModal = (index) => {
    setIsDeleteRoleModalOpen(isDeleteRoleModalOpen === index ? null : index);
  }

  const handleFilterApply = (filters) => {
    // Apply filters
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
    setCurrentPage(1);
  }

  const handleActiveMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  }

  const handlePageChange = (action) => {
    setCurrentPage(action);
  }

  const getRoleAccess = async () => {
    try {
      const URL = "role/get-data-access"
      const response = await axiosCMS.get(URL);
      const result = response.data;
      const tempDataAccessList = [];
      result.data.map((obj, i) => {

        if (obj.group_name === 'PSM') {
          setDefaultPSMAccess(obj.list_group);
        } else if (obj.group_name === 'REGION') {
          setDefaultRegionAccess(obj.list_group)
        } else if (obj.group_name === 'ESTATE') {
          setDefaultEstateAccess(obj.list_group);
        } else if (obj.group_name === 'DIVISION') {
          setDefaultDivisionAccess(obj.list_group);
        } else if (obj.group_name === 'COMPLEX') {
          setDefaultComplexAccess(obj.list_group);
        }

        const dataAccess = obj.list_group.map((item) => ({ label: item.name, value: item.id }));
        tempDataAccessList.push(...dataAccess);
      })
      setDataAccessOptions(tempDataAccessList);
    } catch (error) {
      console.error('Error fetching role access:', error);
    }
  }

  const getDashboards = async () => {
    const URL = "role/get-list-dashboard/"
    try {
      const response = await axiosCMS.get(URL);
      const result = response.data;
      setDashboardAccess(result.data);
      setDashboardTypeOptions(result.data.map((obj) => ({ label: obj.dashboard_name, value: obj.id })));
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }

  const getRoles = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    const URL = "role/get/";
    try {
      const response = await axiosCMS.post(URL, filters);
      const result = response.data;
      setRoles(result.data.result);
      setTotalPages(result.data.total_page);
    } catch (error) {
      console.error('Error fetching role data:', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch data roles',
      }))
    }
    setIsLoading(false);
  }, 1000), [])

  useEffect(() => {
    getRoleAccess();
    getDashboards();
  }, [])

  useEffect(() => {
    getRoles({
      ...selectedFilters,
      page: currentPage,
      sort: selectedSort.sort,
      order_by: selectedSort.order_by
    });
  }, [selectedFilters, currentPage, selectedSort])

  const handleChangeSearch = debounce((filters, search) => {
    const updatedFilters = { ...filters, search: search };
    setSelectedFilters(updatedFilters);
  }, 300)

  useEffect(() => {
    handleChangeSearch(selectedFilters, search);
  }, [search])

  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'Role Management'} />
      <div className="role-mg">
        <div className="role-mg__header-container">
          <h1 className="role-mg__header-title">Role Management</h1>
          <div className="role-mg__action-container">
            <div style={{ position: 'relative' }}>
              <input type="text" placeholder="Search by Role Name" className="role-search" value={search} onChange={(e) => setSearch(e.target.value)} />
              {search
                ? <img src={clearIcon} alt="search icon" className="role-search__icon" onClick={() => setSearch('')} />
                : <img src={searchIcon} alt="search icon" className="role-search__icon" />
              }
            </div>
            <div className="role-filter">
              <button onClick={handleFilterModal} className="btn-base with-icon font-base">
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className="filter-button-icon"
                />
                Filter
              </button>
              <FilterModal
                isOpen={isFilterModalOpen}
                onFilterModal={handleFilterModal}
                onApply={handleFilterApply}
                dataAccessOptions={dataAccessOptions}
                dashboardAccessOptions={dashboardTypeOptions}
              />
            </div>
            <button onClick={handleAddRoleModal} className="btn-primary with-icon font-primary" >
              <img
                src={plusIcon}
                alt="filter icon"
                className="filter-button-icon"
              />
              Add Role
            </button>
            <AddRoleModal
              defaultPSMAccess={defaultPSMAccess}
              defaultRegionAccess={defaultRegionAccess}
              defaultEstateAccess={defaultEstateAccess}
              defaultDivisionAccess={defaultDivisionAccess}
              defaultComplexAccess={defaultComplexAccess}
              dashboardAccess={dashboardAccess}
              isOpen={isAddRoleModalOpen}
              onClose={() => handleAddRoleModal(false)}
              onSuccess={() => getRoles({
                ...selectedFilters,
                page: currentPage,
                sort: selectedSort.sort,
                order_by: selectedSort.order_by
              })}
            />
          </div>
        </div>
        <div className="role-table">
          <div className="role-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th>Role Name</th>
                  <th>Data Access</th>
                  <th>Dashboard Type</th>
                  <th>Status</th>
                  <th>
                    <div className="group-flex-8 align-center">
                      <span>Last Updated</span>
                      <img
                        src={
                          selectedSort.order_by === "updated_at"
                            ? selectedSort.sort === "asc"
                              ? sortIconAsc
                              : sortIconDesc
                            : sortIcon
                        }
                        className="sort-icon"
                        alt="Sort icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedSort({
                            order_by: "updated_at",
                            sort:
                              selectedSort.order_by === "updated_at"
                                ? selectedSort.sort === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc",
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th style={{ width: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ?
                    <LoadingTable columnSize={6} rowSize={4} />
                    : (
                      roles.length > 0 ? roles.map(role => (
                        <tr key={role.id}>
                          <td>{role.role_name}</td>
                          <td>{role.data_access && role.data_access.length >= 5 ? role.data_access?.slice(0, 5).map((obj) => obj.access).join(', ') + ', ...' : role.data_access.map((obj) => obj.access).join(', ')}</td>
                          <td>{role.dashboard_type && role.dashboard_type.length >= 5 ? role.dashboard_type.slice(0, 5).map(obj => obj.dashboard_name).join(', ') + ', ...' : role.dashboard_type.map(obj => obj.dashboard_name).join(', ')}</td>
                          <td>
                            {role.is_active === 'active'
                              ? <span className="role-badge--active">Active</span>
                              : <span className="role-badge--inactive">Inactive</span>
                            }
                          </td>
                          <td>{role.updated_at && convertFormatedDate(role.updated_at)}</td>
                          <td>
                            <div className="kebab-menu-container">
                              <img
                                src={kebabMenu}
                                alt="kebab menu"
                                onClick={() => handleActiveMenu(role.id)}
                                style={{ cursor: "pointer" }}
                              />
                              {activeMenuIndex === role.id && (
                                <OutsideHandler handleClose={handleActiveMenu}>
                                  <div className="role-menu">
                                    <div
                                      className="role-menu-option"
                                      onClick={() => setIsEditRoleModalOpen(role)}
                                    >
                                      <EditIcon className="role-menu-icon" />
                                      Edit
                                    </div>
                                    {/* <div
                                      className="role-menu-option"
                                      onClick={() => setIsDeleteRoleModalOpen(role)}
                                    >
                                      <TrashIcon className="role-menu-icon" />
                                      Delete
                                    </div> */}
                                  </div>
                                </OutsideHandler>
                              )}
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={6} className="text-center">No data available</td>
                        </tr>
                      )
                    )
                }
              </tbody>
            </table>
          </div>
          <Pagination currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} />
        </div>
        <EditRoleModal
          role={isEditRoleModalOpen}
          defaultPSMAccess={defaultPSMAccess}
          defaultRegionAccess={defaultRegionAccess}
          defaultEstateAccess={defaultEstateAccess}
          defaultDivisionAccess={defaultDivisionAccess}
          defaultComplexAccess={defaultComplexAccess}
          dashboardAccess={dashboardAccess}
          onClose={handleEditRoleModal}
          onSuccess={() => getRoles({
            ...selectedFilters,
            page: currentPage,
            sort: selectedSort.sort,
            order_by: selectedSort.order
          })}
        />
        {/* <DeleteRoleModal
          role={isDeleteRoleModalOpen}
          onDeleteModal={handleDeleteRoleModal}
          onSuccess={() => getRoles(selectedFilters, currentPage)}
        /> */}
      </div>
    </>
  )
}

export default RoleManagement;