import { useCallback, useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import InputErrorMessage from "../../components/InputErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import { addToast } from "../../libs/redux/slices/toastSlice";

const EditMenuModal = ({ menuOptions, permissionGroup, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const btnFinalSubmitRef = useRef(null);
  const { register, reset, setValue, control, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const statusOptions = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' }
  ];

  const [errorMessages, setErrorMessages] = useState({
    privilege_name: [],
    menu_list: [],
    other_errors: [],
  });

  const getPermissionById = useCallback(async () => {
    const URL = "cms/get-detail/";
    try {
      const response = await axiosCMS.get(URL + permissionGroup.id);
      if (response.status === 200) {
        const result = response.data;
        setValue("privilege_name", result.data.permission_master.permission_group_name);
        setValue("menu_list", result.data.permission_access.map(menu => ({ value: menu.menu_id, label: menu.menu_name, access_id: menu.access_id })));
        setValue("is_active", { value: result.data.permission_master.status, label: result.data.permission_master.status ? 'Active' : 'Inactive' });
      }
    } catch (error) {
      console.error('failed to get permission by id', error);
    }
  }, [permissionGroup])

  useEffect(() => {
    if (permissionGroup !== null) {
      getPermissionById();
    }
  }, [permissionGroup])

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = async (data) => {
    btnFinalSubmitRef.current.setAttribute("disabled", "disabled");
    const URL_PERMISSION_MASTER = "cms/update-master/"
    const URL_PERMISSION_ACCESS = "cms/update-access/"
    const permissionMasterForm = {
      permission_master_id: permissionGroup.id,
      permission_group_name: data.privilege_name,
      status: data.is_active.value
    }
    const permissionAccessForm = {
      permission_master_id: permissionGroup.id,
      update_access: data.menu_list.map(menu => ({access_id: menu.access_id?.toString() ?? "new", menu_id: menu.value})),
    }
    try {
      const responsePermissionMaster = await axiosCMS.post(URL_PERMISSION_MASTER, permissionMasterForm);
      const responsePermissionAccess = await axiosCMS.post(URL_PERMISSION_ACCESS, permissionAccessForm);
      if (responsePermissionMaster.status === 200 && responsePermissionAccess.status === 200) {
        handleConfirmation(false);
        onClose();
        reset();
        dispatch(addToast({
          type: "success",
          title: "Changes were successfully saved"
        }));
        onSuccess();
      }
    } catch (error) {
      console.error('Failed to edit Permission', error);
      dispatch(addToast({
        type: "error",
        title: "Failed to edit Permission"
      }))
    }
    btnFinalSubmitRef.current.removeAttribute("disabled");
  }

  if (permissionGroup === null) return null;
  return (
    <div className="privilege-add__overlay">
      <div className="privilege-add__modal">
        <div className="privilege-add__header">
          <h2 className="privilege-add__title">Edit Permission</h2>
          <button className="privilege-add__close" onClick={() => {
            onClose()
            handleConfirmation(false)
          }}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="privilege-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="privilege-add__content">
                  <h3 className="privilege-add__confirmation-title">Do you really wish to submit edited Permission?</h3>
                  <p className="privilege-add__confirmation-desc">Once you submit, the edited Permission will be added to the system.</p>
                  <div className="privilege-add__footer">
                    <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button className="btn-primary font-primary" ref={btnFinalSubmitRef} onClick={handleSubmit}>Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="privilege-add__content">
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="privilege_name">Permission Group <span className="privilege-add__input-required">*</span></label>
                    <input type="text" className="privilege-add__input-box"
                      {...register("privilege_name", {
                        required: "Permission group must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="privilege_name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="menu_list">Permission <span className="privilege-add__input-required">*</span></label>
                    <Controller
                      name="menu_list"
                      control={control}
                      rules={{ required: "Permission must be filled" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          name="menu_list"
                          isMulti={true}
                          options={menuOptions}
                          placeholder="-- Select Permission--"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="menu_list"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="is_active">Status</label>
                    <Controller
                      control={control}
                      name="is_active"
                      rules={{ required: "Status must be filled" }}
                      defaultValue={statusOptions[0]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={statusOptions}
                          placeholder="-- Select Status --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="is_active"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="privilege-add__footer">
                    <button className="btn-base font-base" onClick={onClose}>
                      Cancel
                    </button>
                    <button className="btn-primary font-primary" onClick={() => handleConfirmation(true)}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default EditMenuModal;