import axios from "axios";

let sessionExpiredHandler = null;
let isLocked = false;

export const setSessionExpiredHandler = (handle) => {
  sessionExpiredHandler = handle;
}

const BASE_URL = 'https://genai-cms-api.nawatech.co/api/sma/';
// const BASE_URL = 'http://localhost:8000/api/sma/';
const axiosCMS = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
  }
});

axiosCMS.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      if (!isLocked) {
        isLocked = true;
        if (sessionExpiredHandler) {
          sessionExpiredHandler();
        }
        isLocked = false;
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axiosCMS.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});


export default axiosCMS;