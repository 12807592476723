import axios from 'axios';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { useCallback, useEffect, useState } from 'react';

import { Chart } from 'react-chartjs-2';
import debounce from '../../utils/Debounce';
import LoadingChart from '../../components/LoadingChart';
import Select, { components } from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import axiosCMS from '../../libs/axios/axiosCMS';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineController, BarController, PointElement, LineElement, Tooltip);

const TotalInteractionChart = ({ chartWidth, selectedFilters, selectedMonth, selectedYear }) => {
  const [isChurnRateDisplay, setIsChurnRateDisplay] = useState(true)
  const [selectedMetric, setSelectedMetric] = useState({ value: 'total_interactions', label: 'Total Interactions' });
  const [selectedTime, setSelectedTime] = useState({ value: 'day', label: 'Daily' });
  const [timeOptions, setTimeOptions] = useState([
    { value: 'day', label: 'Daily' },
    { value: 'month', label: 'Monthly' },
    { value: 'year', label: 'Yearly' },
  ]);
  const [labelData, setLabelData] = useState([])
  const [barChartData, setBarChartData] = useState([])
  const [lineChartData, setLineChartData] = useState([])
  const [lineChartLegend, setLineChartLegend] = useState('')
  const [barChartLegend, setBarChartLegend] = useState('')
  const [showLineChart, setShowLineChart] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSelectDayDisabled, setIsSelectDayDisabled] = useState(false)

  const listChartLegend = {
    total_interactions: {
      bar: 'Total Interactions',
      line: 'Avg. Interactions'
    },
    total_active_users: {
      bar: 'Total Active Users',
      line: 'Avg. Active Users'
    },
    avg_session_length: {
      bar: 'Avg Session Length',
    },
    avg_message_session: {
      bar: 'Avg Message/Session',
    },
    retention_rate: {
      bar: 'Retention Rate',
    },
    churn_rate: {
      bar: 'Churn Rate',
    },
  };

  const listMetrics = [
    { value: 'total_interactions', label: 'Total Interactions' },
    { value: 'total_active_users', label: 'Total Active Users' },
    { value: 'avg_session_length', label: 'Avg Session Length' },
    { value: 'avg_message_per_session', label: 'Avg Message/Session' },
    { value: 'retention_rate', label: 'Total Retention' },
    { value: 'churn_rate', label: 'Total Churn' },
  ]

  const listTime = [
    { value: 'day', label: 'Daily' },
    { value: 'month', label: 'Monthly' },
    { value: 'year', label: 'Yearly' },
  ]

  const handleMetricChange = (selectedOptions) => {
    setSelectedMetric(selectedOptions);
  };

  const handleTimeChange = (selectedOptions) => {
    setSelectedTime(selectedOptions)
  };

  const isIncludeChurnRate = (date_months, date_years) => {
    let prevMonth = 0;
    if (date_months.length === 1 && date_years.length === 1) return true;

    if (date_years.length > 1) return false;

    for (let i = 0; i < date_months.length; i++) {
      if (i === 0) {
        prevMonth = date_months[i];
      } else {
        if (date_months[i] - prevMonth !== 1) {
          return false;
        }
        prevMonth = date_months[i];
      }
    }
    return true
  }

  const getData = useCallback(debounce(async (filters, metric, time) => {
    setIsLoading(true)
    const isChurnRate = isIncludeChurnRate(filters.date_months, filters.date_years);
    if (metric.value === "churn_rate" && !isChurnRate) {
      setIsChurnRateDisplay(false)
      setIsLoading(false)
      return
    } else {
      setIsChurnRateDisplay(true)
    }
    try {
      const URL = "user-engagement/line-chart";
      const response = await axiosCMS.post(URL, {
        ...filters,
        line_type: metric.value,
        period_type: time.value
      })

      if (metric.value === 'total_interactions' || metric.value === 'total_active_users') {
        const result = response.data;
        setBarChartData(result.data.map(x => x.total));
        setLabelData(result.data.map(x => x.date));
        setLineChartData(result.data.map(x => x.avg));
        setBarChartLegend(listChartLegend[metric.value].bar)
        setLineChartLegend(listChartLegend[metric.value].line)
        setShowLineChart(true);
      } else {
        const result = response.data;
        setBarChartData(result.data.map(x => x.total));
        setLabelData(result.data.map(x => x.date));
        setLineChartData([]);
        setShowLineChart(false);
        setBarChartLegend(listChartLegend[metric.value].bar)
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
    }

    setIsLoading(false)
  }, 2000), [])

  useEffect(() => {
    getData(selectedFilters, selectedMetric, selectedTime);
  }, [selectedFilters, selectedMetric, selectedTime])

  useEffect(() => {
    if (selectedMonth.length > 1 || selectedYear.length > 1) {
      setIsSelectDayDisabled(true)
      setSelectedTime({ value: 'month', label: 'Monthly' })
    } else {
      setIsSelectDayDisabled(false)
    }
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    if (selectedMetric.value === 'retention_rate' || selectedMetric.value === 'churn_rate') {
      setSelectedTime({ value: 'month', label: 'Monthly' })
      setTimeOptions([
        { value: 'month', label: 'Monthly' },
        { value: 'year', label: 'Yearly' },
      ])
    } else {
      setTimeOptions([
        { value: 'day', label: 'Daily' },
        { value: 'month', label: 'Monthly' },
        { value: 'year', label: 'Yearly' },
      ])
    }
  }, [selectedMetric])

  const data = {
    labels: labelData,
    datasets: [
      {
        type: 'line',
        borderColor: 'rgb(237, 28, 36, 0.8)',
        borderWidth: 2,
        fill: false,
        data: isLoading ? [] : lineChartData,
      },
      {
        type: 'bar',
        backgroundColor: 'rgb(113, 174, 235)',
        data: isLoading ? [] : barChartData,
        borderColor: 'white',
        borderWidth: 2,
        maxBarThickness: 70,
      },
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    }
  };

  const CostumOption = ({ innerProps, ...props }) => {
    return (
      <>
        <components.Option {...props} innerProps={innerProps} className={props.isDisabled ? 'my-anchor-element-id' : ''} />
        <ReactTooltip id='tooltip' place="right" content='Daily data can be displayed only if one month and year are selected' anchorSelect=".my-anchor-element-id" style={{ maxWidth: "200px" }} />
      </>
    )
  }

  return (
    <div className='ue-section grow'>
      <div className='ue-filter-container'>
        <Select
          id="metricType"
          name="metricType"
          isSearchable={false}
          options={listMetrics}
          value={selectedMetric}
          onChange={handleMetricChange}
          placeholder={"-- Select Metric --"}
          classNamePrefix="select"
          className='w-200'
        />
        <Select
          id="periodType"
          name="periodType"
          isSearchable={false}
          components={{ Option: CostumOption }}
          options={timeOptions}
          value={selectedTime}
          onChange={handleTimeChange}
          placeholder={"-- Select Period --"}
          classNamePrefix="select-period-chart"
          isOptionDisabled={(option) => option.value === 'day' && isSelectDayDisabled}
        />
      </div>
      <div style={{ position: 'relative', width: chartWidth ?? '100%', height: '300px' }}>
        {isLoading && <LoadingChart />}
        {selectedMetric.value === 'churn_rate' && !isChurnRateDisplay ? <div className="loading-chart">Chart N/A</div> : ''}
        <Chart type='bar' data={data} options={chartOptions} />
      </div>
      <div className='chart-legend-container'>
        {
          barChartLegend &&
          <div className='chart-legend'>
            <div className='legend-blue'></div>
            <p className='legend-title'>
              {barChartLegend}
            </p>
          </div>
        }
        {showLineChart &&
          <div className='chart-legend'>
            <div className='legend-red'></div>
            <p className='legend-title'>
              {lineChartLegend}
            </p>
          </div>
        }
      </div>
    </div>)
}

export default TotalInteractionChart;