import { useEffect } from "react";
import infoIcon from "../../assets/infoIcon24.svg";
import useBulkTesting from "../../hooks/useBulkTesting";


const ActiveConfiguration = (props) => {

  const { appliedConfiguration, getCurrentActiveConfiguration } = useBulkTesting();
  useEffect(() => {
    getCurrentActiveConfiguration();
  }, [])
  return (
    <div className="bulk-active-box bulk-active--info">
      <div className="bulk-active-group">
        <img src={infoIcon} alt="info icon" />
        <h5>Active Configuration:</h5>
        <p>
          {appliedConfiguration.join(", ") || "No configuration applied"}
        </p>
      </div>
    </div>
  );
}

export default ActiveConfiguration;