import plusIcon from "../../assets/plusIconWhite20.svg";
import searchIcon from "../../assets/searchIcon20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import clearIcon from "../../assets/xIcon20.svg";

import useBulkTesting from "../../hooks/useBulkTesting";
import { useState } from "react";
import FilterModal from "./FilterModal";
import AddBulkTestModal from "./AddBulkTestModal";

const ActionBulkTesting = () => {

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isAddBulkTestOpen, setIsAddBulkTestOpen] = useState(false);

  const {
    searchBulkTest,
    setSearchBulkTest,
    selectedBulkTestFilters,
    setSelectedBulkTestFilters,
    getBulkTest,
  } = useBulkTesting();

  return (
    <div className="bulk-testing__action-container">
      {/* <div style={{ position: 'relative' }}>
        <input type="text" placeholder="Search by ID" className="user-search" value={searchBulkTest} onChange={(e) => setSearchBulkTest(e.target.value)} />
        {searchBulkTest
          ? <img src={clearIcon} alt="search icon" className="user-search__icon" onClick={() => setSearchBulkTest('')} />
          : <img src={searchIcon} alt="search icon" className="user-search__icon" />
        }
      </div> */}
      <div className="bulk-filter">
        <button onClick={() => setIsFilterOpen(true)} className="btn-base with-icon font-base">
          <img
            src={filterIcon}
            alt="filter icon"
            className="filter-button-icon"
          />
          <span>Filter</span>
        </button>
        <FilterModal isOpen={isFilterOpen} onClose={() => setIsFilterOpen(false)} />
      </div>
      <button onClick={() => setIsAddBulkTestOpen(true)} className="btn-primary with-icon font-primary" >
        <img
          src={plusIcon}
          alt="filter icon"
          className="filter-button-icon"
        />
        New Bulk Test
      </button>
      <AddBulkTestModal
        isOpen={isAddBulkTestOpen}
        onClose={() => setIsAddBulkTestOpen(false)}
        onSuccess={() => getBulkTest(selectedBulkTestFilters)}
      />
    </div>
  )
}

export default ActionBulkTesting;