import { useDispatch } from "react-redux";
import closeIcon from "../../assets/cancelIcon.svg";
import { addToast } from "../../libs/redux/slices/toastSlice";
import axiosCMS from "../../libs/axios/axiosCMS";

const DeleteUserModal = ({ user, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const deleteUser = async (user) => {
    const URL ="user-management/delete/"
    try{
      const response = await axiosCMS.post(URL + user.id, {});
      if(response.status === 200){
        dispatch(addToast({
          type: 'success',
          title: 'User deleted successfully',
        }))
        onSuccess();
        onClose();
      }
    }catch(error){
      console.error("Failed to delete user", error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to delete user',
      }))
    } 
  }
  const handleSubmit = () => {
    deleteUser(user);
  }

  if (user === null) return null;
  return (
    <div className="user-add__overlay">
      <div className="user-add__modal">
        <div className="user-add__header">
          <h2 className="user-add__title">Delete User</h2>
          <button className="user-add__close" onClick={() => onClose()}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="user-add__separator-line"></div>
        <div className="user-add__content">
          <h3 className="user-add__confirmation-title">Do you really wish to delete selected user?</h3>
          <p className="user-add__confirmation-desc">Once you delete, the selected user will be removed from the system.</p>
          <div className="user-add__footer">
            <button className="btn-base font-base" onClick={() => onClose()}>Cancel</button>
            <button className="btn-primary font-primary" onClick={handleSubmit}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteUserModal;