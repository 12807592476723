import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert } from "../../components";
import backArrow from "../../assets/backArrow.svg";
import EditSpecificProp from "./EditSpecificProp";

import { format } from "date-fns";
import axiosCMS from "../../libs/axios/axiosCMS";

// const relatedFunctions = [
//   "get_metrics_trend",
//   "get_metrics_combine",
//   "get_top_or_ranking",
//   "get_metrics_compare",
//   "get_metrics_trend",
//   "get_metrics_summary",
// ];

const SpecificPropertiesDetail = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [dataSpecificProp, setDataSpecificProp] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const generalPropId = location.state?.id || "";

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleOpenModal = () => {
    setIsEditModalOpen(true);
  };

  const getDataSpecificProp = useCallback((params) => {
    // API call to get function details
    axiosCMS({
      method: "post",
      url: `prompt_properties/get/detail/`,
      params: params,
    }).then(
      (response) => {
        const {
          data: {
            code,
            data: { properties: dataProp, function: dataFunction },
            detail,
          },
        } = response;

        if (code !== 200) {
          showAlert({
            type: "error",
            title: code || "Error",
            message: detail || "Error fetching data",
          });
          return;
        }

        setDataSpecificProp({ ...dataProp, relatedFunctions: dataFunction });
      },
      (error) => {
        const { message, response: { data: { detail } = {} } = {} } = error;

        showAlert({
          type: "error",
          title: message || "Error",
          message: detail || "Error fetching data",
        });
      }
    );
  }, []);

  useEffect(() => {
    getDataSpecificProp({ id: generalPropId });
  }, [generalPropId, getDataSpecificProp]);

  return (
    <>
      <Alert ref={alertRef} />

      <div className="general-prop-details">
        <div className="general-prop-details-header">
          <img
            src={backArrow}
            className="back-button"
            alt="return button"
            onClick={() => navigate(-1)}
          />
          <h2 className="general-prop-details-title">
            Specific Properties Details
          </h2>
          <button
            className="general-prop-edit-button"
            onClick={handleOpenModal}
          >
            Edit Properties
          </button>
        </div>
        <div className="general-prop-overview-container">
          <h3 className="overview-title">Overview</h3>
          <div className="general-prop-container">
            <div className="overview-box general-prop-name">
              <p className="overview-box-title">Properties Name:</p>
              <hr className="overview-separator" />
              <p className="overview-description">{dataSpecificProp.name}</p>
            </div>
            <div className="overview-box general-prop-name">
              <p className="overview-box-title">Properties Type:</p>
              <hr className="overview-separator" />
              <p className="overview-description">{dataSpecificProp.type}</p>
            </div>
            <div className="overview-box general-prop-name">
              <p className="overview-box-title">Mandatory Properties:</p>
              <hr className="overview-separator" />
              <p className="overview-description">
                {dataSpecificProp.isrequired ? "Yes" : "No"}
              </p>
            </div>
            <div className="overview-box general-prop-name">
              <p className="overview-box-title">Last Updated:</p>
              <hr className="overview-separator" />
              <p className="overview-description">
                {dataSpecificProp.updated_at && format(new Date(dataSpecificProp.updated_at), "yyyy-MM-dd hh:mm:ss.SSX")}
              </p>
            </div>
            <div className="overview-box description">
              <p className="overview-box-title">Description:</p>
              <hr className="overview-separator" />
              <p className="overview-description">
                {dataSpecificProp.description}
              </p>
            </div>
          </div>
        </div>
        <div className="overview-container related-functions-container">
          <h3 className="related-functions-title">Related Functions</h3>
          <ul className="related-functions-list">
            {dataSpecificProp.relatedFunctions?.map((func, index) => (
              <li key={index}>{func}</li>
            ))}
          </ul>
        </div>
        <EditSpecificProp
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSuccess={() => {
            setIsEditModalOpen(false);
            getDataSpecificProp({ id: generalPropId });
          }}
          formData={dataSpecificProp}
        />
      </div>
    </>
  );
};

export default SpecificPropertiesDetail;
