import React from "react";
import LoadingSkeleton from "./LoadingSkeleton"

const LoadingTable = React.memo(({ columnSize = 2, rowSize = 1 }) => {
  return (
    <>
      {
        [...Array(rowSize)].map((_, rowIndex) => (
          <tr key={rowIndex}>
            {
              [...Array(columnSize)].map((_, columnIndex) => (
                <td key={columnIndex}>
                  <LoadingSkeleton height="20px" width="100%" />
                </td>
              ))
            }
          </tr>
        ))
      }
    </>

  )
})

export default LoadingTable;