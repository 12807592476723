import { roleef, useState } from "react";
import OutsideHandler from "../../components/OutsideHandler";
import Select from "react-select";

import closeIcon from "../../assets/cancelIcon.svg";

const FilterModal = ({ menuOptions, isOpen, onApply, onFilterModal }) => {
  const [statusOptions, setStatusOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]);

  const [filters, setFilters] = useState({
    permission: [],
    status: { label: "ALL", value: "" },
  })

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  }

  const handleClear = () => {
    onApply({
      permission: [],
      status: "",
    });
    setFilters({
      permission: [],
      status: { label: "ALL", value: "" },
    })
    onFilterModal(false);
  }

  const handleApply = () => {
    onApply({
      permission: filters.permission.map(permission => permission.value),
      status: filters.status.value,
    });
    onFilterModal(false);
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onFilterModal(false)}>
      <div className="privilege-filter__wrapper">
        <div className="privilege-filter__header">
          <h2 className="privilege-filter__title">Filter</h2>
          <button className="privilege-filter__close" onClick={() => onFilterModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="privilege-filter__separator" />
        <div className="privilege-filter__content">
          <div className="privilege-filter__form-group">
            <label htmlFor="privilege_name" className="privilege-filter__input-label">Permission</label>
            <Select
              id="permission"
              name="permission"
              isMulti={true}
              options={menuOptions}
              value={filters.permission}
              onChange={handleInputChange}
              placeholder="-- Select Permission --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={true}
            />
          </div>
          <div className="user-filter__form-group">
            <label htmlFor="status" className="user-filter__input-label">Status</label>
            <Select
              id="status"
              name="status"
              options={statusOptions}
              value={filters.cms_access}
              onChange={handleInputChange}
              defaultValue={statusOptions[0]}
              placeholder={"-- Select Status --"}
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={true}
            />
          </div>
        </div>
        <div className="privilege-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;