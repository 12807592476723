import { useDispatch } from "react-redux";
import useBulkTesting from "../hooks/useBulkTesting";
import GenerateActualAnswer from "./GenerateActualAnswer/GenerateActualAnswer";
import "./BulkTesting.css";
import TopBar from "../components/TopBar";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

import ActionBulkTesting from "./BulkTest/ActionBulkTesting";
import ActionGenerateActualAnswer from "./GenerateActualAnswer/ActionGenerateActualAnswer";
import BulkTesting from "./BulkTest/BulkTesting";
import ActiveConfiguration from "./BulkTest/ActiveConfiguration";

const BulkTestingPage = () => {
  useDocumentTitle('Bulk Testing')

  const dispatch = useDispatch();
  const {
    activeTab,
    setActiveTab,
  } = useBulkTesting();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  }

  const renderContent = () => {
    let content = null;
    switch (activeTab) {
      case 'Bulk Testing':
        content = <BulkTesting />;
        break;
      case 'Generate Actual Answer':
        content = <GenerateActualAnswer />;
        break;
      default:
        break;
    }
    return content;
  };

  const prevRoutes = [
    { name: 'Bulk Testing', route: null }
  ]
  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={activeTab} />
      <div className="bulk-testing">
        {activeTab === "Bulk Testing" && <ActiveConfiguration />}
        <div className="bulk-testing__header-container">
          <div className="toggle-buttons">
            <button
              className={`toggle-button ${activeTab === "Generate Actual Answer" ? "active" : ""}`}
              onClick={() => handleTabClick("Generate Actual Answer")}
            >
              Generate Actual Answer
            </button>
            <button
              className={`toggle-button ${activeTab === "Bulk Testing" ? "active" : ""}`}
              onClick={() => handleTabClick("Bulk Testing")}
            >
              Bulk Testing
            </button>
          </div>
          <div>
            {activeTab === "Generate Actual Answer"
              ? <ActionGenerateActualAnswer />
              : <ActionBulkTesting />
            }
          </div>
        </div>
        <div >{renderContent()}</div>
      </div>
    </>
  );
}

export default BulkTestingPage;