import Sortable from "./Sortable";

const THead = ({ headColumn = [] }) => {
  return (
    <thead>
      <tr>
        {headColumn.map((x, index) =>
          <th key={index} style={{ minWidth: x.width }}>
            <div className='group-flex-8 align-center'>
              <span>{x.title}</span>
              <Sortable sort={x.sort} />
            </div>
          </th>
        )}
      </tr>
    </thead>
  )
}

export default THead;